import React from 'react'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { Button } from 'react-bootstrap'

export default function NotFoundPage({ resetError }: { resetError?: () => void }): JSX.Element {
  const { _t } = useTypedTranslation()

  const handleResetError = () => (resetError ? resetError() : window.location.replace('/'))

  return (
    <div className="centered-layout error-page">
      <div className="error-code">404</div>
      <div className="error-message">{_t('page-not-found')}</div>
      <div className="error-note text-muted text-center mb-4">{_t('page-not-found-note')}</div>
      <Button variant="primary" onClick={handleResetError}>
        {_t('go-back')}
      </Button>
    </div>
  )
}
