import React from 'react'
import { ModalWrapperType } from '../../modules/shared/types'
import { getUpdateModalCallback } from '../modal'
import { TermsService } from '../../modules/shared/services'
import PageLoader from '../../modules/shared/components/loader'
import { fixLocalLinks } from '../../modules/terms/helpers'
import { useLocation } from 'react-router-dom'

export interface TermsModalProps {
  title: string | JSX.Element
  isTerms?: boolean
  textColorClass?: string
  modalWrapper: ModalWrapperType
}

export function TermsModal({ title, isTerms, textColorClass, modalWrapper }: TermsModalProps): JSX.Element {
  const { pathname: url } = useLocation()

  const setTermsContent = (content: string) => {
    const termsContentDiv = document.getElementById('terms-modal')
    if (termsContentDiv) termsContentDiv.innerHTML = content
  }

  const buildContent = () => {
    showModal()
    if (isTerms) {
      TermsService.getTermsCondition().then((content) => {
        content = fixLocalLinks(content, url)
        setTermsContent(content)
      })
    } else {
      TermsService.getPrivacyPolicy().then((content) => {
        content = fixLocalLinks(content, url)
        setTermsContent(content)
      })
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    buildContent()
  }

  const handleHide = () => {
    hideModal()
  }

  const hideModal = getUpdateModalCallback(modalWrapper, { show: false })
  const showModal = getUpdateModalCallback(modalWrapper, {
    show: true,
    title: title,
    body: (
      <div id="terms-modal" className="pt-5 ps-4 pe-4 pb-4">
        <PageLoader />
      </div>
    ),
    props: {
      onHide: handleHide,
      dialogClassName: 'custom-modal-szie w-75 terms-modal modal-content-full-width',
    },
  })

  if (!textColorClass) {
    textColorClass = ''
  }

  return (
    <a href="#" className={textColorClass} onClick={(e) => handleClick(e)}>
      {title}
    </a>
  )
}
