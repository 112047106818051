import React, { useState, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useExport } from '../contexts'
import { SignalVerdict } from '../types'
import { formatEnumItem, getVerdictClass } from '../helpers'
import { Tooltip } from './tooltip'

type CollapsProps = {
  id?: string
  header: any
  body: any
  headerClass?: string
  bodyClass?: string
  isOpen?: boolean
  className?: string
  verdict?: SignalVerdict
  renderBodyOnOpen?: boolean
  iconClass?: string
  iconImageClass?: string
  iconTimeline?: boolean
}

export function Collapse(props: CollapsProps): JSX.Element {
  return <GeneralCollapse {...props} />
}

export function GeneralCollapse(props: CollapsProps): JSX.Element {
  const {
    id,
    header,
    body,
    bodyClass,
    headerClass,
    isOpen,
    iconImageClass,
    className,
    verdict,
    renderBodyOnOpen = false,
    iconClass,
    iconTimeline,
  } = props
  const { exporting } = useExport()
  const [active, setActive] = useState<string>(exporting ? '0' : '')
  const [passedBody, setPassedBody] = useState(body)
  const [activeBody, setActiveBody] = useState(renderBodyOnOpen && !exporting ? null : body)
  const verdictClass = getVerdictClass(verdict)
  const verdictMarker = !verdictClass ? null : (
    <Tooltip id="verdict-tooltip" content={formatEnumItem(verdict as string)} placement={'bottom'}>
      <div className={'verdict-marker ' + verdictClass}></div>
    </Tooltip>
  )

  useEffect(() => {
    !exporting && setActive(isOpen ? '0' : '')
  }, [isOpen, exporting])

  useEffect(() => {
    setPassedBody(body)
  }, [body])

  // Optimimzation to only render body when collapse is opened.
  // In case it is closed we clear body in some timeout.
  // Otherwise, if it is cleared at once, collapse will not close smoothly, but at once
  useEffect(() => {
    if (active) {
      setActiveBody(passedBody)
    }
  }, [active, passedBody, renderBodyOnOpen])

  const collapse = (
    <Accordion activeKey={active} className={className} id={id}>
      <Card>
        <Accordion.Header as={Card.Header} className={headerClass || ''} onClick={() => setActive(active ? '' : '0')}>
          {verdictMarker}
          <div className="accordion-header-content">{header}</div>
        </Accordion.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className={bodyClass}>{active == '0' ? body : activeBody}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )

  if (!iconClass) {
    return collapse
  }

  return (
    <div className="collapse-with-icon">
      {!exporting && (
        <div className={'collapse-icon-container ' + (iconTimeline ? ' icon-timeline' : '')}>
          <div className={'collapse-icon icon-' + (iconClass || '')}>
            <i className={'ds3-icon ds3-' + (iconImageClass || '')} />
          </div>
        </div>
      )}
      {collapse}
    </div>
  )
}
