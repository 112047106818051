import { HttpError } from 'app/modules/shared/exceptions'
import { useState, useEffect } from 'react'
import { reportsService } from '../services'
import { Buffer } from 'buffer'

export const useContentFetcher = (
  content: string | undefined,
  contentHex: Uint8Array | undefined,
  sha256: string | undefined,
  showModal: CallableFunction,
  setLoading: CallableFunction,
  setContent: CallableFunction,
  setContentHex: CallableFunction,
  setError: CallableFunction
) => {
  const [updateModal, setUpdateModal] = useState(false)

  useEffect(() => {
    // Make sure the state updated before showing the modal
    if (updateModal) {
      showModal()
      setUpdateModal(false)
    }
  }, [updateModal])

  async function fetchContent() {
    if (content && contentHex) {
      showModal()
      return
    }

    if (!sha256) {
      return
    }

    setLoading(true)
    const timeout = 30 * 1000 // milliseconds

    try {
      const data: { [key: string]: any } = await reportsService.getFile(sha256, 'with_content=True', { timeout })
      const decoded = Buffer.from(data.content, 'base64').toString()
      const decoded_hex = new Uint8Array(Buffer.from(data.content, 'base64').buffer)

      setContent(decoded)
      setContentHex(decoded_hex)
      setUpdateModal(true)
    } catch (e) {
      const notFound = e instanceof HttpError && e.code === 404
      setError(notFound ? 'File not found' : 'Error fetching content')
      console.error(e)
    }

    setLoading(false)
  }

  return fetchContent
}
