import React, { useContext, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { mainNotificationContext } from '../context/main-notification-context'
import { getPageUrl } from '../routing/pages'
import { PageData } from '../routing/types'
import { redirectAfterSignin } from '../events/handlers/signin-redirect'
import { useURLSearchParams } from '../hooks'
import { useTypedTranslation } from '../hooks/use-translation'

export function GetBackAfterSignin({ page, message }: { page: PageData; message?: string }): JSX.Element {
  const { _t } = useTypedTranslation()
  const location = useLocation()
  const notificationContext = useContext(mainNotificationContext)
  const searchQuery = useURLSearchParams()

  useEffect(() => {
    // Use delay because of a bug - translations are loaded/applied after notification is shown
    setTimeout(() => {
      notificationContext.setMessageData(message ? message : _t('signin-required-note'), 'info')
    }, 500)

    redirectAfterSignin.setRedirect(page.key as string, location.pathname, searchQuery)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Do not use pageResolver here to avoid circular reference
  return <Navigate to={getPageUrl('sign-in') as string} replace />
}
