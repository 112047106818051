//eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck - hexViewerTheme() return value had to be used as string
import { css } from 'styled-components'

import defaultTheme, { HexViewerTheme } from '../themes'

export const hexViewerTheme =
  <K extends keyof HexViewerTheme>(key: K) =>
  ({ theme: { hexViewer = defaultTheme } }: { theme: { hexViewer: HexViewerTheme } }): HexViewerTheme[K] => {
    const value = hexViewer[key] || defaultTheme[key]
    return typeof value === 'number' ? `${value}px` : value
  }

export default css`
  font-family: ${hexViewerTheme('fontFamily')};
  font-size: ${hexViewerTheme('fontSize')};

  color: ${hexViewerTheme('colorText')};
  background-color: ${hexViewerTheme('colorBackground')};

  @keyframes highlight-animation {
    50% {
      background-color: ${hexViewerTheme('colorBackgroundCursorHighlight')};
      color: ${hexViewerTheme('colorTextCursorHighlight')};
    }
  }

  @keyframes highlight-animation-unfocused {
    50% {
      background-color: ${hexViewerTheme('colorBackgroundInactiveCursorHighlight')};
      color: ${hexViewerTheme('colorTextInactiveCursorHighlight')};
    }
  }

  &::selection {
    background-color: transparent;
  }

  *::selection {
    background-color: transparent;
  }

  /* Layout & Sizing */
  .gutter,
  .gutterHeader {
    width: ${hexViewerTheme('gutterWidth')};
    padding-top: ${hexViewerTheme('rowPaddingY')};
    padding-bottom: ${hexViewerTheme('rowPaddingY')};
  }

  .hexViewerHeader,
  .hexViewerBody {
    min-width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  [data-measure-scrollbar],
  .hexViewerHeader,
  .hexViewerBody {
    &::-webkit-scrollbar {
      width: ${hexViewerTheme('scrollWidth')};
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      width: ${hexViewerTheme('scrollWidth')};
      background-color: ${hexViewerTheme('colorScrollbackThumb')};

      &:hover {
        background-color: ${hexViewerTheme('colorScrollbackThumbHover')};
      }
    }

    &::-webkit-scrollbar-track {
      width: ${hexViewerTheme('scrollWidth')};
      background-color: ${hexViewerTheme('colorScrollbackTrack')};
    }

    &::-webkit-scrollbar-button {
      width: ${hexViewerTheme('scrollWidth')};
      background-color: ${hexViewerTheme('colorScrollbackThumb')};
      height: 0;
    }
  }

  .hexViewerRow,
  .hexViewerRowHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .byteValues,
  .asciiValues {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
  }

  .asciiValue,
  .asciiHeader,
  .byteValue,
  .byteHeader,
  .gutter,
  .gutterHeader,
  .offsetLabel,
  .offsetLabelHeader {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  .offsetLabel,
  .offsetLabelHeader {
    padding: ${hexViewerTheme('rowPaddingY')} ${hexViewerTheme('labelPaddingX')};
  }

  .byteValue,
  .byteHeader {
    text-transform: ${hexViewerTheme('textTransform')};

    > .nybbleHighValue,
    > .nybbleLowValue {
      padding-top: ${hexViewerTheme('rowPaddingY')};
      padding-bottom: ${hexViewerTheme('rowPaddingY')};
    }

    > .nybbleHighValue {
      padding-left: ${hexViewerTheme('bytePaddingX')};
    }

    > .nybbleLowValue {
      padding-right: ${hexViewerTheme('bytePaddingX')};
    }
  }

  .asciiValue,
  .asciiHeader {
    padding: ${hexViewerTheme('rowPaddingY')} ${hexViewerTheme('asciiPaddingX')};
  }

  /* Colors */
  .gutter,
  .gutterHeader {
    background-color: ${hexViewerTheme('colorBackground')};
    width: 32px;
  }

  .offsetLabel,
  .offsetLabelHeader {
    background-color: ${hexViewerTheme('colorBackgroundLabel')};
    color: ${hexViewerTheme('colorTextLabel')};

    &.currentRow {
      background-color: ${hexViewerTheme('colorBackgroundLabelCurrent')};
      color: ${hexViewerTheme('colorTextLabelCurrent')};
    }
  }

  .byteHeader {
    background-color: ${hexViewerTheme('colorBackgroundLabel')};
    color: ${hexViewerTheme('colorTextLabel')};

    &.currentColumn {
      background-color: ${hexViewerTheme('colorBackgroundLabelCurrent')};
      color: ${hexViewerTheme('colorTextLabelCurrent')};
    }
  }

  .asciiHeader {
    background-color: ${hexViewerTheme('colorBackgroundLabel')};
    color: ${hexViewerTheme('colorTextLabel')};
  }

  .byteValue {
    &.even {
      background-color: ${hexViewerTheme('colorBackgroundEven')};
      color: ${hexViewerTheme('colorTextEven')};

      &.currentColumn {
        background-color: ${hexViewerTheme('colorBackgroundColumnEven')};
        color: ${hexViewerTheme('colorTextColumnEven')};
      }

      &.currentRow {
        background-color: ${hexViewerTheme('colorBackgroundRowEven')};
        color: ${hexViewerTheme('colorTextRowEven')};
      }
    }

    &.odd {
      background-color: ${hexViewerTheme('colorBackgroundOdd')};
      color: ${hexViewerTheme('colorTextOdd')};

      &.currentColumn {
        background-color: ${hexViewerTheme('colorBackgroundColumnOdd')};
        color: ${hexViewerTheme('colorTextColumnOdd')};
      }

      &.currentRow {
        background-color: ${hexViewerTheme('colorBackgroundRowOdd')};
        color: ${hexViewerTheme('colorTextRowOdd')};
      }
    }

    &.cursor > .nybbleHighValue,
    &.cursor > .nybbleLowValue {
      background-color: ${hexViewerTheme('colorBackgroundCursor')};
      color: ${hexViewerTheme('colorTextCursor')};
    }

    &.cursorHigh.highlight > .nybbleHighValue,
    &.cursorLow.highlight > .nybbleLowValue {
      animation: highlight-animation ${hexViewerTheme('cursorBlinkSpeed')} step-start 0s infinite;
    }

    &.selection > .nybbleHighValue,
    &.selection > .nybbleLowValue {
      background-color: ${hexViewerTheme('colorBackgroundSelection')};
      color: ${hexViewerTheme('colorTextSelection')};
    }

    &.selectionCursor > .nybbleHighValue,
    &.selectionCursor > .nybbleLowValue {
      background-color: ${hexViewerTheme('colorBackgroundSelectionCursor')};
      color: ${hexViewerTheme('colorTextSelectionCursor')};
    }
  }

  &.editAscii .byteValue,
  &.notFocused .byteValue {
    &.cursor > .nybbleHighValue,
    &.cursor > .nybbleLowValue {
      background-color: ${hexViewerTheme('colorBackgroundInactiveCursor')};
      color: ${hexViewerTheme('colorTextInactiveCursor')};
    }

    &.cursorHigh.highlight > .nybbleHighValue,
    &.cursorLow.highlight > .nybbleLowValue {
      animation: highlight-animation-unfocused ${hexViewerTheme('cursorBlinkSpeed')} step-start 0s infinite;
    }

    &.selection > .nybbleHighValue,
    &.selection > .nybbleLowValue {
      background-color: ${hexViewerTheme('colorBackgroundInactiveSelection')};
      color: ${hexViewerTheme('colorTextInactiveSelection')};
    }

    &.selectionCursor > .nybbleHighValue,
    &.selectionCursor > .nybbleLowValue {
      background-color: ${hexViewerTheme('colorBackgroundInactiveSelectionCursor')};
      color: ${hexViewerTheme('colorTextInactiveSelectionCursor')};
    }
  }

  .asciiValue {
    &.cursor {
      background-color: ${hexViewerTheme('colorBackgroundCursor')};
      color: ${hexViewerTheme('colorTextCursor')};
    }

    &.cursor.highlight {
      animation: highlight-animation ${hexViewerTheme('cursorBlinkSpeed')} step-start 0s infinite;
    }

    &.selection {
      background-color: ${hexViewerTheme('colorBackgroundSelection')};
      color: ${hexViewerTheme('colorTextSelection')};
    }

    &.selectionCursor {
      background-color: ${hexViewerTheme('colorBackgroundSelectionCursor')};
      color: ${hexViewerTheme('colorTextSelectionCursor')};
    }
  }

  &.editHex .asciiValue,
  &.notFocused .asciiValue {
    &.cursor {
      background-color: ${hexViewerTheme('colorBackgroundInactiveCursor')};
      color: ${hexViewerTheme('colorTextInactiveCursor')};
    }

    &.cursor.highlight {
      animation: highlight-animation-unfocused ${hexViewerTheme('cursorBlinkSpeed')} step-start 0s infinite;
    }

    &.selection {
      background-color: ${hexViewerTheme('colorBackgroundInactiveSelection')};
      color: ${hexViewerTheme('colorTextInactiveSelection')};
    }

    &.selectionCursor {
      background-color: ${hexViewerTheme('colorBackgroundInactiveSelectionCursor')};
      color: ${hexViewerTheme('colorTextInactiveSelectionCursor')};
    }
  }
`
