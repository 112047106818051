import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import React from 'react'

const OpswatMarketingAlert = () => {
  const { _t } = useTypedTranslation()

  return (
    <p className="h6 mb-4 mt-4 font-weight-bold">
      {_t('to-have-access-to-all-fetaures')}
      <a
        href="https://www.opswat.com/products/metadefender/sandbox?utm_source=fsio&utm_medium=osintrepatxtl&utm_campaign=24_v1"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        {_t('sign-up').toLowerCase()}{' '}
      </a>
      {_t('for-a-free-trial')}.
    </p>
  )
}

export default OpswatMarketingAlert
