import { isBoolean } from 'lodash'
import React from 'react'
import { has } from '../helpers/functions'

/**
 * Display boolean value. Use half-transparent font when value is `false`
 *
 * @param item
 */
export function transparentBoolean(
  item: { [key: string]: any },
  key: string,
  defaultValue = '-'
): string | JSX.Element {
  if (!has(item, key)) {
    return defaultValue
  }

  if (typeof item[key] !== 'boolean') {
    return item[key]
  }

  const text = item[key] ? 'true' : 'false'

  let className = 'boolean'
  if (!item[key]) {
    className += ' opacity-3'
  }

  return <span className={className}>{text}</span>
}

/**
 * Display boolean value from number input. Use half-transparent font when value is `0` -> `false`
 *
 * @param value
 */
export const transparentBooleanFromNumber = (value: number, defaultValue = '-'): string | JSX.Element => {
  if (isNaN(value)) {
    return defaultValue
  }

  return getDisplayElement(value)
}

/**
 * Display boolean value from boolean input. Use half-transparent font when value is `false`
 *
 * @param value
 */
export const transparentBooleanFromBoolean = (value: boolean, defaultValue = '-'): string | JSX.Element => {
  if (!isBoolean(value)) {
    return defaultValue
  }

  return getDisplayElement(value)
}

/**
 * Returns the formatted output
 *
 * @param value
 */
const getDisplayElement = (value: number | boolean): JSX.Element => {
  const text = value ? 'true' : 'false'

  let className = 'boolean'

  if (!value) {
    className += ' opacity-3'
  }

  return <span className={className}>{text}</span>
}
