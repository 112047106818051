import { hasFields } from '../helpers'
import { FileScanReport, ScanReport, RejectedFile, SourceArchive } from '../types'

export class ReportWrapperBase {
  readonly reportsExist: boolean

  constructor(public _data: ScanReport | undefined) {
    this.reportsExist = !!this._data && Object.keys(this._data.reports).length > 0
  }

  /**
   * Get report for given file
   */
  getReport(reportId: string): FileScanReport | undefined {
    const reports = this.getReports()
    return reports && reports[reportId]
  }

  /**
   * Get all reports
   */
  getReports(): { [key: string]: FileScanReport } {
    return this._data?.reports || {}
  }

  /**
   * Get report that replaced given report due to rescan
   */
  getReportReplacementId(reportId: string): string | undefined {
    const reports = this.getReports()
    if (!reports) {
      return undefined
    }

    for (const id in reports) {
      const report = reports[id]
      const replacedIds = report.replaceReports || []
      if (replacedIds.includes(reportId)) {
        return id
      }
    }

    return undefined
  }

  /**
   * Get list of rejected files
   */
  getRejectedFiles(): RejectedFile[] {
    return this._data?.rejected_files || []
  }

  /**
   * Get reports together with reports general information
   */
  getAllReportsData(): ScanReport | undefined {
    return this._data
  }

  /**
   * Check if no data was loaded yet
   */
  isEmpty(): boolean {
    return typeof this._data === 'undefined' || !hasFields(this.getReports())
  }

  /**
   * Get scan init id
   */
  getFlowId(): string | undefined {
    const data = this.getAllReportsData()
    return data?.flowId
  }

  /**
   * Get report scanning priority
   */
  getPriority(): string | undefined {
    return this._data?.priority
  }

  /**
   * Get pause between polling report data
   */
  getDataPollPause(): number | undefined {
    return this._data?.pollPause
  }

  /**
   * Get data of uploaded archive
   */
  getSourceArchiveData(): SourceArchive | undefined {
    return this._data?.sourceArchive
  }

  /**
   * Get data of uploaded file size
   */
  getUploadedFileSizing(): { fileSize: number | undefined; fileReadProgressBytes: number | undefined } {
    return {
      fileSize: this._data?.fileSize,
      fileReadProgressBytes: this._data?.fileReadProgressBytes,
    }
  }

  /**
   * Get date/time of scan start
   */
  getScanStartedDate() {
    const date = this._data?.scanStartedDate
    return date ? new Date(date + 'Z') : undefined
  }
}

export type ReportWrapperConstructor = new (...args: any[]) => ReportWrapperBase
