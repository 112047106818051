import React, { ChangeEvent, ChangeEventHandler, CSSProperties, MouseEvent, useEffect, useState } from 'react'

export type TextInputType = 'text' | 'email' | 'password' | 'date' | 'number' | 'url'

const TextInput = ({
  id,
  className,
  placeholder,
  value,
  onChange,
  prefix,
  postfix,
  name,
  clearable,
  error,
  size = 'default',
  type = 'text',
  required,
  autoComplete,
  disabled,
  onClear,
  inputClassName,
  min,
  max,
  style,
  noWrap
}: {
  id?: string
  className?: string
  inputClassName?: string
  placeholder?: string
  value?: string | number | JSX.Element
  onChange?: ChangeEventHandler
  prefix?: string | JSX.Element
  postfix?: string | JSX.Element
  clearable?: boolean
  onClear?: CallableFunction
  error?: boolean
  required?: boolean
  size?: 'default' | 'big'
  type?: TextInputType
  autoComplete?: string | undefined
  name?: string
  disabled?: boolean
  min?: number | string
  max?: number | string
  style?: CSSProperties
  noWrap?: boolean
}): JSX.Element => {
  const [content, setContent] = useState(value ?? '')
  const [hideClearButton, setHideClearButton] = useState(true)

  useEffect(() => {
    setHideClearButton(content === '')
  }, [])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange ? onChange(event) : setContent(event.target.value)
    setHideClearButton(event.target.value === '')
  }

  const handleClearClick = (e: MouseEvent<HTMLLabelElement>) => {
    // Required to mock an actual element
    const inputElement = e.currentTarget.previousElementSibling as HTMLInputElement
    // Use input's id and empty value
    const changeEvent = {
      currentTarget: { ...inputElement, id: id, value: '' },
      target: { value: '' },
    } as ChangeEvent<HTMLInputElement>

    onChange ? onChange(changeEvent) : setContent('')
    onClear && onClear()
    setHideClearButton(true)
  }

  className = `input-field input-field-${size} ${className ?? ''} ${error ? 'input-field-error' : ''} ${noWrap ? 'no-wrap' : ''}`
  const postClass = !!postfix || clearable ? `input${postfix ? '-postfix' : ''}${clearable ? '-clearable' : ''}` : ''

  return (
    <div
      className={className}
      style={style}
    >
      <label id="prefix">{prefix}</label>
      {typeof value === 'string' || typeof value === 'number' || typeof value === 'undefined' ? (
        <input
          id={id}
          className={`${inputClassName ?? ''} ${prefix ? 'input-prefix' : ''} ${postClass}`}
          placeholder={placeholder}
          value={(value as string) ?? content}
          onChange={handleChange}
          autoComplete={autoComplete || 'off'}
          type={type}
          required={!!required}
          name={name}
          disabled={!!disabled}
          min={min}
          max={max}
        />
      ) : (
        <div
          id={id}
          className={`html-value-input ${inputClassName ?? ''} ${prefix ? 'input-prefix' : ''} ${postClass}`}
          placeholder={placeholder}
        >
          {value}
        </div>
      )}
      {clearable && !hideClearButton && (value || content) && (
        <label id="clear-button" onClick={handleClearClick}>
          <i className="ds3-icon ds3-close ds3-hover" />
        </label>
      )}
      <label id="postfix">{postfix}</label>
    </div>
  )
}

export default TextInput
