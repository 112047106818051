import { CoralogixBrowserSdkConfig, CoralogixDomain, CoralogixRum } from '@coralogix/browser';
import { getLogger } from 'app/components';
import { ApplicationConfiguration } from '../../types';
import { User } from '../../../auth/types';
import { userStorage } from 'app/modules/auth/helper/storage';

let isMonitoringActivated = false
const logger = getLogger('RUM:')

// Init client-side monitoring via third party lib
export function initMonitoring(config: ApplicationConfiguration | undefined) {
  if (!config?.coralogix) {
    toggleMonitoring()
    return
  }
  if (isMonitoringActivated) {
    logger.debug('Coralogix already activated')
    return
  }

  const settings: CoralogixBrowserSdkConfig = {
    public_key: config.coralogix.publicKey,
    application: config.coralogix.appName,
    version: config.coralogix.appVersion,
    coralogixDomain: config.coralogix.domain as CoralogixDomain
  }

  const settingsValid = validateInitSettings(settings)
  toggleMonitoring(settingsValid ? settings : undefined)
}

// Set the user to perform monitoring for
export function setMonitoringUser(user: User | undefined) {
  if (!isMonitoringActivated) {
    return
  }

  const clientId = userStorage.getClientId() ?? userStorage.setClientId()
  const userId = user ? user.accountId : clientId

  const meta = user ? {
    'role' : (
      user.isAdmin ? 'admin' :
      user.isAuditor ? 'auditor' :
      'user'
    )
  } : undefined

  const settings = {
    user_id: userId,
    user_name: user ? user.username : 'guest',
    user_email: user ? user.email : undefined,
    user_metadata: meta
  }

  logger.debug('Use monitoring for user: ', settings)
  CoralogixRum.setUserContext(settings)
}

// Either activate or disable monitoring
function toggleMonitoring(settings?: CoralogixBrowserSdkConfig) {
  isMonitoringActivated = !!settings

  if (settings) {
    logger.debug('Initialize Coralogix monitoring using settings: ', settings)
    CoralogixRum.init(settings)
  } else {
    logger.warn("Shut down Coralogix monitoring, if it was enabled")
    CoralogixRum.shutdown()
  }
}

// Check if init settings are all set
function validateInitSettings(settings: CoralogixBrowserSdkConfig) {
  for (const name in settings) {
    if (!settings[name as keyof typeof settings]) {
      logger.warn(`Can not initialize Coralogix monitoring, value for '${name}' setting is missing`)
      return false
    }
  }

  return true
}
