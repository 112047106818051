import { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'
import { tokensStorage } from '../helper/storage'

/**
 * Set "Authorization" header if user authenticated
 */
export function authHttpInterceptor(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  const token = tokensStorage.getAccessToken()
  // Cookies will be handled through CookieYes, below code is commented and kept for reference
  // const isCookieNoteClosed = window.localStorage.getItem('cookieNote')
  // const allowCookies = window.localStorage.getItem('allowCookies')
  //
  // const allowCookiesFinal = (
  //   (isCookieNoteClosed && !allowCookies) || // old users
  //   allowCookies === 'true'
  // )

  config.headers = config?.headers ?? ({} as AxiosRequestHeaders)

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  // if (!allowCookiesFinal) {
  //   config.headers['No-Cookies'] = '1';
  // }

  return config
}
