import React from 'react'
import { Navigate } from 'react-router'
import { getPageUrl } from '../../routing/pages'
import { routeToRegexp } from '../../routing/helpers'
import { signinRedirectStorage } from 'app/modules/auth/helper/storage'
import { createQueryString } from '../../querystring'
import { castSearchParamsToObject } from 'app/modules/reports/helpers'
import { logger } from 'app/components'

export type RedirectParams = {
  pageKey: string
  url: string
  query?: { [key: string]: any }
}

function RedirectAfterSignin() {
  function setRedirect(pageKey: string, url: string, query?: URLSearchParams) {
    const redirectData = { pageKey, url, query: query ? castSearchParamsToObject(query) : undefined }
    logger.debug('Set redirect: ', redirectData)
    signinRedirectStorage.saveRedirect(redirectData)
  }

  function doAfterSignin() {
    const redirectData = signinRedirectStorage.getRedirect()
    logger.debug('Got redirect: ', redirectData)
    if (!redirectData) {
      return
    }

    const routeTmpl = getPageUrl(redirectData.pageKey)
    if (!routeTmpl) {
      throw Error(`Invalid page ${redirectData.pageKey} for redirect`)
    }

    const url = redirectData.url
    if (!validateRedirectUrl(url, routeTmpl)) {
      throw Error(`Redirect url ${url} does not match route tmpl ${routeTmpl}`)
    }

    let query = ''
    if (redirectData.query) {
      query = createQueryString(redirectData.query)
    }

    if (query) {
      query = '?' + query
    }

    return <Navigate to={url + query} replace />
  }

  function doOnPageLoad(pageKey: string) {
    // These are pages that user can switch to from signin dialog.
    // We consider him still wanting to signin if he's on one of them.
    // If he goes to some other page without signin, then we forget that
    // redirect after signin was going to happen.
    if (['sign-in', 'sign-up', 'request-password-reset', 'oauth-redirect'].indexOf(pageKey) === -1) {
      logger.debug('Clear redirect')
      signinRedirectStorage.clearRedirect()
    }
  }

  return { setRedirect, doAfterSignin, doOnPageLoad }
}

/**
 * Sanity check before redirecting to some page
 */
function validateRedirectUrl(url: string, routeTmpl: string) {
  const routeRegexp = routeToRegexp(routeTmpl)

  return !!url.match(routeRegexp)
}

export const redirectAfterSignin = RedirectAfterSignin()
