import React from 'react'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { PageData } from '../../../shared/routing'
import { GetBackAfterSignin } from '../../../shared/routing/back-after-signin'

export function TrendsOnForbidden({ page }: { page: PageData }): JSX.Element {
  const { _t } = useTypedTranslation()

  return <GetBackAfterSignin page={page} message={_t('trends-signin-required')} />
}
