import {
  IOCDataTypes,
  IOCFileTypes,
  IOCGroupedByFileType,
  IOCGroupedByDataType,
  IOCItem,
  GeolocationItem,
  IOCFinalGroup,
  AllExtractedFiles,
  ExtractedFile,
  AllExtractedFilesTypes,
} from '../../types'
import BaseFormatter from './base'
import { has } from '../../helpers/functions'
import { formatEnumItem } from '../../helpers'

/**
 * Merge geolocation data into IOC IPs.
 * This formatter should be launched after formatters for IOC and geolocation.
 */
export default class MergeIOCGeolocationFormatter extends BaseFormatter {
  cache: { [key: string]: IOCItem[] } = {}

  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    if (!has(report, 'geolocation') || !report.geolocation.length) {
      return
    }

    this.prepareIOCContainer(report)
    this.cacheIps(report)

    const newIps: GeolocationItem[] = []
    report.geolocation.forEach((item: GeolocationItem) => {
      const ip = item.inetAddr

      if (has(this.cache, ip)) {
        this.cache[ip].forEach((ipData: IOCItem) => {
          // Cached IPs objects still reference IPs objects in IOC report section.
          // So when we add data to cached object, it is also added to report.
          ipData.geoData = item
        })
      } else {
        newIps.push(item)
      }
    })

    this.mergeNewIps(newIps, report.ioc.data, report.derivedFiles, report.file)
  }

  /**
   * Make sure we have IOC containers in final report
   */
  prepareIOCContainer(report: { [key: string]: any }): void {
    if (!has(report, 'ioc')) {
      const ioc: IOCGroupedByFileType = {}
      report.ioc = ioc
    }
  }

  /**
   * Add IP from geolocation to IOC container
   */
  addNewIp(ipData: GeolocationItem, output: IOCItem[]): void {
    const ip: IOCItem = {
      data: ipData.inetAddr,
      geoData: ipData,
      origins: ['Domain resolve'],
    }

    output.push(ip)
  }

  /**
   * Flatten IOC IPs structure in a separate cache to lookup for
   * corresponding IOC IP for each geolocation IP
   */
  cacheIps(report: { [key: string]: any }): void {
    this.cacheFileIps(report.ioc[IOCFileTypes.Main])

    if (has(report.ioc, IOCFileTypes.ExtractedFiles)) {
      const files = report.ioc[IOCFileTypes.ExtractedFiles]
      for (const hash in files) {
        this.cacheFileIps(files[hash])
      }
    }

    if (has(report.ioc, IOCFileTypes.DownloadedFiles)) {
      const files = report.ioc[IOCFileTypes.DownloadedFiles]
      for (const hash in files) {
        this.cacheFileIps(files[hash])
      }
    }
  }

  /**
   * Cache IPs extracted from a single file.
   */
  cacheFileIps(source: IOCGroupedByDataType): void {
    if (!source || !has(source, IOCDataTypes.IPs)) {
      return
    }

    const groups = source[IOCDataTypes.IPs]

    groups?.forEach((group: IOCFinalGroup) => {
      group.references.forEach((ipData: IOCItem) => {
        const ip = ipData.data

        if (!has(this.cache, ip)) {
          this.cache[ip] = []
        }

        // The same IP can be present in multiple files. We cache references to
        // all such IP IOCs, to add geodata to all of them
        this.cache[ip].push(ipData)
      })
    })
  }

  mergeNewIps(
    items: GeolocationItem[],
    ioc: { [key: string]: any },
    derivedFiles: AllExtractedFiles | undefined,
    mainFile: { [key: string]: any }
  ): void {
    if (!items.length || !mainFile.hash) {
      return
    }

    const filesMap: { [key in IOCFileTypes]?: { [hash: string]: boolean } } = {
      [IOCFileTypes.Main]: { [mainFile.hash]: true },
    }
    const filesTypes: AllExtractedFilesTypes[] = [
      AllExtractedFilesTypes.ExtractedFiles,
      AllExtractedFilesTypes.DownloadedFiles,
      AllExtractedFilesTypes.ContextFiles,
    ]

    // Build helper map to quickly check if file with given origin hash exists for given IP
    filesTypes.forEach((type) => {
      if (!derivedFiles || typeof derivedFiles[type] === 'undefined') {
        return
      }

      const hashesMap: { [hash: string]: boolean } = {}
      derivedFiles[type].forEach((file) => {
        const hash = file.hashes['sha-256']
        hashesMap[hash] = true
      })

      filesMap[type] = hashesMap
    })

    items.forEach((item) => {
      // Assume IP belongs to main file if there's no origin
      if (!item.resource.origin) {
        if (!has(ioc, IOCFileTypes.Main)) {
          ioc[IOCFileTypes.Main] = {}
        }
        this.addIpToFile(item, ioc[IOCFileTypes.Main], 'Domain resolve')
        return
      }

      Object.keys(filesMap).forEach((fileType) => {
        const hashesMap = filesMap[fileType as IOCFileTypes]
        const originHash = item.resource.origin?.identifier as string
        if (hashesMap && !has(hashesMap, originHash)) {
          return
        }

        // We detected origin file where IP belongs
        // Now prepare IOC container to save IP
        if (!has(ioc, fileType)) {
          ioc[fileType] = {}
        }

        let target = ioc[fileType]
        if (filesTypes.indexOf(fileType as AllExtractedFilesTypes) !== -1) {
          if (!has(ioc[fileType], originHash)) {
            ioc[fileType][originHash] = {}
          }
          target = ioc[fileType][originHash]
        }

        let originType = item.resource.origin?.type as string
        if (['INPUT_FILE', 'EXTRACTED_FILE', 'DOWNLOADED_FILE'].indexOf(originType) !== -1) {
          originType = 'Domain resolve'
        } else {
          originType = formatEnumItem(originType)
        }

        this.addIpToFile(item, target, originType)
      })
    })
  }

  addIpToFile(item: GeolocationItem, file: { [key: string]: any }, originType: string) {
    const fileIps: IOCFinalGroup[] = file[IOCDataTypes.IPs] ?? []
    let targetGroup = fileIps
      .filter((group: IOCFinalGroup) => {
        return group.origins.indexOf(originType) !== -1
      })
      .pop()

    if (!targetGroup) {
      targetGroup = {
        origins: [originType],
        references: [],
      }
      fileIps.push(targetGroup)
    }

    targetGroup.references.push({
      data: item.inetAddr,
      geoData: item,
    })

    if (!has(file, IOCDataTypes.IPs)) {
      file[IOCDataTypes.IPs] = fileIps
    }
  }
}
