import React, { useContext } from 'react'
import { isObject, isSimpleObject, hasFields, isFloat, isHash, upperAbbr, capitalize } from '../helpers/functions'
import { Copy2Clipboard } from './copy-to-clipboard'
import { buildLongText } from '../components/long-text'
import { modalContext } from '../../shared/context/modal-context'
import { useExport } from '../contexts/export-context'
import { ModalWrapperType } from 'app/modules/shared/types'

/**
 * Generic component for displaying data with label
 */
type CommonLabeledDataRowProps = {
  value: any
  label: string
  enableCopy?: boolean
  checkHash?: boolean
  lengthLimit?: number
  style?: any
  showEmpty?: boolean
}
export function CommonLabeledDataRow(props: Readonly<CommonLabeledDataRowProps>): JSX.Element | null {
  const { value, label, enableCopy, checkHash, lengthLimit, style, showEmpty } = props
  const { exporting } = useExport()
  const modalWrapper = useContext(modalContext)

  const isEmpty = checkValueEmpty(value)
  if (isEmpty && showEmpty === false) {
    return null
  }

  const className = 'data-row'
  let realValue: any
  const realLabel = upperAbbr(label)

  if (isSimpleObject(value) && 'component' in value) {
    return buildComponentValue(value, className, realLabel)
  }

  if (Array.isArray(value)) {
    let hasObject = false

    realValue = value.map((item, idx) => {
      const isItemObject = isObject(item)
      hasObject = hasObject || isItemObject

      return isItemObject ? (
        <CommonLabeledDataRow
          key={idx}
          label=""
          value={item}
          enableCopy={enableCopy}
          checkHash={checkHash}
          lengthLimit={lengthLimit}
        />
      ) : (
        <span key={idx}>{item}</span>
      )
    })

    realValue = <div className="d-flex flex-column">{realValue}</div>
  } else {
    realValue = buildValue(value, enableCopy, checkHash, lengthLimit, isEmpty, exporting, modalWrapper)
  }

  const useCopy = shouldUseCopy(isEmpty, realValue, realLabel, checkHash, enableCopy)
  const copyButton = useCopy ? <Copy2Clipboard copyText={realValue} /> : null

  return (
    <div className={className} style={style}>
      <div className="label">{realLabel ? realLabel + ':' : '-'}&nbsp;</div>
      <div className={`value text-break`}>
        <div className="d-flex">
          {realValue}
          {copyButton}
        </div>
      </div>
    </div>
  )
}

function checkValueEmpty(value: any) {
  return value === '' || (Array.isArray(value) && !value.length) || (isSimpleObject(value) && !hasFields(value))
}

function shouldUseCopy(
  isEmpty: boolean,
  realValue: any,
  realLabel: string,
  checkHash: boolean | undefined,
  enableCopy: boolean | undefined
) {
  return !isEmpty && !isObject(realValue) && (enableCopy || (checkHash && isHash(realLabel)))
}

function buildValue(
  value: any,
  enableCopy: boolean | undefined,
  checkHash: boolean | undefined,
  lengthLimit: number | undefined,
  isEmpty: boolean,
  exporting: boolean,
  modalWrapper: ModalWrapperType
) {
  if (isEmpty) {
    return '-'
  }

  if (isFloat(value, true)) {
    return value.toPrecision(2)
  }

  if (isSimpleObject(value)) {
    const formatted = Object.keys(value).map((key) => (
      <CommonLabeledDataRow
        key={key}
        label={capitalize(key)}
        value={value[key]}
        enableCopy={enableCopy}
        checkHash={checkHash}
        lengthLimit={lengthLimit}
      />
    ))

    return <div className="d-flex flex-column">{formatted}</div>
  }

  if (typeof value === 'boolean') {
    return <span className="boolean">{value ? 'true' : 'false'}</span>
  }

  return lengthLimit && !exporting ? buildLongText(value, modalWrapper, true, lengthLimit) : value
}

function buildComponentValue(value: any, className: string, realLabel: string) {
  return value.float ? (
    <div className={className}>
      <div className="label float-left">{value.label ?? realLabel}</div>
      <div className={`value text-break d-block`}>{value.component}</div>
    </div>
  ) : (
    <div className={className}>
      {value.label ? (
        <div className="label">{value.label}:</div>
      ) : (
        <div className="label">{realLabel ? realLabel + ':' : '-'}</div>
      )}
      <div className={`value text-break ` + (value.componentClass || '')}>
        {value.component}
        {value.text && value.copy && <Copy2Clipboard copyText={value.text} />}
      </div>
    </div>
  )
}
