import BaseFormatter from './base'
import { castStrengthToVerdict, hasFields } from '../../helpers'
import { AllFilesTypes, SignalVerdict } from '../../types'
import {
  ReportFilterSection,
  ReportFilterSectionType,
  ReportFilterSettings,
  ReportPageFilterSettings,
  ReportPageMultiFileFilterSettings,
} from '../../components/filter/types'
import { FileYaraData, YaraFilterStat, YaraRule } from '../../features/yara-report/types'
import { inc } from 'app/modules/shared/helpers'

/**
 * Format data needed for Yara Matches report subpage
 */
export default class YaraFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const fileResource = this.getResource(data, 'file')
    if (!fileResource) {
      return
    }

    const result: { [key: string]: any } = {}
    const filter: ReportPageMultiFileFilterSettings = { files: {} }
    const [yaraData, fileFIlter] = this.formatSingleFileYara(fileResource)

    if (yaraData) {
      result[AllFilesTypes.MainFile] = yaraData
      filter.files[AllFilesTypes.MainFile] = fileFIlter
    }

    const extractedFiles: any[] = []
    const contextFiles: any[] = []
    fileResource.extractedFiles?.forEach((file: any) => {
      const target = file.origin?.type === 'INPUT_CONTEXT' ? contextFiles : extractedFiles
      target.push(file)
    })

    this.formatDerivedFiles(extractedFiles, 'extractedFiles', result, filter)
    this.formatDerivedFiles(contextFiles, 'contextFiles', result, filter)
    this.formatDerivedFiles(this.getDownloadedFiles(data), 'downloadedFiles', result, filter)

    if (hasFields(result)) {
      report.yara = {
        data: result,
        filter,
      }
    }
  }

  formatDerivedFiles(
    files: any[],
    key: string,
    result: { [key: string]: any },
    filter: ReportPageMultiFileFilterSettings
  ) {
    const derivedData: { [key: string]: any } = {}
    files?.forEach((fileData: { [key: string]: any }) => {
      const [yara, fileFilter] = this.formatSingleFileYara(fileData)
      const hash: string = fileData.digests['SHA-256']

      if (yara) {
        derivedData[hash] = yara
        filter.files[hash] = fileFilter
      }
    })

    if (hasFields(derivedData)) {
      result[key] = derivedData
    }
  }

  formatSingleFileYara(fileData: {
    [key: string]: any
  }): [{ [key: string]: any }, ReportPageFilterSettings] | [undefined, undefined] {
    const matches: any[] = []
    const fileFilter = this.formatYaraMatches(fileData.yaraMatches, matches)

    const info: { [key: string]: any } = {}
    this.formatYaraInfo(fileData, info)

    const result: FileYaraData = { matches: [], unfilteredMatchesAmount: 0 }
    if (matches.length) {
      result.matches = matches
      result.unfilteredMatchesAmount = matches.length
    }

    if (hasFields(info)) {
      result.info = info
    }

    if (fileData.generatedYaraRule) {
      result.generated = fileData.generatedYaraRule
    }

    return result.generated || result.matches.length ? [result, fileFilter] : [undefined, undefined]
  }

  /**
   * Apply format to a single field from response
   *
   * @param {object} input      Data obtained from given field in report response
   * @param {object} output     Container to put formatted result in
   * @param {string} fieldName  Field name
   */
  formatYaraMatches(input: any[], output: any[]): ReportPageFilterSettings {
    const sectionsCache: { [key: string]: boolean } = {}
    const sections = this.getFilterSectionsTmpl()
    const stat = this.getFilterStatTmpl()

    const existingNames = output.map((item: { [key: string]: any }) => {
      return item.ruleName
    })

    const existingNamesMap: { [key: string]: boolean } = {}
    existingNames.forEach((name: string) => {
      existingNamesMap[name] = true
    })

    input.forEach((item: { [key: string]: any }) => {
      if (typeof existingNamesMap[item.ruleName] !== 'undefined') {
        return
      }

      const initialVerdict = castStrengthToVerdict(item.strength || 0, true)
      const verdict = this.fixVerdictByTags(initialVerdict, item.tags)

      const rule: YaraRule = {
        ruleName: item.ruleName,
        verdict: verdict,
        matchedStrings: item.matchedStrings,
        tags: item.tags,
        metaData: item.metaData,
        description: '',
      }

      if (typeof rule.metaData.description !== 'undefined') {
        rule.description = rule.metaData.description
        delete rule.metaData.description
      }

      output.push(rule)
      existingNamesMap[rule.ruleName] = true

      this.gatherFiterSections(rule, sections, sectionsCache)
      this.gatherFiterStat(rule, stat)
    })

    return { sections: Object.values(sections), stat }
  }

  fixVerdictByTags(verdict: SignalVerdict, tags: any[] | undefined) {
    if (verdict === SignalVerdict.Malicious) {
      return verdict
    }

    for (const tag of tags || []) {
      if (typeof tag?.tag?.verdict?.threatLevel !== 'undefined') {
        const tagVerdict = castStrengthToVerdict(tag.tag.verdict.threatLevel, true)
        if (tagVerdict === SignalVerdict.Malicious) {
          return tagVerdict
        }
      }
    }

    return verdict
  }

  gatherFiterSections(
    item: YaraRule,
    sections: { [sectionName: string]: ReportFilterSection },
    sectionsCache: { [key: string]: boolean }
  ) {
    const author = item.metaData?.author
    if (author && typeof sectionsCache[author] === 'undefined') {
      sections.author.items.push({ value: author })
      sectionsCache[author] = true
    }
  }

  gatherFiterStat(item: YaraRule, stat: YaraFilterStat) {
    const author = item.metaData?.author

    author && (stat.types.author[author] = inc(stat.types.author[author]))
    item.verdict && (stat.types.verdict[item.verdict] = inc(stat.types.verdict[item.verdict]))

    stat.total++
  }

  formatYaraInfo(input: { [key: string]: any }, output: { [key: string]: any }): void {
    const fields = ['yaraNumberOfRules', 'yaraNumberOfStrings']
    this.copyFields(fields, input, output)
  }

  getFilterSectionsTmpl() {
    const sections: { [sectionName: string]: ReportFilterSection } = {
      verdict: { type: ReportFilterSectionType.Verdict, name: 'verdict', items: [] },
      author: { type: ReportFilterSectionType.List, name: 'author', items: [], placeholder: 'select-authors' },
    }

    return sections
  }

  getFilterStatTmpl() {
    const stat: YaraFilterStat = {
      types: { verdict: {}, author: {} },
      total: 0,
    }

    return stat
  }
}
