import {
  IOCDataTypes,
  IOCFileTypes,
  IOCGroupedByFileType,
  IOCGroupedByDataType,
  IOCFinalGroup,
  IOCFilterStat,
  mainFileKey,
  IOCPrevalenceVerdicts,
  IOCDataTypesName,
} from '../../types'
import BaseFormatter from './base'
import {
  ReportFilterSection,
  ReportFilterSectionType,
  ReportPageMultiFileFilterSettings,
} from '../../components/filter/types'
import { inc } from 'app/modules/shared/helpers'

/**
 * Gather data for IOC page filter.
 * Do thias in a separate formatter, because data for IOCs page is gathered in multiple formatters.
 */
export default class IOCFilterFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    if (!report.ioc) {
      return
    }

    report.ioc.filter = this.gatherFilter(report.ioc.data)
  }

  gatherFilter(result: IOCGroupedByFileType) {
    const filter: ReportPageMultiFileFilterSettings = { files: {} }

    for (const fileType of Object.values(IOCFileTypes)) {
      if (!result[fileType]) {
        continue
      }

      if (fileType === IOCFileTypes.Main) {
        filter.files[mainFileKey] = this.gatherFileFilter(result[mainFileKey])
        continue
      }

      for (const hash of Object.keys(result[fileType])) {
        filter.files[hash] = this.gatherFileFilter(result[fileType][hash])
      }
    }

    return filter
  }

  // This function is also called from IOCs page controller, to
  // rebuild filter when we get prevalence information
  gatherFileFilter(data: IOCGroupedByDataType, prevalenceVerdicts?: IOCPrevalenceVerdicts) {
    const sections: { [sectionName: string]: ReportFilterSection } = this.getFilterSectionsTmpl()
    const stat = this.getFilterStatTmpl()
    const sectionsCache: { [key: string]: boolean } = {}

    const iocTypes: { [key in IOCDataTypes]?: IOCDataTypesName } = {}
    for (const key in IOCDataTypes) {
      const iocField = IOCDataTypes[key as keyof typeof IOCDataTypes]
      iocTypes[iocField] = IOCDataTypesName[key as keyof typeof IOCDataTypesName]
    }

    for (const iocType in data) {
      const groups = data[iocType as IOCDataTypes] as IOCFinalGroup[]
      const iocTypeName = iocTypes[iocType as IOCDataTypes] as string

      if (typeof sectionsCache[iocType] === 'undefined') {
        sections.type.items.push({ value: iocType, name: iocTypeName })
        sectionsCache[iocType] = true
      }

      for (let groupIdx = 0; groupIdx < groups.length; groupIdx++) {
        const group = groups[groupIdx]

        for (const origin of group.origins) {
          stat.types.origin[origin] = inc(stat.types.origin[origin], group.references.length)

          if (typeof sectionsCache[origin] === 'undefined') {
            sections.origin.items.push({ value: origin })
            sectionsCache[origin] = true
          }
        }

        stat.total = inc(stat.total, group.references.length)

        for (let iocIdx = 0; iocIdx < group.references.length; iocIdx++) {
          const ioc = group.references[iocIdx]

          stat.types.type[iocType] = inc(stat.types.type[iocType])
          if (ioc.verdict) {
            stat.types.verdict[ioc.verdict] = inc(stat.types.verdict[ioc.verdict])
          }
        }
      }
    }

    return { sections: Object.values(sections), stat }
  }

  getFilterSectionsTmpl() {
    const sections: { [sectionName: string]: ReportFilterSection } = {
      search: { type: ReportFilterSectionType.Search, name: 'search', items: [], placeholder: 'search' },
      verdict: { type: ReportFilterSectionType.Verdict, name: 'verdict', items: [] },
      type: { type: ReportFilterSectionType.Flags, name: 'type', items: [] },
      origin: { type: ReportFilterSectionType.Flags, name: 'origin', items: [] },
    }

    return sections
  }

  getFilterStatTmpl() {
    const stat: IOCFilterStat = {
      types: { verdict: {}, type: {}, origin: {} },
      total: 0,
    }

    return stat
  }
}
