import React, { useCallback, useEffect, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger'
import OutsideClickHandler from 'react-outside-click-handler'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { Placement } from '@popperjs/core'
import { useExport } from '../contexts'

type LabelWithIconProps = {
  label?: string | JSX.Element
  noteTitle?: string | JSX.Element
  noteComponent?: JSX.Element
  note?: string
  noteParam?: { [key: string]: any }
  noteId?: string
  right?: boolean
  variant?: string
  className?: string
  iconClass?: string
  placement?: Placement
  trigger?: OverlayTriggerType | OverlayTriggerType[]
  widePopover?: boolean
  style?: { [key: string]: string }
}

export function LabelWithIcon(props: LabelWithIconProps): JSX.Element {
  let { label, trigger } = props
  const {
    note,
    noteId,
    noteParam,
    right,
    noteTitle,
    noteComponent,
    variant,
    placement,
    widePopover,
    style,
    iconClass,
  } = props
  const { _t } = useTypedTranslation()
  const { exporting } = useExport()
  const [show, setShow] = useState(false)
  const iconClassName = iconClass ?? 'ds3-icon ds3-information'
  const popoverClassName = `responsive-popover ${widePopover ? 'popover-wide' : undefined}`
  const delay = 250

  let className = iconClassName + ' general-mark ' + (right ? 'right-mark' : 'left-mark')
  if (variant === 'primary' || !variant) {
    className += ' text-primary'
  } else if (variant === 'light') {
    className += ' text-muted-light'
  } else if (variant === 'main') {
    className += ' color-text-main'
  }
  if (props.className) {
    className += ` ${props.className}`
  }

  label = label ?? ''
  trigger = trigger ?? 'hover'
  const showPopper = trigger === 'click' ? show : undefined

  const onToggle = () => {
    if (trigger === 'click') {
      setShow((show) => !show)
    }
  }

  return (
    <span className="d-inline-flex align-items-center label-icon-container" style={style}>
      {right && label}

      {!exporting &&
        (noteId ? (
          <OutsideClickHandler
            onOutsideClick={(e) => {
              e.stopPropagation()
              setShow(false)
            }}
          >
            <OverlayTrigger
              placement={placement ?? 'auto'}
              trigger={trigger}
              onToggle={onToggle}
              show={showPopper}
              delay={delay}
              overlay={
                <Popover id={noteId} className={popoverClassName} onClick={onToggle}>
                  {noteTitle && <Popover.Header>{noteTitle}</Popover.Header>}
                  <Popover.Body>
                    {noteComponent ? (
                      noteComponent
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: _t(note ?? '', noteParam),
                        }}
                      />
                    )}
                  </Popover.Body>
                </Popover>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <i {...triggerHandler} ref={ref} className={className} style={{ cursor: 'pointer' }}></i>
              )}
            </OverlayTrigger>
          </OutsideClickHandler>
        ) : (
          <i className={className} />
        ))}

      {right || label}
    </span>
  )
}
