import React, { createContext, useState } from 'react'
import { RouteState, RouteStateData } from '../types'

export const routeState = createContext<RouteState>({} as RouteState)

/**
 * Context to store any variables to share between components
 */
export const WithRouteStateContext = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element | null => {
  const [routeData, setRouteData] = useState<RouteStateData>({} as RouteStateData)
  let cache = {}

  const setValues = function (values: { [key: string]: any }) {
    cache = { ...cache, ...values }
    setRouteData({ ...routeData, ...cache })
  }

  const value: RouteState = { data: routeData, setData: setRouteData, setValues }

  return <routeState.Provider value={value}>{children}</routeState.Provider>
}
