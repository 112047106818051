import { HttpService } from './http.service'
import { MitreData } from '../types'
import { apiRoutes } from '../routing/api-routes'
import { YaraRuleExtendedData } from 'app/modules/reports/features/yara-report/types'
import { ThreatIndicators } from 'app/modules/reports/types'

export const AssetsService = {
  getMitreData: (): Promise<MitreData> => {
    return HttpService.get(apiRoutes.mitreData)
  },
  getLanguages: (): Promise<{ [key: string]: string }> => {
    return HttpService.get(apiRoutes.languages)
  },
  getCountries: (): Promise<{ [key: string]: string }> => {
    return HttpService.get(apiRoutes.countries)
  },
  getYaraRules: (names?: string[]): Promise<YaraRuleExtendedData[]> => {
    const params = Array.isArray(names)
      ? names.length === 0
        ? ['name=']
        : names.map((name) => 'name=' + name)
      : undefined

    return HttpService.get(apiRoutes.getYaraExtendedData + (params?.length ? '?' + params.join('&') : ''))
  },
  getYaraAuthors: (names?: string[]): Promise<string[]> => {
    const params = Array.isArray(names)
      ? names.length === 0
        ? ['name=']
        : names.map((name) => 'name=' + name)
      : undefined

    return HttpService.get(apiRoutes.getYaraAuthors + (params?.length ? '?' + params.join('&') : ''))
  },
  getThreatIndicators: (): Promise<ThreatIndicators[]> => {
    return HttpService.get(apiRoutes.signalGroups)
  },
  getMimeTypes: (): Promise<string[]> => {
    return HttpService.get(apiRoutes.getMimeTypes)
  },
}
