import { FileIcon, FileIconProps } from 'react-file-icon'
import React from 'react'

type CustomFileIconProps = {
  extension?: string
  color?: string
  childProps?: Partial<FileIconProps>
}

const CustomFileIcon = ({ extension, color, childProps }: CustomFileIconProps): React.JSX.Element => {
  const Icon = FileIcon({ extension, color, ...childProps }) as React.JSX.Element

  return <div>{Icon}</div>
}

export default CustomFileIcon
