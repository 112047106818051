import React, { useContext } from 'react'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { GenericTabsOptions, DetailsOverviewProps } from '../../types'
import { GenericTabs } from '../tabs'
import { ObjectRows } from '../../helpers/object-to-data-rows'
import { LabelWithIcon } from '../label-with-icon'
import { reportContext, useExport, useSelector } from '../../contexts'
import { modalContext } from '../../../shared/context/modal-context'
import { ModalWrapperType } from '../../../shared/types'
import { withSearch } from './with-search'
import { DefaultOverviewBySections } from './sections-overview'

/**
 * Generic component to display a page with the following items:-
 * - on the top - overview-like block, that is always visible
 * - underneath it - block with tabs
 */
type OverviewWithTabsProps = {
  data: { [key: string]: any }
  overview: { title: string | React.JSX.Element; handler?: CallableFunction; bySections?: boolean }
  tabs: { title: string; options: GenericTabsOptions; mountOnEnter?: boolean }
}
export function OverviewWithTabs(props: OverviewWithTabsProps): React.JSX.Element {
  const { data, overview, tabs } = props
  const Overview = overview?.handler && (overview.handler({ data: data }) as React.JSX.Element)

  return (
    <div className="overview-with-tabs">
      <h2 className="h3 mb-4">{overview.title}</h2>
      {Overview ?? <DefaultOverview data={data} bySections={overview.bySections} />}

      <div className="overview-tabs mt-7">
        <h2 className="h3 mb-4">{tabs.title}</h2>
        <GenericTabs data={data} options={tabs.options} mountOnEnter={!!tabs.mountOnEnter} />
      </div>
    </div>
  )
}

/**
 * Default simple function for building overview
 *
 * @param data
 */
export function DefaultOverview(props: DetailsOverviewProps): JSX.Element | null {
  const { data, bySections } = props

  const { _t } = useTypedTranslation()
  const { exporting, reportId } = useExport()
  const modalWrapper = useContext(modalContext)
  const reportsWrapper = useContext(reportContext)
  const hash = reportsWrapper.getFileHash(reportId)
  const isMain = hash === data.overview['SHA-256']

  const checked = useSelector((state) => state.similar.done)
  const error = useSelector((state) => state.similar.error)
  const similars = useSelector((state) => state.similar.data)

  if (!data.overview) {
    return null
  }

  const overview = { ...data.overview }
  delete overview.language
  applySearchToHashes(overview, reportId, modalWrapper, exporting, isMain, checked, error, similars, _t)

  if (bySections || typeof bySections === 'undefined') {
    return <DefaultOverviewBySections data={overview} />
  }

  return <ObjectRows object={overview} enableCopy={false} checkHash={!exporting} lengthLimit={64} />
}

function applySearchToHashes(
  overview: { [key: string]: any },
  reportId: string,
  modalWrapper: ModalWrapperType,
  exporting: boolean,
  isMain: boolean,
  checked: boolean,
  error: string,
  similars: { [key: string]: any },
  _t: CallableFunction
) {
  overview.fsiofuzzyhash &&
    (overview.fsiofuzzyhash = withSearch(
      isMain,
      checked,
      error,
      similars,
      modalWrapper,
      exporting,
      reportId,
      'fuzzyfsiohash',
      overview.fsiofuzzyhash,
      _t,
      <LabelWithIcon
        style={{ marginLeft: exporting ? '0' : '-20px' }}
        note="fuzzyhash-note"
        noteId="fuzzyhash-info"
        label={_t('fsiofuzzyhash')}
        trigger={['hover', 'focus']}
      />
    ))
  overview.ssdeep &&
    (overview.ssdeep = withSearch(
      isMain,
      checked,
      error,
      similars,
      modalWrapper,
      exporting,
      reportId,
      'ssdeep',
      overview.ssdeep,
      _t
    ))
  overview.imphash &&
    (overview.imphash = withSearch(
      isMain,
      checked,
      error,
      similars,
      modalWrapper,
      exporting,
      reportId,
      'imphash',
      overview.imphash,
      _t
    ))
  overview.authentihash &&
    (overview.authentihash = withSearch(
      isMain,
      checked,
      error,
      similars,
      modalWrapper,
      exporting,
      reportId,
      'authentihash',
      overview.authentihash,
      _t
    ))
}
