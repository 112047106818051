import { Feature } from './feature'
import { ScanProfile } from 'app/modules/user/features/profile/types'

export enum UserNotification {
  DarkTheme = 'dark_theme',
}

export type User = {
  accountId: string
  username: string
  email: string
  isAdmin?: boolean
  isAuditor?: boolean
  isPasswordEmpty?: boolean
  avatarLastModified: string
  allowedFeatures?: Feature[]
  apiKey?: string
  theme?: string
  timezone?: string
  locale?: string
  externalAuth?: {
    service: string
    userId: string
  }
  notifications?: UserNotification[]
  scanProfiles: ScanProfile[]
}
