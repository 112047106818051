export const apiRoutes = {
  config: '/api/system/config',
  scanFile: '/api/scan/file',
  scanUrl: '/api/scan/url',
  reports: '/api/scan/{flowId}/report',
  report: '/api/reports/{reportId}/{hash}',
  downloadReport: '/api/reports/{reportId}/download',
  startSlowDownloadReport: '/api/reports/{reportId}/download/start',
  files: '/api/reports/{reportId}/files?type=all',
  extractedFiles: '/api/reports/{reportId}/files?type=extracted',
  downloadedFiles: '/api/reports/{reportId}/files?type=downloaded',
  file: '/api/files/{hash}?type=base64',
  downloadFile: '/api/files/{hash}?type=raw',
  uploads: '/api/reports',
  myuploads: '/api/me/reports',
  myDownloads: '/api/users/me/downloads',
  myReportsLinks: '/api/me/reports/links',
  reportSearch: '/api/reports/search',
  advancedSearch: '/api/reports/advanced-search',
  downloadIOCs: '/api/threatintel/download-iocs',
  getPrevalence: '/api/threatintel/get-prevalence',
  getSimilarReports: '/api/threatintel/get-similars',
  getFrequentTags: '/api/users/get-frequent-tags',
  getInterestingReport: '/api/users/most-interesting',
  profile: '/api/users/profile',
  resetTimezoneLocale: '/api/users/reset-timezone-locale',
  privacyPolicy: '/api/system/get-terms/privacy-policy',
  queryHealthcheck: '/api/system/query-healthcheck',
  queryFullHealthcheck: '/api/system/query-full-healthcheck',
  doHealthcheck: '/api/system/do-healthcheck',
  termsCondition: '/api/system/get-terms/terms-condition',
  cookiePolicy: '/api/system/get-terms/cookie-policy',
  user: '/api/users/me',
  signup: '/api/auth/signup',
  signup_invite: '/api/auth/signup/invite',
  signout: '/api/auth/signout',
  confirmSignup: '/api/auth/confirm',
  resendConfirmLink: '/api/auth/resend-confirm-link',
  signin: '/api/auth/signin',
  signinExternal: '/api/auth/signin/{authType}/{service}',
  signinExternalBackendRedirect: '/api/auth/signin/{service}/callback',
  requestPasswordReset: '/api/auth/request-password-reset',
  validatePasswordResetToken: '/api/auth/validate-password-reset-token',
  resetPassword: '/api/auth/reset-password',
  signalGroups: '/api/system/signal-groups',
  translation: '/api/system/translations/{{lng}}',
  avatar: '/api/users/{account_id}/avatar',
  refresh: '/api/auth/refresh',
  settingsGroup: '/api/admin/settings/{group}',
  userGroups: '/api/admin/access-groups',
  removeUserGroup: '/api/admin/access-groups/{groupId}/remove',
  apiQuota: '/api/admin/settings/api-quota',
  apiQuotaRemove: '/api/admin/settings/api-quota/{quotaId}/remove',
  users: '/api/admin/users',
  adminEditUser: '/api/admin/users/{userId}',
  adminResetUser: '/api/admin/users/{userId}/reset-password',
  userUploads: '/api/admin/users/{userId}/uploads',
  appConfig: '/api/admin/app-config',
  userCreateApiKey: '/api/users/api-key/create',
  userRemoveApiKey: '/api/users/api-key/remove',
  reportsFeed: '/api/feed/reports',
  reportsFeedInfo: '/api/feed/reports/info',
  yaraFeed: '/api/feed/yara',
  yaraFeedInfo: '/api/feed/yara/info',
  samplesArchivesInfo: '/api/archives/samples',
  yaraArchivesInfo: '/api/archives/yara',
  apiData: '/openapi.json',
  adminRetentionCalculation: '/api/admin/retention-calculation',
  adminGetStat: '/api/admin/stat',
  getGenericStat: '/api/users/stat/generic',
  getJobs: '/api/admin/jobs',
  getJobsConfig: '/api/admin/jobs/config',
  stopAllJobs: '/api/admin/jobs/stop/all',
  enableAllJobs: '/api/admin/jobs/enable/all',
  editJob: '/api/admin/jobs/{jobName}',
  getJobsMemoryPeaks: '/api/admin/jobs/memory/peaks',
  getJobMemoryData: '/api/admin/jobs/memory',
  getJobPublicLog: '/api/admin/jobs/{jobName}/log',
  getMaliciousIpsStat: '/api/users/stat/malicious-ips',
  adminGetDailyStat: '/api/admin/stat/daily',
  getDailyStat: '/api/users/stat/daily',
  getUserStat: '/api/users/stat',
  statIoc: '/api/users/stat/iocs',
  statYara: '/api/users/stat/yara',
  infoYara: '/api/users/info/yara',
  adminReport: '/api/admin/reports/{reportId}',
  generateInviteToken: '/api/admin/invite',
  mitreData: '/api/system/mitre',
  mitreStat: '/api/users/stat/mitre',
  languages: '/api/system/languages',
  countries: '/api/system/countries',
  saveAuthService: '/api/admin/auth-services',
  removeAuthService: '/api/admin/auth-services/{service}/remove',
  generateExternalTokensSecret: '/api/admin/generate-external-tokens-secret',
  logError: '/api/system/errors/log',
  getClientErrors: '/api/admin/errors',
  removeClientErrors: '/api/admin/errors',
  removeClientError: '/api/admin/errors/{errorId}',
  editClientErrorNote: '/api/admin/errors/{errorId}/note',
  getLogs: '/api/admin/logs',
  removeLogs: '/api/admin/logs',
  getFullLogRecord: '/api/admin/logs/{recordId}',
  removeLogRecord: '/api/admin/logs/{recordId}',
  editLogRecordNote: '/api/admin/logs/{recordId}/note',
  getScanSourcesConfigs: '/api/admin/scan-sources/config',
  editScanSourceConfig: '/api/admin/scan-sources/config',
  getScanSourcesFiles: '/api/admin/scan-sources/files',
  checkScanSourceConnection: '/api/admin/scan-sources/{configId}/check-connection',
  getScanSourceSubJobs: '/api/admin/scan-sources/subjobs',
  getScanSourceFullSubJob: '/api/admin/scan-sources/subjobs/{recordName}',
  importUsers: '/api/admin/users/import',
  userNotification: '/api/users/notifications/{notification}',
  emailingHistory: '/api/admin/emails',
  saveEmailQueueItem: '/api/admin/emails',
  emailTemplates: '/api/admin/emails/templates',
  getLogoByType: '/api/system/logo?theme={theme}&type={type}',
  getLogoByFilename: '/api/system/logo?name={name}',
  listAllLogos: '/api/admin/logos',
  selectLogo: '/api/admin/logos/select',
  getCommunityVotes: '/api/community/votes?sha256={hash}',
  communityVote: '/api/community/vote',
  getSimilarity: '/api/similarity-search/similarity',
  modifyVerdict: '/api/threatintel/modify-verdict',
  getReputation: '/api/reputation/{iocType}',
  getReportChatGptSummary: '/api/reports/{reportId}/chat-gpt',
  getLicenseInfo: '/api/system/license',
  activateLicenseOnline: '/api/system/license/activate/online',
  activateLicenseOffline: '/api/system/license/activate/offline',
  deactivateLicens: '/api/system/license/deactivate',
  getOcmIntegrationState: '/api/ocm/state',
  enrollOcm: '/api/ocm/enroll',
  unenrollOcm: '/api/ocm/unenroll',
  getScanQueueHealth: '/api/admin/stat/scan/health',
  getVersionInfo: '/api/system/version',
  getScansStateStat: '/api/admin/stat/scan/states',
  exportErrors: '/api/admin/errors/export',
  getAuditLogs: '/api/admin/audit-logs',
  getYaraExtendedData: '/api/system/yara',
  getYaraAuthors: '/api/system/yara-authors',
  getMimeTypes: '/api/system/mime-types',
  reputationCheckConfig: '/api/system/reputation/check-config',
  getNews: '/api/system/news',
  saveNewsItem: '/api/system/news',
  removeNewsItem: '/api/system/news',
  getAuthServices: '/api/admin/auth-services',
  saveScanProfile: '/api/scan/profiles',
  removeScanProfile: '/api/scan/profiles/{{profileId}}',
}

export const externalUrls = {
  terms: 'https://app.termly.io/document/terms-of-use-for-website/38c0efd5-37ca-4955-8127-d116113297ec',
  privacyPolicy: 'https://app.termly.io/document/privacy-notice/22dba502-44a1-4ce2-8a09-5ea60200e8c4',
}
