import { createContext } from 'react'

import {
  HexViewerClassNames,
  HexViewerInlineStyles,
  SelectionDirectionType,
  SetSelectionBoundaryCallback,
  SetSelectionRangeCallback,
  ValueFormatter,
} from '../types'
import { SELECTION_DIRECTION_NONE } from '../constants'
import { formatHex } from '../utils'

export interface IHexViewerContext {
  asciiPlaceholder: string | JSX.Element | null
  classNames: HexViewerClassNames
  columns: number
  cursorColumn?: number
  cursorOffset: number
  cursorRow?: number
  data: Uint8Array | number[]
  formatOffset: (offset: number) => string
  formatValue: ValueFormatter
  isEditing: boolean
  nonce?: number | string
  nybbleHigh: number | null
  rows: number
  selectionAnchor: number | null
  selectionDirection: SelectionDirectionType
  selectionEnd: number
  selectionStart: number
  setSelectionEnd: SetSelectionBoundaryCallback
  setSelectionRange: SetSelectionRangeCallback
  setSelectionStart: SetSelectionBoundaryCallback
  showAscii: boolean
  showRowLabels: boolean
  styles: HexViewerInlineStyles
}

const HexViewerContext = createContext<IHexViewerContext>({
  asciiPlaceholder: null,
  classNames: {},
  columns: 1,
  cursorOffset: 0,
  data: [],
  formatOffset: formatHex,
  formatValue: formatHex,
  isEditing: false,
  nybbleHigh: null,
  rows: 1,
  selectionAnchor: null,
  selectionDirection: SELECTION_DIRECTION_NONE,
  selectionEnd: 0,
  selectionStart: 0,
  setSelectionEnd: () => {},
  setSelectionRange: () => {},
  setSelectionStart: () => {},
  showAscii: false,
  showRowLabels: false,
  styles: {},
})

export default HexViewerContext
