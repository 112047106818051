import {
  IOCDataTypes,
  IOCFileTypes,
  IOCGroupedByFileType,
  IOCGroupedByDataType,
  IOCGroup,
  IOCItem,
  IOCFinalGroup,
  IOCDataTypesName,
  StrippedIOCsType,
  mainFileKey,
} from '../../types'
import BaseFormatter from './base'
import { formatEnumItem, has, hasFields } from '../../helpers/functions'
import { hasInterestingIOCs } from '../../helpers'

/**
 * Format data needed for Indicators of Compromise report subpage.
 *
 * Performs grouping of IOCs, so that:
 * - result report has `ioc` item
 * - that item has the following items:
 *   - `mainFile` for main report file, containing all it's IOCs in the form given in report
 *   - `extractedFiles`, containing hash -> IOCs map for all extracted files for given main report file
 *   - `downloadedFiles`, containing hash -> IOCs map for all downloaded files for given main report file
 */
export default class IOCFormatter extends BaseFormatter {
  strippedIocs: StrippedIOCsType = {}

  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const result: IOCGroupedByFileType = {}
    const fileResource = this.getResource(data, 'file')
    const downloadResource = this.getResource(data, 'file-download')

    let overallUrls: IOCGroup[] = []
    if (downloadResource) {
      overallUrls = this.extractTopUrls(downloadResource)
    }

    if (fileResource) {
      const main: IOCGroupedByDataType = {}
      this.copyFields(fileResource, overallUrls, main)

      if (hasFields(main)) {
        result[mainFileKey] = main
      }

      if (has(fileResource, 'extractedFiles')) {
        this.formatFilesIOCs(fileResource.extractedFiles, overallUrls, result, 'extractedFiles')
      }
    }

    if (downloadResource) {
      this.formatFilesIOCs(downloadResource.fileDownloadResults, overallUrls, result, 'downloadedFiles')
    }

    if (Array.isArray(data.allSignalGroups)) {
      const groupVerdicts = this.formatSignalGroupVerdicts(data.allSignalGroups)
      if (hasFields(groupVerdicts)) {
        report.groupVerdicts = groupVerdicts
      }
    }

    this.refineMetas(result)
    if (hasFields(result)) {
      report.ioc = { data: result }
      if (result[mainFileKey]) {
        report.hasInterestingIOCs = hasInterestingIOCs(result[mainFileKey])
      }
    }
    if (hasFields(this.strippedIocs)) {
      report.strippedIocs = this.strippedIocs
    }
  }

  /**
   * Format IOCs for extracted and downloaded files
   *
   * @param data
   * @param result
   * @param resultField
   */
  formatFilesIOCs(
    data: { [key: string]: any },
    overallUrls: IOCGroup[],
    result: IOCGroupedByFileType,
    resultField: string
  ): void {
    const filesResult: { [hash: string]: IOCGroupedByDataType } = {}

    data.forEach((file: any) => {
      const hash = file.digests['SHA-256']
      const data: IOCGroupedByDataType = {}

      this.copyFields(
        file,
        overallUrls.filter((item) => item.origin.identifier === hash),
        data
      )

      if (hasFields(data)) {
        filesResult[hash] = data
      }
    })

    if (hasFields(filesResult)) {
      const key = resultField as IOCFileTypes
      result[key] = filesResult
    }
  }

  extractTopUrls(data: { [key: string]: any }): IOCGroup[] {
    const field = 'extractedUrls'
    let urls = []
    if (has(data, field)) {
      urls = data[field]
    }

    return urls
  }

  copyFields(source: { [key: string]: any }, overallUrls: IOCGroup[], target: IOCGroupedByDataType): void {
    const urls = overallUrls.filter((item) => item.origin.identifier === source.digests['SHA-256'])

    Object.keys(IOCDataTypes).forEach((key: string) => {
      const iocType = key as keyof typeof IOCDataTypes
      const iocTypeField = IOCDataTypes[iocType]
      const iocTypeName = IOCDataTypesName[iocType]

      if (has(source, iocTypeField) && source[iocTypeField].length) {
        const merged = iocTypeField === IOCDataTypes.Urls ? [...urls, ...source[iocTypeField]] : source[iocTypeField]
        const limited = this.limitTooManyIOCs(source, merged, iocTypeName)
        const grouped = this.groupIOCs(limited, iocTypeName)
        target[iocTypeField] = grouped
      }
    })
  }

  limitTooManyIOCs(source: { [key: string]: any }, iocs: IOCGroup[], iocTypeName: IOCDataTypesName): IOCGroup[] {
    const limit = 100
    const hash = source.digests['SHA-256']
    const result: IOCGroup[] = []
    let iocTypeCount = 0

    iocs.forEach((group) => {
      const count = iocTypeCount + group.references.length
      if (count <= limit) {
        iocTypeCount = count
        result.push(group)
        return
      }

      const diff = count - limit
      const keepCount = group.references.length - diff
      if (!keepCount) {
        return false
      }

      if (typeof this.strippedIocs[hash] == 'undefined') {
        this.strippedIocs[hash] = {}
      }
      this.strippedIocs[hash][iocTypeName] = true

      group = { ...group, references: group.references.slice(0, keepCount) }
      result.push(group)
      return false
    })

    return result
  }

  groupIOCs(originGroups: IOCGroup[], iocTypeName: IOCDataTypesName): IOCFinalGroup[] {
    const flattened = this.gatherOrigins(originGroups)
    const grouped = this.groupByOrigins(flattened, iocTypeName)

    return grouped
  }

  /**
   * Flatten IOC structure, so that each of them had all sources gathered as array
   */
  gatherOrigins(originGroups: IOCGroup[]): IOCItem[] {
    const result: { [key: string]: IOCItem } = {}

    originGroups.forEach((group: IOCGroup) => {
      group.references.forEach((resource: IOCItem) => {
        const key = resource.data
        const item = result[key] ? result[key] : { ...resource, origins: [] }
        const originType = formatEnumItem(group.origin.type)

        item.origins && !item.origins.includes(originType) && item.origins.push(originType)

        if (!result[key]) {
          result[key] = item
        }
      })
    })

    return Object.values(result)
  }

  /**
   * Again group IOCs, but now by the whole array of origins each IOC has
   */
  groupByOrigins(items: IOCItem[], iocTypeName: IOCDataTypesName): IOCFinalGroup[] {
    const result: { [key: string]: IOCFinalGroup } = {}

    items.forEach((item: IOCItem) => {
      // Shouldn't happen, just to pass TS type check
      if (!item.origins) {
        return
      }

      item.origins.sort((a, b) => a.localeCompare(b))
      const key = item.origins.join('|')

      if (!result[key]) {
        result[key] = {
          origins: item.origins,
          references: [],
        }
      }

      result[key].references.push(item)
    })

    return Object.values(result)
  }

  refineMetas(result: IOCGroupedByFileType): void {
    for (const key in result) {
      if (key === IOCFileTypes.Main) {
        this.refineFileMeta(result[IOCFileTypes.Main])
      } else {
        const fileResults: { [hash: string]: IOCGroupedByDataType } = result[key as IOCFileTypes]
        for (const hash in fileResults) {
          this.refineFileMeta(fileResults[hash])
        }
      }
    }
  }

  refineFileMeta(ioc: IOCGroupedByDataType): void {
    for (const key in ioc) {
      const groups: IOCFinalGroup[] = ioc[key as IOCDataTypes] ?? []
      groups.forEach((group) => {
        group.references &&
          group.references.forEach((ref: IOCItem) => {
            if (!ref.metaData) return

            ref.metaData = this.cleanMeta(ref.metaData)
          })
      })
    }
  }

  cleanMeta(metaData: { [key: string]: any }): { [key: string]: any } | undefined {
    const result = metaData
    if (result.reason && Array.isArray(result.reason)) {
      result.reason = result.reason.filter((item) => {
        return item !== 'INTERNAL'
      })

      if (result.reason.length === 0) delete result.reason
    }

    if (result.referenceIDs) {
      delete result.referenceIDs
    }

    if (Object.keys(result).length > 0) {
      return result
    }
  }

  formatSignalGroupVerdicts(data: any[]): { [key: string]: string } {
    const result: { [key: string]: string } = {}
    for (const group of data) {
      if (!group.identifier || !group.verdict?.verdict) {
        continue
      }

      result[group.identifier] = group.verdict.verdict.toLowerCase()
    }

    return result
  }
}
