import React, { ReactElement } from 'react'
import BaseFormatter from './base'
import { castStrengthToVerdict, has } from '../../helpers'
import {
  Signal,
  SignalGroup,
  SignalGroups,
  SignalGroupMitreData,
  SignalGroupMitreTechnique,
  TechniquesGroupsMap,
  TagType,
  MitreTactic,
  RawMITRETechnique,
  SignalVerdict,
} from '../../types'
import mitre from '../../lib/mitre'
import { MitreTechnique } from 'app/modules/shared/types'
import { inc } from 'app/modules/shared/helpers'
import { ReportOverviewFilterStat } from '../../features/overview-report/types'
import { ReportFilterSection, ReportFilterSectionType } from '../../components/filter/types'

/**
 * Format report response data for overview page
 */
export default class OverviewFormatter extends BaseFormatter {
  underscoreRegexp = /_/g
  sectionsCache: { [key: string]: boolean } = {}

  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    this.sectionsCache = {}
    const result: { [key: string]: any } = {}

    if (typeof data.allSignalGroups !== 'undefined') {
      this.formatAllSignalGroups(data.allSignalGroups, result)
    }

    const resource = this.getResource(data, 'file')
    result.private = resource?.private ?? false

    if (resource && has(resource, 'mediaType') && has(resource.mediaType, 'string')) {
      result.mediaType = resource.mediaType.string
    }

    if (data.allTags) {
      result.tags = data.allTags
    }
    if (data.created_date) {
      result.date = data.created_date
    }
    if (data.estimated_progress) {
      result.estimatedProgress = data.estimated_progress
    }
    if (resource?.metaData?.isUrlToFileAnalysis) {
      const meta = resource.metaData
      const urlScanInfo: { [key: string]: any } = { isUrlToFileAnalysis: true }

      if (resource.digests && resource.digests['SHA-256']) {
        urlScanInfo.fileIdentifier = resource.digests['SHA-256']
      }

      for (const name in ['originalUrl', 'originalUrlIdentifier']) {
        if (meta[name]) {
          urlScanInfo[name] = meta[name]
        }
      }

      result.urlScanInfo = urlScanInfo
    }

    report.overview = result
  }

  /**
   * Apply format to a signal groups field.
   *
   * Performs the following:
   * - splits signal groups into columns by signal strength
   * - maps MITRE techniques to signal groups
   * - formats and simplifies data
   *
   * @param {object} input     Data obtained from given field in report response
   * @param {object} output    Container to put formatted result in
   */
  formatAllSignalGroups(input: { [key: string]: any }, output: { [key: string]: any }): void {
    const groups: SignalGroups = {}
    const techniquesGroupsMap: TechniquesGroupsMap = {}
    const stat: ReportOverviewFilterStat = {
      types: {
        verdict: {},
        origin: {},
        mitre: {},
        tags: {},
      },
    }
    const filterSections: { [key: string]: ReportFilterSection } = {
      verdict: { type: ReportFilterSectionType.Verdict, name: 'verdict', items: [] },
      tags: { type: ReportFilterSectionType.List, name: 'tags', items: [] },
      mitre: { type: ReportFilterSectionType.Flags, name: 'mitre', title: 'mitre-techniques', items: [] },
      origin: { type: ReportFilterSectionType.Flags, name: 'origin', items: [] },
    }

    input.sort((a: any, b: any) => b.verdict.threatLevel - a.verdict.threatLevel)

    input.forEach((groupData: any) => {
      const group = this.buildGroup(groupData, stat, filterSections)
      const verdict: SignalVerdict = castStrengthToVerdict(group.strength.average)

      const typedGroups = groups[verdict] || []
      const existing = typedGroups.find((grp) => grp.id === group.id)
      if (existing) {
        this.mergeGroup(existing, group)
      } else {
        typedGroups.push(group)
        this.updateTechniquesGroupsMap(techniquesGroupsMap, group, verdict)
      }

      groups[verdict] = typedGroups
    })

    output.groups = groups
    output.techniques_groups_map = techniquesGroupsMap
    output.filter = {
      stat,
      sections: Object.values(filterSections),
    }
  }

  /**
   * Build data for signal group
   *
   * @param {object} data
   */
  buildGroup(
    data: { [key: string]: any },
    stat: ReportOverviewFilterStat,
    filterSections: { [key: string]: ReportFilterSection }
  ): SignalGroup {
    const signals = this.buildSignals(data.signals, stat, filterSections, data.allMitreTechniques)
    const group: SignalGroup = {
      id: data.identifier,
      description: data.description,
      signals: signals,
      strength: {
        average: data.verdict.threatLevel,
        peak: data.peakSignalStrength,
      },
    }

    if (typeof data.allMitreTechniques !== 'undefined') {
      group.mitre = this.buildMitreTechniques(data.allMitreTechniques)
    }

    if (Array.isArray(data.allTags) && data.allTags.length) {
      group.tags = data.allTags
    }

    return group
  }

  mergeGroup(group: SignalGroup, other: SignalGroup): void {
    // merge signals
    const existingSignals = group.signals
    const otherSignals = other.signals
    for (const origin in otherSignals) {
      const signals = otherSignals[origin]
      const existings = existingSignals[origin] ?? []
      const existingObj: { [key: string]: Signal } = {}
      for (const sig of existings) {
        existingObj[sig.originId] = sig
      }

      for (const signal of signals) {
        if (!(existingObj[signal.originId]?.description === signal.description)) {
          existings.push(signal)
          existingObj[signal.originId] = signal
        }
      }

      if (existings.length) {
        existingSignals[origin] = existings
      }
    }

    // merge tags
    const existingTags = group.tags ?? []
    const existingTagObj: { [key: string]: TagType } = {}
    for (const tag of existingTags) {
      if (tag.tag?.name) {
        existingTagObj[tag.tag?.name] = tag
      }
    }
    const otherTags = other.tags ?? []
    for (const tag of otherTags) {
      if (!tag.tag?.name || !(existingTagObj[tag.tag.name]?.tag?.name === tag.tag.name)) {
        existingTags.push(tag)
      }
    }

    if (existingTags.length) {
      group.tags = existingTags
    }

    // merge mitre techs
    const existingMitres = group.mitre ?? {}
    const otherMitres = other.mitre ?? {}
    for (const id in otherMitres) {
      if (id in existingMitres) continue
      existingMitres[id] = otherMitres[id]
    }

    if (Object.keys(existingMitres).length) {
      group.mitre = existingMitres
    }
  }

  /**
   * Add signal group to map of MITRE techniques IDs -> signal groups
   *
   * @param {object} mapping Map of MITRE techniques IDs to signal groups
   * @param {object} group Signal group
   * @param {string} type Type of signal group by strength
   */
  updateTechniquesGroupsMap(mapping: TechniquesGroupsMap, group: SignalGroup, verdict: SignalVerdict): void {
    if (typeof group.mitre === 'undefined') {
      return
    }

    const techniquesIds = Object.keys(group.mitre)
    techniquesIds.forEach((id: string) => {
      if (typeof mapping[id] === 'undefined') {
        mapping[id] = { count: {}, groups: [] }
      }

      let count = mapping[id]?.count[verdict] || 0
      count++

      mapping[id].count[verdict] = count
      mapping[id].groups.push(group.id)
    })
  }

  /**
   * Format and simplify MITRE techniques
   *
   * @param {array} data  MITRE techniques for single signal group
   */
  buildMitreTechniques(data: { [key: string]: any }): { [key: string]: any } {
    const result: SignalGroupMitreData = {}
    const techniquesDesc = this.getAllTechniques()

    data.forEach((item: any) => {
      const technique: SignalGroupMitreTechnique = { name: item.name }

      if (typeof item.relatedTactic !== 'undefined') {
        technique.tactic = item.relatedTactic.name
      }
      if (typeof techniquesDesc[item.ID] !== 'undefined') {
        technique.description = techniquesDesc[item.ID].description
      }

      result[item.ID] = technique
    })

    return result
  }

  /**
   * Return MITRE techniques as plain object {techniquesId data}
   */
  getAllTechniques() {
    const techniques: { [techniqueId: string]: MitreTechnique } = Object.values(mitre).reduce(
      (result: { [key: string]: any }, item: MitreTactic) => {
        return { ...result, ...item.techniques }
      },
      {}
    )

    return techniques
  }

  /**
   * Simplify and format signals
   *
   * @param {array} data  Signals
   */
  buildSignals(
    data: { [key: string]: any },
    stat: ReportOverviewFilterStat,
    filterSections: { [key: string]: ReportFilterSection },
    groupMitreTechniques: RawMITRETechnique[] | undefined
  ): { [key: string]: any } {
    const signals: { [key: string]: any } = {}

    data.forEach((item: any) => {
      const origin = has(item, 'originType')
        ? item.originType.replace(this.underscoreRegexp, ' ').toLowerCase()
        : 'other'

      const signal: Signal = {
        strength: item.strength,
        description: this.formatSignalDesc(item.signalReadable),
        origin,
        originId: item.originIdentifier,
      }

      if (Array.isArray(item.tags) && item.tags.length) {
        signal.tags = item.tags
      }

      this.gatherFilterStat(signal, stat, groupMitreTechniques)
      this.gatherFilterSections(signal, filterSections, groupMitreTechniques)

      let subgroup: Signal[] = []
      if (!has(signals, origin)) {
        signals[origin] = subgroup
      } else {
        subgroup = signals[origin]
      }

      subgroup.push(signal)
    })

    return signals
  }

  gatherFilterStat(
    signal: Signal,
    stat: { [key: string]: any },
    groupMitreTechniques: RawMITRETechnique[] | undefined
  ) {
    const verdict = castStrengthToVerdict(signal.strength)
    const origin = signal.origin
    const tags = signal.tags || []
    const statTypes = stat.types

    statTypes.verdict[verdict] = inc(statTypes.verdict[verdict])
    statTypes.origin[origin] = inc(statTypes.origin[origin])
    for (const technique of groupMitreTechniques || []) {
      statTypes.mitre[technique.ID] = inc(statTypes.mitre[technique.ID])
    }
    for (const tag of tags) {
      const tagName = tag.tag.name
      statTypes.tags[tagName] = inc(statTypes.tags[tagName])
    }

    stat.total = inc(stat.total)
  }

  gatherFilterSections(
    signal: Signal,
    sections: { [key: string]: ReportFilterSection },
    groupMitreTechniques: RawMITRETechnique[] | undefined
  ) {
    const origin = signal.origin
    const tags = signal.tags || []

    for (const technique of groupMitreTechniques || []) {
      if (typeof this.sectionsCache[technique.ID] === 'undefined') {
        sections.mitre.items.push({ value: technique.ID, name: technique.name })
        this.sectionsCache[technique.ID] = true
      }
    }
    if (origin && typeof this.sectionsCache[origin] === 'undefined') {
      sections.origin.items.push({ value: origin, name: origin })
      this.sectionsCache[origin] = true
    }
    for (const tag of tags) {
      const tagName = tag.tag.name
      if (typeof this.sectionsCache[tagName] === 'undefined') {
        sections.tags.items.push({ value: tagName })
        this.sectionsCache[tagName] = true
      }
    }
  }

  /**
   * Highlight some signal description parts
   */
  formatSignalDesc(description: string): (ReactElement | string)[] {
    const parts = split_string(description)
    const result: (ReactElement | string)[] = []

    for (let i = 0; i < parts.length; i++) {
      const part = i % 2 ? <span className="extracted-inline-token">{parts[i]}</span> : parts[i]

      if (part) {
        result.push(part)
      }
    }

    return result
  }
}

function split_string(text: string): Array<string> {
  if (!text) return []
  const len = text.length
  const arr = []
  let level = 0,
    start = 0
  for (let i = 0; i < len; i++) {
    if (text[i] === '"') {
      if (level === 0) {
        arr.push(text.substring(start, i))
        start = i + 1
        level++
      } else if (level > 0) {
        if (i > 0 && text[i - 1] === '\\') continue
        else {
          level = 0
          arr.push(text.substring(start, i))
          start = i + 1
        }
      }
    }
  }

  if (len > start) {
    arr.push(text.substring(start, len))
  }
  return arr
}
