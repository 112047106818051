import React, { useMemo } from 'react'
import { HelpIcon } from './helpers'
import { ExtendedOption } from './extended-options'
import { TFunction } from 'i18next'
import { FormControl } from 'app/modules/shared/components/form/form-control'
import Checkbox from 'app/components/checkbox'
import { ScanProfileOptions } from 'app/modules/user/features/profile/types'

const ExtendedOptionsBody = ({
  disableFields,
  _t,
  fields,
  data,
  onOptionChange,
  availableOptions,
}: {
  disableFields: boolean
  _t: TFunction
  fields: ExtendedOption[]
  data?: ScanProfileOptions
  onOptionChange?: CallableFunction
  availableOptions: ScanProfileOptions | undefined
}) => {
  // Options availability config might not be loaded due to various issues.
  // In this case, we just show all options.
  const configExists = !!Object.keys(availableOptions || {}).length

  // Split options into two columns
  const groupedFields: ExtendedOption[][] = useMemo(() => {
    const result: ExtendedOption[][] = [[], []]
    const filtered = []

    // Filter by options availability
    for (const field of fields) {
      const name = field.name as keyof ScanProfileOptions
      if (!configExists || availableOptions?.[name]) {
        filtered.push(field)
      }
    }

    // Split into columns
    const half = Math.floor(filtered.length / 2)
    const add = filtered.length % 2
    for (let i = 0; i < filtered.length; i++) {
      const target = i < half + add ? 0 : 1
      result[target].push(filtered[i])
    }

    return result
  }, [fields, availableOptions])

  return (
    <div className="custom-popover-body row">
      {groupedFields.map((group, idx) => (
        <div className="col" key={idx}>
          {group.map((field) => {
            const value = data?.[field.name as keyof ScanProfileOptions]

            return (
              <FormControl
                key={field.name}
                parentClass="d-flex mb-4 align-items-start"
                control={
                  <>
                    <Checkbox
                      id={field.name}
                      label={field.label}
                      className="d-inline-flex align-items-center"
                      checked={(value as boolean) || false}
                      onChange={onOptionChange?.(field.name)}
                      disabled={disableFields}
                    />
                    <HelpIcon tooltipId={field.name + 'Tooltip'} note={field.note} />
                  </>
                }
              />
            )
          })}
        </div>
      ))}

      {!groupedFields[0].length ? <i className="text-muted">{_t('no-options')}</i> : null}
    </div>
  )
}

export default ExtendedOptionsBody
