import { WhoisData, WhoisDataField } from '../../types'
import BaseFormatter from './base'

/**
 * Format data from WHOIS lookup resource
 */
export default class WhoisFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const resource = this.getResource(data, 'whois-lookup')
    if (!resource) {
      return
    }

    const result: { [key: string]: any } = {}
    resource.whoisLookupResults.forEach((whoisItem: { [key: string]: any }) => {
      const whoisData = whoisItem.whoisData?.ServiceResult?.QueryResult
      const valid = whoisData?.ErrorCode?.toLowerCase() === 'success' && whoisData?.FoundMatch.toLowerCase() === 'yes'

      if (!valid) {
        return
      }

      const domain = whoisItem.domain
      const provider = whoisItem.whoisDataType.toLowerCase()
      const data = whoisItem.whoisData.ServiceResult.QueryResult.WhoisRecord
      const grouped: WhoisData = { domain, provider }

      this.groupAndSimplify(
        data,
        grouped,
        ['CreatedDate', 'UpdatedDate', 'ExpiresDate', 'DatabaseUpdatedDate'],
        WhoisDataField.WhoisRecord,
        false
      )
      this.groupAndSimplify(data, grouped, ['Domain'], WhoisDataField.DomainInfo)
      this.groupAndSimplify(data, grouped, ['Registrar', 'Registrant'], WhoisDataField.DomainRegistration)
      this.groupAndSimplifyArray(data, grouped, 'AdminContact', WhoisDataField.Admins)
      this.groupAndSimplifyArray(data, grouped, 'TechContact', WhoisDataField.Techs)

      if (typeof result[domain] === 'undefined') {
        result[domain] = []
      }

      // Currently each domain has only 1 whois provider, but in future there can be more.
      // So they will be gathered together here
      result[domain].push(grouped)
    })

    if (Object.keys(result).length) {
      report.whois = result
    }
  }

  groupAndSimplify(
    data: { [key: string]: any },
    grouped: WhoisData,
    fields: string[],
    targetField: WhoisDataField,
    simplify = true
  ): void {
    const result: { [key: string]: string } = {}
    this.copyFields(fields, data, result, simplify ? simplifyFields : undefined)

    if (Object.keys(result).length) {
      grouped[targetField] = result as any
    }
  }

  groupAndSimplifyArray(
    data: { [key: string]: any },
    grouped: WhoisData,
    sourceField: string,
    targetField: WhoisDataField
  ): void {
    const field = data[sourceField]
    if (!field) {
      return
    }

    for (let i = 0; i < field.length; i++) {
      field[i] = simplifyFields(field[i])
    }

    grouped[targetField] = field
  }
}

function simplifyFields(obj: { [key: string]: any }) {
  const newObj: { [key: string]: any } = {}

  for (const name in obj) {
    newObj[name] = castArrayToString(obj[name])
  }

  return newObj
}

function castArrayToString(data: any) {
  const hasSingleItem = Array.isArray(data) && data.length === 1
  return hasSingleItem ? data[0] : data
}
