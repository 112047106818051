import { getUpdateModalCallback } from 'app/components'
import { AuthButton } from 'app/modules/auth/components'
import { applicationConfiguration, modalContext } from 'app/modules/shared'
import { isAsciiData } from 'app/modules/shared/helpers/functions'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import React, { MouseEvent, RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { reportContext, useExport } from '../../contexts'
import { humanFileSize } from '../../helpers'
import { useReport } from '../../hooks'
import { Copy2Clipboard } from '../copy-to-clipboard'
import { HexViewerContainer } from '../hex-viewer'
import { useContentFetcher } from '../../hooks/useContentFetcher'

export enum ViewerButtonType {
  Lock = 'lock-icon',
  Link = 'link',
}

type ViewerButtonProps = {
  text?: string
  type?: ViewerButtonType
  size?: string
}

type ViewerProps = {
  content?: string
  contentHex?: Uint8Array
  sha256?: string
  modalTitle?: string
  allowPlainTextHex?: boolean
  button?: ViewerButtonProps
  isPrivate?: boolean
  language?: string
  fileSize?: number
}

// View file content either in plain or hex viewer, using modal
export function FileContentModalViewer({
  content,
  contentHex,
  sha256,
  button,
  modalTitle,
  allowPlainTextHex,
  isPrivate,
  language,
  fileSize,
}: ViewerProps): JSX.Element | null {
  const { _t } = useTypedTranslation()
  const { exporting } = useExport()
  const config = useContext(applicationConfiguration)
  const reportsWrapper = useContext(reportContext)
  const modalWrapper = useContext(modalContext)
  const stringContentRef = useRef(null)
  const [contentState, setContentState] = useState<string | undefined>(content)
  const [contentHexState, setContentHexState] = useState<Uint8Array | undefined>(contentHex)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const { flowId, reportId } = useReport()
  const reportHelpers = reportsWrapper.getHelpers(reportId)

  if (typeof button === 'undefined') {
    button = {} as ViewerButtonProps
  }
  if (typeof button.text === 'undefined') {
    button.text = _t('view-content')
  }
  if (typeof modalTitle === 'undefined') {
    modalTitle = _t('file-content')
  }
  if (typeof isPrivate === 'undefined') {
    isPrivate = false
  }

  const hideModal = getUpdateModalCallback(modalWrapper, { show: false })
  const showModal = getUpdateModalCallback(modalWrapper, {
    show: true,
    title: (
      <ContentModalTitle
        content={contentState}
        sha256={sha256}
        stringContentRef={stringContentRef}
        title={modalTitle}
      />
    ),
    body: (
      <ContentModalBody
        content={contentState}
        contentHex={contentHexState}
        sha256={sha256}
        stringContentRef={stringContentRef}
        allowPlainTextHex={allowPlainTextHex}
        language={language}
      />
    ),
    titleClassName: 'w-100',
    props: {
      onHide: hideModal,
      dialogClassName: 'modal-size-auto modal-top',
    },
  })

  const size = fileSize ?? reportHelpers?.plainFileSize
  const isTooBig = isFileSizeTooBig(size, config.reports.viewFileContentSizeLimit)
  const fetchContent = useContentFetcher(
    contentState,
    contentHexState,
    sha256,
    showModal,
    setLoading,
    setContentState,
    setContentHexState,
    setError
  )
  const handleClick = (e: any) => {
    e.stopPropagation()
    !isTooBig && fetchContent()
  }

  // Disable the view content button and set the popup message if the file is set to private or the automatic file download is disabled
  const isDemoReport = flowId === config.demoReport.flowId && reportId === config.demoReport.id
  const demoReportMessage = _t('demo-report-file-not-available-note')
  const disableButton = isPrivate || !config.automaticReportFilesDownload || isDemoReport
  const privateMessage = isDemoReport
    ? demoReportMessage
    : isPrivate
      ? _t('protected-file-content')
      : !config.automaticReportFilesDownload
        ? _t('automatic-report-files-download-disabled-popup-note')
        : ''

  const buttonElement =
    button.type === ViewerButtonType.Lock ? (
      <i
        className={'ds3-icon ds3-unlock icon-button'}
        title={button.text}
        onClick={handleClick}
        aria-label="Show modal"
      ></i>
    ) : (
      <AuthButton
        className="me-2"
        variant="primary"
        onClick={handleClick}
        isPrivate={disableButton}
        privateMsg={privateMessage}
        fileSize={isTooBig ? 0 : 1} // stubbed values to trigger needed behaviour on exceeding size limit
        sizeMsg={_t('view-file-content-limit', { limit: humanFileSize(config.reports.viewFileContentSizeLimit, 0) })}
        disabled={loading}
      >
        <>
          {button.text}
          {loading && <Spinner animation="border" variant="warning" size="sm" className="ms-2" />}
        </>
      </AuthButton>
    )

  if (exporting) {
    return null
  }

  return (
    <>
      <Form.Control type="hidden" value={contentState} ref={stringContentRef} />
      <div className="d-flex align-items-center">
        {buttonElement}
        {error && <span className="text-danger me-2">{error}</span>}
      </div>
    </>
  )
}

function ContentModalTitle({
  content,
  sha256,
  stringContentRef,
  title,
}: {
  content?: string
  sha256?: string
  stringContentRef: RefObject<HTMLInputElement>
  title: string
}) {
  const { _t } = useTypedTranslation()
  const [data, setData] = useState<string>(content || _t('no-data'))

  waitContentByRef(content, sha256, stringContentRef, setData)

  return (
    <div className="d-flex justify-content-between">
      <div>{title}</div>
      <div className="me-2">
        <Copy2Clipboard copyText={data} />
      </div>
    </div>
  )
}

function ContentModalBody({
  content,
  contentHex,
  sha256,
  allowPlainTextHex,
  stringContentRef,
  language,
}: {
  content?: string
  contentHex?: Uint8Array
  sha256?: string
  allowPlainTextHex?: boolean
  language?: string
  stringContentRef: RefObject<HTMLInputElement>
}) {
  const { _t } = useTypedTranslation()
  const [contentState, setContentState] = useState<string | undefined>(content)
  const [contentHexState, setContentHexState] = useState<Uint8Array | undefined>(contentHex)
  // eslint-disable-next-line no-control-regex
  const isAscii = useMemo(() => isAsciiData(contentState), [contentState])
  const [hexView, setHexView] = useState(!isAscii)
  const [txtView, setTxtView] = useState(true)
  const [contentComponent, setContentComponent] = useState<JSX.Element | null>(null)
  const [loading, setLoading] = useState(true)

  if (typeof allowPlainTextHex === 'undefined') {
    allowPlainTextHex = true
  }
  const showHexSwitch = allowPlainTextHex || !isAscii

  waitContentByRef(contentState, sha256, stringContentRef, setContentState, setHexView)

  // Switch between views
  useEffect(() => {
    setLoading(true)

    setTimeout(() => {
      contentState && setLoading(false)

      if (hexView) {
        setContentComponent(<HexViewerContainer data={contentHexState as Uint8Array} />)
      } else {
        if (language && !txtView) {
          setContentComponent(
            <SyntaxHighlighter style={docco} language={language} wrapLongLines>
              {contentState as string}
            </SyntaxHighlighter>
          )
        } else {
          setContentComponent(<p className="extracted-code">{contentState}</p>)
        }
      }
    }, 100)
  }, [contentState, contentHexState, hexView, isAscii, language, txtView])

  const toggleSyntaxHighlight = (event: MouseEvent<HTMLInputElement>) => {
    setTxtView((flag) => !flag)
  }

  return (
    <div className="decode-view-container">
      <div className="decoded relative">
        <div className="d-flex align-items-center mb-1">
          {showHexSwitch && (
            <Form.Check
              className="ms-0 mb-2 me-8"
              type="switch"
              id="custom-switch"
              label={_t('hex-view')}
              onChange={(e) => setHexView(e.target.checked)}
              checked={hexView}
            />
          )}
          {!!language && !hexView && (
            <Form.Check
              className="d-flex align-items-center ms-0 mb-2 me-2"
              type="switch"
              label="Syntax Highlight"
              checked={!txtView}
              onClick={toggleSyntaxHighlight}
            />
          )}
        </div>
        {loading && (
          <Spinner animation="border" variant="warning" className="absolute-center" style={{ zIndex: 100 }} />
        )}
        <div className={'decoded-content-wrapper ' + (loading ? 'invisible' : '')}>{contentComponent}</div>
      </div>
    </div>
  )
}

// Wait untill content is loaded and becomes available by ref
function waitContentByRef(
  content: string | undefined,
  sha256: string | undefined,
  stringContentRef: RefObject<HTMLInputElement>,
  setContent: CallableFunction,
  setHexView?: CallableFunction
) {
  if (typeof content === 'undefined' && sha256) {
    const interval = setInterval(() => {
      const fetchedValue = stringContentRef.current?.value
      if (fetchedValue && fetchedValue !== content) {
        // Setting hex here because it's not auto updated in content component when content changes.
        // TODO: Not really good, need to fix it
        setHexView && setHexView(!isAsciiData(fetchedValue as string))

        setContent(fetchedValue as string)
        clearInterval(interval)
      }
    }, 100)
  }
}

// Determine if it is useless to show file content because of file size
function isFileSizeTooBig(size: number | undefined, sizeLimit: number) {
  if (typeof size === 'undefined') {
    return false
  }

  return size > sizeLimit
}
