import React, { useContext, useState } from 'react'
import Image from 'react-bootstrap/Image'
import { modalContext } from '../../modules/shared'
import { getUpdateModalCallback } from '../modal'
import { useExport } from '../../modules/reports/contexts/export-context'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { reportContext } from 'app/modules/reports/contexts'
import { useReport } from 'app/modules/reports/hooks'
import { Trans } from 'react-i18next'

type ZoomableThumbnailItem = {
  mediaType: string
  base64: string
}

type ZoomableThumbnailParams = {
  items: ZoomableThumbnailItem[]
  activeItemIdx?: number
  title?: string
  modalClass?: string
  otherContent?: { [key: string]: any }
  className?: string
  textLink?: string
}

export function ZoomableThumbnail({
  items,
  activeItemIdx,
  title,
  modalClass,
  otherContent,
  className,
  textLink,
}: ZoomableThumbnailParams): JSX.Element {
  const { _t } = useTypedTranslation()
  const { exporting } = useExport()
  const modalWrapper = useContext(modalContext)
  const hideModal = getUpdateModalCallback(modalWrapper, { show: false })
  const showModal = getUpdateModalCallback(modalWrapper, {
    show: true,
    title: title || _t('image-preview'),
    body: <ModalBody items={items} activeItemIdx={activeItemIdx} otherContent={otherContent} />,
    props: {
      dialogClassName: 'modal-size-auto ' + (modalClass ?? ''),
      onHide: hideModal,
    },
  })

  const currentItem = typeof activeItemIdx !== 'undefined' ? items[activeItemIdx] : items[0]
  const allowModal = !exporting && !!currentItem.base64
  if (textLink && allowModal) {
    return (
      <a
        href="#"
        className={'lonely-link ' + (className || '')}
        onClick={(e) => {
          e.preventDefault()
          showModal()
        }}
      >
        {textLink}
      </a>
    )
  }

  const fullClassName = 'thumbnail-container ' + (!allowModal ? 'disabled ' : '') + (className || '')
  return (
    <div className={fullClassName} onClick={allowModal ? showModal : undefined}>
      <ThumbItem item={currentItem} />
    </div>
  )
}

function ThumbItem({ item }: { item: ZoomableThumbnailItem }) {
  const reportsWrapper = useContext(reportContext)
  const { reportId } = useReport()
  const filesDownloadFinished = reportsWrapper.getReportFilesLoadingState(reportId)

  const imageExists = !!item.base64
  const imageInProgress = !imageExists && !filesDownloadFinished

  if (imageInProgress) {
    return (
      <div className="image-not-ready alert alert-warning p-2">
        <Trans i18nKey="image-not-ready">
          Image
          <br /> not ready yet
        </Trans>
      </div>
    )
  }

  // Temp disable this, untill tracking of file upload state is ready
  if (!imageExists) {
    return (
      <div className="image-not-found alert alert-danger p-2">
        <Trans i18nKey="image-not-found">
          Image
          <br /> not found
        </Trans>
      </div>
    )
  }

  return <Image src={`data:${item.mediaType};base64,${item.base64}`} className="report-thumbnail" rounded />
}

function ModalBody({
  items,
  activeItemIdx,
  otherContent,
}: {
  items: ZoomableThumbnailItem[]
  activeItemIdx: number | undefined
  otherContent?: { [key: string]: any }
}) {
  return (
    <>
      <ModalImage items={items} activeItemIdx={activeItemIdx} />
      {otherContent?.afterImage ? otherContent.afterImage : null}
    </>
  )
}

/**
 * Build image modal content.
 * It is either a single image or a slideshow component.
 */
function ModalImage({ items, activeItemIdx }: { items: ZoomableThumbnailItem[]; activeItemIdx: number | undefined }) {
  const [sliderImageIdx, setSliderImageIdx] = useState(activeItemIdx)

  if (items.length === 1) {
    return <Image src={`data:${items[0].mediaType};base64,${items[0].base64}`} rounded />
  }

  if (typeof sliderImageIdx === 'undefined' || !items[sliderImageIdx]) {
    return null
  }

  const item = items[sliderImageIdx]
  const moveIdx = (move: number) => {
    setSliderImageIdx(sliderImageIdx + move)
  }

  const leftArrowClass = sliderImageIdx > 0 ? '' : 'invisible'
  const rightArrowClass = sliderImageIdx < items.length - 1 ? '' : 'invisible'

  return (
    <div className="slider-container">
      <i className={`fas fa-chevron-left slider-button ${leftArrowClass}`} onClick={() => moveIdx(-1)}></i>
      <Image src={`data:${item.mediaType};base64,${item.base64}`} rounded />
      <i className={`fas fa-chevron-right slider-button ${rightArrowClass}`} onClick={() => moveIdx(1)}></i>
    </div>
  )
}
