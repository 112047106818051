import { has } from '../../helpers'
import { TagType } from '../../types'

const supportedLanguages: string[] = ['javascript', 'jscript', 'powershell', 'html', 'htm', 'vbs', 'mhtml', 'txt']
/**
 * Base class for formatters
 */
class BaseFormatter {
  static supportedTypes: string[] = []
  static fileCategories: { [key: string]: string[] } = {}

  /**
   * Find given resource in report data
   *
   * @param data
   * @param name
   */
  getResource(data: { [key: string]: any }, name: string): { [key: string]: any } | undefined {
    const resources = data.resources || {}

    for (const id in resources) {
      const resource = resources[id]

      if (resource.resourceReference.name === name) {
        return resource
      }
    }

    return undefined
  }

  /**
   * Find given resource in report data, in case when
   *  there're multiple resources with this name.
   *
   * @param data
   * @param name
   */
  getResources(data: { [key: string]: any }, name: string): any[] {
    const resources = data.resources || {}
    const result: any[] = []

    for (const id in resources) {
      const resource = resources[id]

      if (resource.resourceReference.name === name) {
        result.push(resource)
      }
    }

    return result
  }

  /**
   * Get tag from `allTags` field
   *
   * @param data
   * @param name
   */
  getTag(data: { [key: string]: any }, name: string): string | undefined {
    if (!has(data, 'allTags')) {
      return undefined
    }

    for (const tag of data['allTags']) {
      if (tag.source === name) {
        return tag.tag.name
      }
    }

    return undefined
  }

  getRootTags(data: { [key: string]: any }, source: string): string[] {
    if (!has(data, 'allTags')) {
      return []
    }

    return data['allTags']
      .filter((tag: TagType) => tag.source === source && tag.isRootTag)
      .map((tag: TagType) => tag.tag.name)
  }

  /**
   * Determine scanned file media type (return shorthand name, like `pe`)
   * @param data  All report data
   */
  getFileType(data: { [key: string]: any }): string | undefined {
    const tags = this.getRootTags(data, 'MEDIA_TYPE')
    if (!tags.length) {
      return data?.oleOrOffice ? 'ms-office' : 'other'
    }

    for (const category in BaseFormatter.fileCategories) {
      const exts = BaseFormatter.fileCategories[category]
      if (exts.some((ext: string) => tags.includes(ext))) {
        return category
      }
    }

    return 'other'
  }

  getFileLanguage(data: { [key: string]: any }): string | undefined {
    const tags = this.getRootTags(data, 'MEDIA_TYPE')
    for (const tag of tags) {
      if (supportedLanguages.includes(tag)) return tag
    }
  }

  getDetailField(type: string): string {
    if (type === 'ms-office') {
      return 'ms_office_details'
    } else if (type === 'other') {
      return 'details'
    } else {
      return `${type}_details`
    }
  }

  /**
   * Copy fields from report data to prepared report
   *
   * @param fields
   * @param data
   * @param target
   */
  copyFields(
    fields: string[],
    data: { [key: string]: any },
    target: { [key: string]: any },
    modifier?: CallableFunction
  ): void {
    fields.forEach((field) => {
      if (has(data, field)) {
        target[field] = modifier ? modifier(data[field]) : data[field]
      }
    })
  }

  deleteFields(fields: string[], data: { [key: string]: any }): void {
    for (const field of fields) {
      if (has(data, field)) {
        delete data[field]
      }
    }
  }

  getDigests(data: { [key: string]: any }, target: { [key: string]: any }): void {
    const digests = data.digests
    const hashes = ['MD5', 'SHA-1', 'SHA-256', 'SHA-512']
    if (digests) {
      for (const hash of hashes) {
        if (has(digests, hash)) {
          target[hash] = digests[hash]
        }
      }
    }
  }

  getExtractedFiles(data: { [key: string]: any }): any {
    const resource: any = this.getResource(data, 'file')
    return resource?.extractedFiles
  }

  getDownloadedFiles(data: { [key: string]: any }): any {
    const resource: any = this.getResource(data, 'file-download')
    return resource?.fileDownloadResults ?? []
  }

  /**
   * Find geolocation (domain resolve) data in report
   *
   * @param data
   */
  getGeolocation(data: { [key: string]: any }): { [key: string]: any } | undefined {
    // Try to get data from old report structure
    const domainResolveResource = this.getResource(data, 'domain-resolve')
    const geoData = domainResolveResource?.domainResolveResults?.[0]?.geoData

    if (!!geoData && Object.keys(geoData).length) {
      return domainResolveResource
    }

    // Try to get data from new report structure
    const resources = data.resources || {}

    for (const id in resources) {
      const resource = resources[id]

      if (resource.domainResolveData && Object.keys(resource.domainResolveData).length) {
        return resource.domainResolveData
      }
    }

    return undefined
  }
}

export default BaseFormatter
