import { advertisementInfoStorage } from 'app/modules/auth/helper/storage'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// Fix going to page element with id={location.hash}, broken by React
export function GoToLocaionHash() {
  const { pathname, hash, key, search } = useLocation()

  useEffect(() => {
    if (!window.location.pathname.startsWith('/uploads')) {
      advertisementInfoStorage.clearShowAdvertisement()
    }

    handleCurrentLocationHash(hash)
    setTimeout(() => handleLinks(pathname, search, window.location.host), 2000)
  }, [pathname, hash, key, search])

  return null
}

// Go to element with id of current location's hash
function handleCurrentLocationHash(hash: string) {
  let countTry = 0
  const id = hash.replace('#', '')
  if (id === '') {
    return
  }

  const interval = setInterval(() => {
    countTry++

    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView()
    }

    if (element || countTry > 50) {
      clearInterval(interval)
    }
  }, 100)
}

// When clicking on page local links with hash, avoid page reload and go to hash at once
function handleLinks(path: string, search: string, host: string) {
  const links = document.getElementsByTagName('a')
  const linksArray = Array.from(links)

  for (const link of linksArray) {
    const isLocalLink = (link.href.startsWith('/') || link.host === host) && link.hash

    if (!isLocalLink) continue

    const regex = /#([^#]*)$/
    const match = regex.exec(link.href)

    if (!match) continue

    const id = match[1]
    link.addEventListener('click', (e) => {
      e.preventDefault()
      const element = document.getElementById(id)

      if (element) {
        element.scrollIntoView()
        window.history.replaceState(null, '', path + search + '#' + id)
      }
    })
  }
}
