import React, { useCallback, MouseEvent, useMemo, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { getTagVerdict, createReportsSearchLink, getVerdictClass } from '../helpers'
import { SignalVerdict, TagType } from '../types'
import infoIcon from 'app/../assets/icons/opswat/Information.svg'
import Button from 'app/modules/shared/components/button'

type TagProps = {
  tag: TagType
  className?: string
  noColor?: boolean
  exporting: boolean
}
export function Tag(props: TagProps): JSX.Element {
  const { tag, className, noColor, exporting } = props

  const handleClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    exporting && e.preventDefault()
    e.stopPropagation()
  }, [])

  const name = tag.tag.name
  const verdict = getTagVerdict(tag)
  const descriptions = tag.tag.descriptions
  const searchLink = createReportsSearchLink({ tag: name })
  const synonyms = tag.tag.synonyms
  const tagClass = (typeof className == 'undefined' ? '' : className) + (noColor ? '' : ' ' + getVerdictClass(verdict))

  const [description, source] = useMemo(() => {
    if (descriptions && Array.isArray(descriptions) && descriptions[0]) {
      const desc: string = descriptions[0].description
      const cluster = descriptions[0].cluster
      const type: string = cluster.type ?? ''
      let authors = ''
      if (cluster.authors) {
        if (Array.isArray(cluster.authors)) {
          authors += cluster.authors.join(', ')
        } else {
          authors += cluster.authors
        }
      }

      const source = (
        <span>
          {synonyms && !!synonyms.length && (
            <>
              <b>Synonyms</b>:&nbsp;{synonyms.join(', ')}
              <br />
            </>
          )}
          {type && (
            <>
              <b>Source</b>:&nbsp;MISP Galaxy ({type})&nbsp;
            </>
          )}
          {!type && authors && (
            <>
              <b>Authors</b>:&nbsp;{authors}
            </>
          )}
        </span>
      )
      return [desc, source]
    } else {
      return ['', '']
    }
  }, [descriptions, synonyms])

  return (
    <a
      className={'badge-fs-link ' + (exporting ? 'cursor-default' : '')}
      href={!exporting ? searchLink : '#'}
      target={exporting ? '_self' : '_blank'}
      onClick={handleClick}
      aria-label="Tag search"
      rel="noreferrer"
    >
      <span className={`badge badge-fs badge-fs-tag text-lower ${tagClass} ${exporting ? 'no-hover' : ''}`}>
        {exporting ? (
          <span className="m-auto d-flex align-items-center">
            {name}
            {!!description && (
              <i
                onClick={(e: MouseEvent) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                className="ds3-icon ds3-information fa-2 ms-1"
              />
            )}
          </span>
        ) : (
          <span className="m-auto d-flex align-items-center">
            {name}
            {!!description && (
              <OverlayTrigger
                placement="auto"
                aria-hidden={true}
                overlay={
                  <Popover id={`${name}-info`} title={description} className="responsive-popover">
                    {description && <Popover.Header>{description}</Popover.Header>}
                    {source && <Popover.Body>{source}</Popover.Body>}
                  </Popover>
                }
                delay={300}
              >
                {({ ref, ...triggerHandler }) => (
                  <i
                    {...triggerHandler}
                    onClick={(e: MouseEvent) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    ref={ref}
                    className="ds3-icon ds3-information ms-1"
                  ></i>
                )}
              </OverlayTrigger>
            )}
          </span>
        )}
      </span>
    </a>
  )
}

type TagListProps = {
  tags: TagType[] | string[]
  sort?: boolean
  short?: boolean
  tagClass?: string
  exporting?: boolean
  allowFull?: boolean
  size?: 'lg'
  showLimit?: number
}
export function TagList(props: TagListProps): JSX.Element | null {
  const { tags, sort, short, tagClass, exporting, size, showLimit } = props

  let { allowFull } = props
  if (typeof allowFull === 'undefined') {
    allowFull = true
  }

  const [roots, setRoots] = useState<TagType[]>([])
  const [full, setFull] = useState(false)
  const verdicts = Object.values(SignalVerdict)
  const shortenToLength = showLimit || 3 // Amount of tags to show while collapsing other
  const tagsObjects: TagType[] = tags.map((tag) => normalizeTag(tag))

  const toggleFull = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()
    allowFull && setFull((flag) => !flag)
  }, [])

  const sorted = useMemo(() => {
    const allTags = [...tagsObjects]
    const roots = allTags.filter((tag: TagType) => tag.source === 'MEDIA_TYPE' && tag.isRootTag)
    setRoots(roots)

    const filtered = allTags
      .filter(
        (tag: TagType, idx: number) => allTags.findIndex((value: TagType) => value.tag.name === tag.tag.name) === idx
      )
      .filter((tag: TagType) => tag.source !== 'MEDIA_TYPE' || !tag.isRootTag)

    // Todo: Can be removed if BE sort tags
    // (currently available but can't be removed for old database)
    if (!sort) {
      return filtered
    } else {
      return filtered.sort((a: TagType, b: TagType) => {
        let comp = (b.tag.descriptions?.length ? 1 : 0) - (a.tag.descriptions?.length ? 1 : 0)
        if (comp === 0) {
          const verdictA = getTagVerdict(a)
          const verdictB = getTagVerdict(b)
          comp = verdicts.indexOf(verdictA) - verdicts.indexOf(verdictB)
        }
        return comp
      })
    }
    // eslint-disable-next-line
  }, [tags, sort])

  const mals = useMemo(() => {
    const mals = sorted.filter((tag: TagType) => {
      const verdict = getTagVerdict(tag)
      return verdict === SignalVerdict.Malicious || verdict === SignalVerdict.Likely_malicious
    })

    return mals
  }, [sorted])

  const shortLimit = Math.max(shortenToLength - roots.length, allowFull ? mals.length : 0)
  const shortened = useMemo(() => {
    if (!short) {
      return sorted
    } else {
      const doCollapse = sorted.length > shortLimit
      return doCollapse ? sorted.slice(0, shortLimit) : sorted
    }
  }, [short, mals, sorted, roots])

  const moreNames = useMemo(() => {
    if (allowFull || sorted.length === shortened.length) {
      return undefined
    }

    const more = sorted.slice(shortLimit)
    const names = []
    for (const item of more) {
      names.push(item.tag.name)
    }

    return names.length ? names.join(', ') : undefined
  }, [sorted, shortened])

  const sizeClass = size === 'lg' ? 'tag-lg' : ''
  const tagClassStr = (typeof tagClass == 'undefined' ? '' : tagClass) + ' ' + sizeClass

  return (
    <div className={`tag-list ${size === 'lg' ? 'tags-lg' : ''}`}>
      {/* root media_type tags */}
      {!!roots.length &&
        roots.map((tag: TagType, idx: number) => (
          <Tag
            tag={tag}
            key={`root-${idx}`}
            className={`badge-fs badge-fs-info ${tagClassStr}`}
            noColor
            exporting={!!exporting}
          />
        ))}

      {/* shortend tags */}
      {full ||
        shortened.map((tag: TagType, idx: number) => (
          <Tag tag={tag} key={idx} className={tagClassStr} exporting={!!exporting} />
        ))}

      {/* all tags */}
      {full && sorted.map((tag: TagType, idx: number) => <Tag tag={tag} key={idx} exporting={!!exporting} />)}

      {/* more button */}
      {shortened.length < sorted.length && !full && (
        <Button
          variant=""
          onClick={toggleFull}
          className={'badge-fs more-button ' + (allowFull ? '' : 'with-tooltip')}
          aria-label="More tags"
          tooltip={moreNames}
        >
          {`+${sorted.length - shortened.length}`}
        </Button>
      )}
    </div>
  )
}

function normalizeTag(tag: TagType | string) {
  if (typeof tag !== 'string') {
    return tag
  }

  return {
    source: '',
    tag: {
      name: tag,
    },
  }
}
