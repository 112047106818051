import BaseFormatter from './base'
import { hasFields, sanitizeDetail } from '../../helpers'

/**
 * Format report response data for PE file details page
 */
export default class LnkDetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const result: { [key: string]: any } = {}

    const resource = this.getResource(data, 'file')
    if (!resource) return

    const fileType = !data?.file?.type || data.file.type === 'other' ? this.getFileType(resource) : data.file.type
    if (fileType !== 'lnk') {
      return
    }

    this.formatExtended(resource.extendedData, result)

    if (hasFields(result)) {
      report.lnk_details = result
      report.lnk_details.overview = { ...report.details.overview }
      delete report.details
    }
  }

  formatExtended(resource: { [key: string]: any }, result: { [key: string]: any }): void {
    if (!resource) return

    const fields = ['data', 'extra', 'header', 'target', 'link_info']

    const extended: { [key: string]: any } = {}
    if (resource.metaData) {
      extended.meta = resource.metaData
      sanitizeDetail(extended)
      if (hasFields(extended.meta)) {
        result.meta = extended.meta
      }
    }

    for (const field of fields) {
      if (field in resource) {
        result[field] = resource[field]
      }
    }
  }
}
