import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { HexViewerHandle, HexViewerProps } from './types'
import { EMPTY_INLINE_STYLES } from './constants'

import hexEditorStyles from './utils/styles'

import HexViewer from './hex-viewer'

const StyledHexViewer = forwardRef<HexViewerHandle, HexViewerProps>(
  ({ inlineStyles = EMPTY_INLINE_STYLES, ...restProps }: HexViewerProps, ref) => (
    <HexViewer inlineStyles={inlineStyles} ref={ref} {...restProps} />
  )
)

const StyledHexViewerWithStyles = styled(StyledHexViewer as React.FC<React.PropsWithChildren<HexViewerProps>>)`
  ${hexEditorStyles}
`

export default StyledHexViewerWithStyles
