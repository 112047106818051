import React, { useMemo } from 'react'
import { defaultStyles, DefaultExtensionType } from 'react-file-icon'
import CustomFileIcon from '../custom-file-icon'

export function FileItem({ file }: { file: File | string }): JSX.Element {
  const [name, formatedName] = useMemo(() => getFileName(file), [file])
  const [extension, childProps] = useMemo(() => {
    const idx = name.lastIndexOf('.')
    const ext = idx >= 0 ? name.slice(idx + 1).toLowerCase() : ''

    let type = defaultStyles[ext as DefaultExtensionType]
    if (!type) {
      type = { type: 'document' }
    }

    return [ext, type]
  }, [name])

  return (
    <div className="ellipsis">
      <span className="file-icon me-2 d-inline-block">
        <CustomFileIcon extension={extension} color="#b6e5ff" {...childProps} />
      </span>
      {formatedName}
    </div>
  )
}

function getFileName(file: File | string) {
  let name = typeof file === 'string' ? file : file.name
  name = name.trim()
  let formated = name

  // Display only a few of last url pathname parts, it url is too long
  const limit = 40
  if (typeof file === 'string' && name.length > limit) {
    const match = name.match(/^https?:\/\/[^/]+\/[^/]+/)
    if (match) {
      formated = ''
      const parts = name.replace(/^https?:\/\//, '').split('/')
      const useParts = []
      let totalLength = 0

      for (let i = parts.length - 1; i > 0; i--) {
        const partLength = parts[i].length + 1 // 1 for adding slash
        totalLength += partLength
        if (totalLength <= limit) {
          useParts.push(parts[i])
          continue
        }

        const prevLength = totalLength - partLength
        if (prevLength < 2) {
          // check if name won't be just /
          useParts.push(parts[i])
        }

        break
      }

      formated = '.../' + useParts.reverse().join('/')
    }
  }

  return [name, formated]
}
