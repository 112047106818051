import { AuthContext } from 'app/modules/auth'
import React, { useContext, createContext, useState, useCallback, useEffect } from 'react'
import { ThemeContextType, VisualMode } from '../types/theme'
import { applicationConfiguration } from './configuration-context'

export const ThemeContext = createContext<ThemeContextType | null>(null)

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [used, setUsed] = useState(false)
  const user = useContext(AuthContext)?.user
  const { defaultTheme } = useContext(applicationConfiguration)
  const [mode, setMode] = useState<VisualMode>(defaultTheme as VisualMode)

  // Set theme on page load or user change
  useEffect(() => {
    // Temporarly disable dark mode with the new UI
    //const theme = user?.theme || defaultTheme;
    const theme = 'light'

    if (theme !== mode) {
      setTheme(theme)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTheme, user])

  // Apply new theme to page markup
  useEffect(() => {
    const root = document.documentElement
    if (root) {
      root.className = ''
      root.classList.add(`theme-${mode}`)
    }
  }, [mode])

  const setTheme = useCallback((mode: string) => {
    // Temporarly disable dark mode with the new UI
    //setMode(mode as VisualMode);
    setMode('light')
    setUsed(true)
  }, [])

  return <ThemeContext.Provider value={{ mode, themeUsed: used, setTheme }}>{children}</ThemeContext.Provider>
}

export const useTheme = () => {
  const theme = useContext(ThemeContext)

  if (!theme) throw Error('All components should be placed inside the theme context')
  return theme
}
