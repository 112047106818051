import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { TermsModal } from 'app/components/footer/terms-modal'
import { applicationConfiguration, modalContext } from '../../../shared'
import { BaseUploadOptions } from './types'
import { HelpIcon } from './helpers'
import { FormControl } from 'app/modules/shared/components/form/form-control'
import Checkbox from 'app/components/checkbox'
import TextInput from 'app/components/text-input'
import { Feature } from 'app/modules/auth/types'
import { allowedFeaturesStorage } from 'app/modules/auth/helper/storage'

type BaseTabParams = {
  data: BaseUploadOptions
  setData: CallableFunction
  error: string | undefined
  setError: CallableFunction
}

export function BaseScanOptions({ data, setData, error, setError }: BaseTabParams) {
  const { _t } = useTypedTranslation()
  const config = useContext(applicationConfiguration)
  const modalWrapper = useContext(modalContext)
  const features = allowedFeaturesStorage.getFeatures()
  const isPrivateReportAllowed = features && features.indexOf(Feature.Private_report) !== -1

  function setValue(name: string | string[], value: any) {
    const values: { [key: string]: any } = { ...data }

    if (Array.isArray(name)) {
      name.forEach((item) => {
        values[item] = value
      })
    } else {
      values[name] = value
    }

    setData(values as BaseUploadOptions)
  }

  function changeDescription(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setValue('description', e.target.value)
    // Parse tags in FileUploadModal
  }

  function changeIsTerms(checked: boolean) {
    setValue('isTerms', checked)
    setError('')
  }

  return (
    <>
      <FormControl
        label={_t('file-description')}
        labelClass=""
        control={
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={_t('upload-description-placeholder')}
            value={data.description}
            onChange={changeDescription as any}
          ></Form.Control>
        }
      />

      {data.showPropagate && (
        <FormControl
          parentClass="d-flex mb-4 align-items-start"
          control={
            <Checkbox
              id="showPropagate"
              label={_t('propagate-tags')}
              className="d-inline-flex align-items-center"
              checked={data.propagateTags}
              onChange={(checked) => setValue('propagateTags', checked)}
            />
          }
        />
      )}

      <FormControl
        parentClass="d-flex mb-4 align-items-start"
        control={
          <>
            <Checkbox
              id="isPassword"
              label={_t('password')}
              className="d-inline-flex align-items-center"
              checked={data.isPassword}
              onChange={(checked) => setValue('isPassword', checked)}
            />
            <HelpIcon tooltipId="password-tooltip" note={_t('password-tooltip')} />
          </>
        }
      />

      {data.isPassword && (
        <FormControl
          control={
            <TextInput
              type="password"
              value={data.password}
              autoComplete="off"
              aria-label="password"
              aria-describedby="basic-addon1"
              onChange={(e: any) => setValue('password', e.target.value)}
            />
          }
        />
      )}

      <FormControl
        parentClass="d-flex mb-4 align-items-start"
        control={
          <>
            <Checkbox
              id="isPrivate"
              label={_t('do-not-share-file')}
              className="d-inline-flex align-items-center"
              checked={data.isPrivate}
              onChange={(checked) => {
                if (data.isPrivateReport && !checked) {
                  setValue(['isPrivate', 'isPrivateReport'], checked)
                } else {
                  setValue('isPrivate', checked)
                }
              }}
            />
            <HelpIcon tooltipId="private-tooltip" note={_t('private-tooltip')} />
          </>
        }
      />

      {isPrivateReportAllowed ? (
        <FormControl
          parentClass="d-flex mb-4 align-items-start"
          control={
            <>
              <Checkbox
                id="isPrivateReport"
                label={_t('private-report')}
                className="d-inline-flex align-items-center"
                checked={data.isPrivateReport}
                onChange={(checked) => setValue(['isPrivateReport', 'isPrivate'], checked)}
              />
              <HelpIcon tooltipId="private-report-tooltip" note={_t('private-report-tooltip')} />
            </>
          }
        />
      ) : null}

      <FormControl
        parentClass={'d-flex mb-4 align-items-start' + (config.askTermsConsent ? '' : 'mb-0')}
        control={
          <>
            <Checkbox
              id="skipWhitelisted"
              label={_t('skip-whitelisted')}
              className="d-inline-flex align-items-center"
              checked={data.skipWhitelisted}
              onChange={(checked) => setValue('skipWhitelisted', checked)}
            />
            <HelpIcon tooltipId="skip-whitelisted-tooltip" note={_t('skip-whitelisted-tooltip')} />
          </>
        }
      />

      {config.askTermsConsent ? (
        <FormControl
          error={error}
          parentClass="mb-0"
          control={
            <Checkbox
              id="isTerms"
              label={
                <>
                  {_t('terms-label1')}
                  <TermsModal title={_t('terms-condition')} modalWrapper={modalWrapper} isTerms={true} />{' '}
                  {_t('terms-label3')}
                  <TermsModal title={_t('privacy-policy')} modalWrapper={modalWrapper} isTerms={false} />
                </>
              }
              className="d-inline-flex align-items-center"
              checked={data.isTerms}
              onChange={changeIsTerms}
            />
          }
        />
      ) : null}
    </>
  )
}
