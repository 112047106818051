import React from 'react'
import { InternalErrorPage } from './internal-error'
import { ForbiddenPage } from './forbidden'
import { ForbiddenError, NotFoundError } from '../../modules/shared/exceptions'
import NotFoundPage from './not-found'

export function ErrorPageSelector({ error, resetError }: { error: any; resetError: () => void }): JSX.Element {
  return (
    <>
      {error instanceof ForbiddenError ? (
        <ForbiddenPage error={error} resetError={resetError} />
      ) : error instanceof NotFoundError ? (
        <NotFoundPage resetError={resetError} />
      ) : (
        <InternalErrorPage error={error} resetError={resetError} />
      )}
    </>
  )
}
