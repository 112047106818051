import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import React from 'react'
import { ZoomableThumbnail } from '../../../components'
import { capitalize } from '../helpers'

type VisualizationProps = {
  base64: string
  type: string
}

export function Visualization(props: VisualizationProps): JSX.Element {
  const { _t } = useTypedTranslation()

  const items = [
    {
      base64: props.base64,
      mediaType: props.type,
    },
  ]

  return (
    <ZoomableThumbnail
      items={items}
      title="File Visualization"
      otherContent={{
        afterImage: (
          <div className="mt-3">
            <span className="text-capital">{_t('source')}</span>:
            <a
              href="https://github.com/katjahahn/PortEx"
              target="_blank"
              rel="noreferrer"
              className="text-default ms-2"
            >
              https://github.com/katjahahn/PortEx
            </a>
          </div>
        ),
      }}
    />
  )
}

Visualization.defaultProps = {
  type: 'image/png',
}
