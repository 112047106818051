import React from 'react'
import { createQueryString } from 'app/modules/shared/querystring'
import { IOCDataTypes } from '../types/ioc'
import { SignalVerdict, SearchParams, SearchType } from '../types'
import { logger } from 'app/components'
import { getPageUrl } from 'app/modules/shared/routing'
import { NavigateFunction } from 'react-router'

let constantStrings: string[] = []
let regExAbbr = new RegExp('')
const fileSizeUnits = ['B', 'kB', 'MB', 'GB', 'TB']
/**
 * Cast tags objects to strings
 *
 * @param {array} tags
 */
export function buildTags(tags: any[]): JSX.Element[] {
  return tags.map((item, idx) => {
    return (
      <a href="#!" key={idx}>
        #{item.tag.name}
      </a>
    )
  })
}

/**
 * Upper case first string letter
 *
 * @param {string} string
 */
export function capitalize(word?: string, strict?: boolean): string {
  if (!word) {
    return ''
  }

  let append = word.slice(1)
  if (strict === true) {
    append = append.toLowerCase()
  }

  return word.charAt(0).toUpperCase() + append
}

/**
 * Check floating number
 *
 * @param {number | string} number
 * @param {strict} boolean
 */

export function isFloat(number: string | number, strict?: boolean): boolean {
  if (strict === true) {
    return typeof number === 'number' && number % 1 !== 0
  }

  return Number(number) === number && number % 1 !== 0
}

/**
 * Check key is hash
 *
 * @param {string} key
 */

export function isHash(key: string): boolean {
  const hashLabels = {
    Ssdeep: 1,
    Imphash: 1,
    Authentihash: 1,
    Sdhash: 1,
    Sha256: 1,
    MD5: 1,
    fuzzyfsiohash: 1,
    'SHA-1': 1,
    'SHA-256': 1,
    'SHA-512': 1,
  }
  return has(hashLabels, key)
}

const underscoreRegexp = /_/g

/**
 * Format string, that is a value for enum field, obtained from scan service
 *
 * @param {string} item
 */
export function formatEnumItem(item: string, modifyTo?: string): string {
  item = capitalize(item, true).replace(underscoreRegexp, ' ')

  if (modifyTo === 'camelcase') {
    item = item
      .split(' ')
      .map((word) => capitalize(word, true))
      .join('')
  }
  return upperAbbr(item)
}

/**
 * Format verdict to display it to user
 */
export function formatVerdict(verdict: string, first_upper = true) {
  if (first_upper) {
    verdict = capitalize(verdict, true)
  }

  return verdict.replace(underscoreRegexp, ' ')
}

/**
 * Simple wrapper about check if field exists in object
 *
 * @param object
 * @param field
 */
export function has(object: { [key: string]: any }, ...fields: string[]): boolean {
  let source = object

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    const missing = typeof source !== 'object' || source === null || typeof source[field] === 'undefined'

    if (missing) {
      return false
    }

    source = source[field]
  }

  return true
}

/**
 * Check if object has at least 1 field
 * @param object
 */
export function hasFields(object: { [key: string]: any }): number {
  return Object.keys(object).length
}

/**
 * Determine if value is object of any type except null
 * @param value
 */
export function isObject(value: { [key: string]: any }): boolean {
  return typeof value === 'object' && !!value
}

/**
 * Determine if value is simple js non-empty object (like {foo: 'bar'})
 * @param value
 */
export function isSimpleObject(value: any): boolean {
  return typeof value === 'object' && !!value && !React.isValidElement(value)
}

/**
 * Abbreviate string
 */
export function abbreviate(str: string): string {
  const words = str.split(' ')
  let result = ''

  for (let i = 0; i < words.length; i++) {
    result += words[i][0].toUpperCase()
  }

  return result
}

/**
 * Abbreviate each string in array
 */
export function abbreviateArray(strings: string[]): string[] {
  const result = []
  for (let i = 0; i < strings.length; i++) {
    result.push(abbreviate(strings[i]))
  }

  return result
}

/**
 * Copy fields from one object to another
 */
export function copyFields(fields: string[], source: { [key: string]: any }, target: { [key: string]: any }): void {
  fields.forEach((field) => {
    if (has(source, field)) {
      target[field] = source[field]
    }
  })
}

/**
 * Uppercase abbreviation
 */

export function setConstants(constants: string[]): void {
  constantStrings = constants
  const words = constantStrings.map((str) => `^${str}\\b|\\b${str}\\b|\\b${str}$`)
  regExAbbr = new RegExp(`(${words.join('|')})`, 'gi')
}

function replacer(match: string, offset: number, string: string): string {
  return match.toUpperCase()
}

export function upperAbbr(string: any) {
  if (typeof string !== 'string') return string

  return string.replace(regExAbbr, replacer)
}

const hexRegexp = /\\(x[0-9a-f]{4})/g

/**
 * Decode all hex encoded symbols in a string
 */
export function decodeHex(str: string): string {
  return str.replace(hexRegexp, (match: string, hex: string) => {
    const asNumber = `0${hex}` as unknown as number
    return String.fromCharCode(asNumber)
  })
}

const iocClasses: { [key in IOCDataTypes]?: string } = {}

iocClasses[IOCDataTypes.Urls] = 'extracted-url'
iocClasses[IOCDataTypes.Domains] = 'extracted-domain'
iocClasses[IOCDataTypes.HashesMD5] = 'extracted-hash'
iocClasses[IOCDataTypes.HashesSHA1] = 'extracted-hash'
iocClasses[IOCDataTypes.HashesSHA256] = 'extracted-hash'
iocClasses[IOCDataTypes.HashesSHA512] = 'extracted-hash'
iocClasses[IOCDataTypes.UUIDs] = 'extracted-uuid'
iocClasses[IOCDataTypes.IPs] = 'extracted-ip'
iocClasses[IOCDataTypes.RegistryPathways] = 'extracted-registry-path'
iocClasses[IOCDataTypes.Emails] = 'extracted-email'
iocClasses[IOCDataTypes.RevisionSaveIDs] = 'extracted-revision-save-ids'

const iocHashClasses: { [key in IOCDataTypes]?: string } = {}
iocHashClasses[IOCDataTypes.HashesMD5] = 'extracted-hash'
iocHashClasses[IOCDataTypes.HashesSHA1] = 'extracted-hash'
iocHashClasses[IOCDataTypes.HashesSHA256] = 'extracted-hash'
iocHashClasses[IOCDataTypes.HashesSHA512] = 'extracted-hash'

/**
 * Get class for displaying colored IOC
 */
export function getClassForIOCType(type: IOCDataTypes): string | undefined {
  return has(iocClasses, type) ? iocClasses[type] : undefined
}

export function readableIOCType(type: IOCDataTypes): string {
  return type
    .replace('extracted', '')
    .replace('Hashes', '')
    .replace('SHA256', 'SHA-256s')
    .replace('SHA512', 'SHA-512s')
    .replace('UNCPaths', 'UNC Paths')
}

/**
 * Check has type for IOC
 */
export function isIOCHashType(type: IOCDataTypes): boolean {
  return has(iocHashClasses, type)
}

// Get name of IOC search parameter depending on search type
export function getIOCSearchKey(type: IOCDataTypes, allowQuery = true): string {
  // We post multiple IOCs types during prevalence search, so we can
  // not use `query` parameter
  const prevalenceSearchKeys = {
    [IOCDataTypes.Urls]: 'url',
    [IOCDataTypes.Domains]: 'domain',
    [IOCDataTypes.HashesMD5]: 'md5',
    [IOCDataTypes.HashesSHA1]: 'sha1',
    [IOCDataTypes.HashesSHA256]: 'sha256',
    [IOCDataTypes.HashesSHA512]: 'sha512',
    [IOCDataTypes.UUIDs]: 'uuid',
    [IOCDataTypes.IPs]: 'ip',
    [IOCDataTypes.RegistryPathways]: 'registry_path',
    [IOCDataTypes.Emails]: 'email',
    [IOCDataTypes.RevisionSaveIDs]: 'revision_save_id',
    [IOCDataTypes.CryptoWalletsBTC]: 'btc_wallet',
    [IOCDataTypes.CryptoWalletsETH]: 'eth_wallet',
    [IOCDataTypes.CryptoWalletsXMR]: 'xmr_wallet',
    [IOCDataTypes.UNCPaths]: 'unc_path',
  }

  const searchAsQuery = [
    IOCDataTypes.Urls,
    IOCDataTypes.HashesMD5,
    IOCDataTypes.HashesSHA1,
    IOCDataTypes.HashesSHA256,
    IOCDataTypes.HashesSHA512,
    IOCDataTypes.IPs,
  ]

  if (!allowQuery) {
    return prevalenceSearchKeys[type]
  }

  return searchAsQuery.includes(type) ? 'query' : prevalenceSearchKeys[type]
}

export function getVerdictMarks(verdict?: SignalVerdict): number {
  if (!verdict) return 100
  return Object.values(SignalVerdict).indexOf(verdict)
}

export function buildPrevalenceSearchLink(
  type: string,
  value: string,
  days: number,
  currentReportId: string,
  verdicts?: SignalVerdict[]
): string {
  const queryObj: { [key: string]: string | number } = {
    [type]: value,
    exclude: currentReportId,
  }
  if (verdicts) {
    queryObj.verdict_groups = verdicts.join(',')
  }
  if (days > 0) {
    queryObj.age = days
  }

  return createReportsSearchLink(queryObj)
}

const hashLabels: { [key: string]: string } = {
  SHA256: 'SHA-256',
  SHA512: 'SHA-512',
  SHA1: 'SHA-1',
}
/**
 * Get label for hash type
 */
export function getHashLabel(hash: string): string {
  if (hash in hashLabels) {
    return hashLabels[hash]
  }

  return hash
}
/**
 * Build upload link (upload reports list page)
 */
export function buildUploadLink(flowId: string): string {
  return `/uploads/${flowId}`
}

/**
 * Build report link
 */
export function buildReportLink(flowId: string, reportId: string, subpage = 'overview'): string {
  return `/uploads/${flowId}/reports/${reportId}/${subpage}`
}

/**
 * Buidl list of react elements separated by comma
 */
export function buildCommaElementsList(items: string[], itemClass?: string): JSX.Element[] {
  const values: JSX.Element[] = []
  const glue = <>, </>

  items.forEach((item: string) => {
    values.push(<span className={itemClass}>{item}</span>)
    values.push(glue)
  })
  values.pop()

  return values
}

export function sanitizeDetail(data: { [key: string]: any }): void {
  const notShowing = {
    sandbox: 'meta.sandboxAnalysisRecommended',
  }

  Object.values(notShowing).forEach((path: string) => {
    let item: any = data
    let idx = 0
    const paths = path.split('.')

    for (idx = 0; idx < paths.length - 1; idx++) {
      item = item[paths[idx]]
      if (!item) return
    }

    if (paths[idx] in item) delete item[paths[idx]]
  })
}

export function humanFileSize(size: number, decimals = 2): string {
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(decimals) + ' ' + fileSizeUnits[i]
}

/**
 * Parse human readable file size (x MB) to bytes (number)
 *
 * @param sizeStr
 */
export function parseHumanFileSize(sizeStr: string): number | undefined {
  const regex = /^([\d.]+)\s*([a-zA-Z]+)$/
  const match = sizeStr.match(regex)

  if (!match) {
    return
  }

  const value = parseFloat(match[1])
  const unit = match[2]

  const index = fileSizeUnits.indexOf(unit)
  if (index === -1) {
    return
  }

  return value * Math.pow(1024, index)
}

export function readableCount(count: number): string {
  if (count < 1000) {
    return `${count}`
  } else if (count < 1000000) {
    const kilos = Math.floor(count / 1000)
    return `${kilos}k${count % 1000 === 0 ? '' : '+'}`
  } else {
    const megas = Math.floor(count / 1000000)
    return `${megas}M${count % 1000000 === 0 ? '' : '+'}`
  }
}

export function unescapeObject(data: { [key: string]: any }): void {
  for (const key in data) {
    const value = data[key]
    if (typeof value === 'string') {
      data[key] = unescapeString(value)
    } else if (typeof value === 'object') {
      unescapeObject(data[key])
    }
  }
}

export function unescapeObjectKeys(data: { [key: string]: any }): void {
  for (const key in data) {
    const value = data[key]
    const newKey = key.replace(/__/g, '.')
    if (newKey !== key) {
      data[newKey] = value
      delete data[key]
    }

    if (typeof value === 'object') {
      unescapeObjectKeys(value)
    }
  }
}

export function unescapeString(value: string): string {
  value = value.replace(/\\x([a-fA-F0-9]{2})/g, (substr, p1) => {
    return String.fromCharCode(parseInt(p1, 16))
  })
  value = value.replace(/\\u([a-fA-F0-9]{4})/g, (substr, p1) => {
    return String.fromCharCode(parseInt(p1, 16))
  })

  return value
}

const base64Keys = ['filename', 'ip', 'url', 'registry_path', 'revision_save_id', 'tags', 'query']
export function encodeQuery(params: { [key: string]: any }): { [key: string]: any } {
  const newParam = { ...params }
  for (const key in params) {
    if (base64Keys.includes(key)) {
      newParam[key] = btoa(newParam[key])
    }
  }

  return newParam
}

export function decodeQuery(params: { [key: string]: any }): { [key: string]: any } {
  const newParam = { ...params }
  for (const key in params) {
    if (base64Keys.includes(key)) {
      newParam[key] = atob(newParam[key])
    }
  }

  return newParam
}

// Generic function to create reports search link
export function createReportsSearchLink(filters: SearchParams) {
  const values: SearchParams = {}

  Object.keys(filters).forEach((valueKey) => {
    const key = valueKey as keyof SearchParams
    const value = filters[key]

    if (value === '') {
      return
    }

    if (key === 'tag' && typeof value === 'string' && value.startsWith('#')) {
      values[key] = value.substring(1)
    } else {
      values[key] = value as any
    }
  })

  const params = encodeQuery(values)
  const query = createQueryString(params)

  return getPageUrl('search-result') + '?' + query
}

// Generic function to launch reports search from anywhere
export function doReportsSearch(filters: SearchParams, navigate?: NavigateFunction) {
  const link = createReportsSearchLink(filters)

  if (navigate) {
    navigate(link)
  } else {
    const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
    newWindow && (newWindow.opener = null)
  }
}

// Detect search type performed by user
export function getSearchType(filters: SearchParams) {
  const names = Object.keys(filters)
  const isSimpleSearch =
    typeof filters.query !== 'undefined' &&
    (names.length === 1 || (names.length === 2 && names.includes('no_date_limit')))

  return isSimpleSearch ? SearchType.Simple : SearchType.Advanced
}

// Convert URLSearchParams object to simple object
export function castSearchParamsToObject(params: URLSearchParams) {
  const result: { [key: string]: any } = {}
  params.forEach((value, key) => (result[key] = value))

  return result
}

export function safe_decodeURI(uri: string): string {
  try {
    return decodeURIComponent(uri)
  } catch (e) {
    logger.info('Could not decode filename, keeping original name ', uri)
    return uri
  }
}

export function formatReportDate(date: string): JSX.Element {
  const dateParts = date.split(',')
  return (
    <span>
      {dateParts[0]}, <span className="text-muted">{dateParts[1]}</span>
    </span>
  )
}
