import { environment } from '../../../environments/environment'

// Logger factory, that allows using given prefix for all logs messages
export function getLogger(prefix?: string) {
  const withPrefix = (args: any[]) => {
    if (typeof prefix !== 'undefined') {
      args.unshift(prefix)
    }

    return args
  }

  return {
    info(...args: any[]): void {
      console.info(...withPrefix(args));
    },
    debug(...args: any[]): void {
      if (!environment.production) {
        console.info(...withPrefix(args));
      }
    },
    warn(...args: any[]): void {
      if (!environment.production) {
        console.warn(...withPrefix(args));
      }
    },
    error(...args: any[]): void {
      if (!environment.production) {
        console.error(...withPrefix(args));
      }
    }
  }
}

export const logger = getLogger();
