import { createContext, useContext } from 'react'
import { StoreType, ActionType, PureAction } from '../../shared/context/ctx'

type CheckStatus = {
  done: boolean
  error: string
  days: number
  data?: any
}
export interface ReportCheckStatus {
  [type: string]: CheckStatus
}

type ReportCheckStoreType = StoreType<ReportCheckStatus>
export const initialStatus: ReportCheckStoreType = {
  state: {
    ioc: { done: false, error: '', days: 7 },
    similar: { done: false, error: '', days: -1 },
    yara: { done: false, error: '', days: -1 },
  },
  dispatch: (action: ActionType) => {},
}

export const ReportCheckContext = createContext<ReportCheckStoreType>(initialStatus)
type ReportCheckStatusSelector = (store: ReportCheckStatus) => any

export const useSelector = (selectorFn: ReportCheckStatusSelector) => {
  const value = useContext<ReportCheckStoreType>(ReportCheckContext)
  return selectorFn(value.state)
}

export const useDispatch = () => {
  const value = useContext<ReportCheckStoreType>(ReportCheckContext)
  return value.dispatch
}

export function reducer(state: ReportCheckStatus, action: PureAction): ReportCheckStatus {
  return {
    ...state,
    [action.type]: { ...state[action.type], ...action.payload },
  }
}
