import { PageData } from './types'

let pagesData: { [key: string]: PageData } = {}
export let currentPage: PageData | undefined
export const routeState: { [key: string]: any } = {}

/**
 * Set pages routing data.
 * Do not define that data here to avoid circular references
 *  when using, say, getPageUrl function from this module
 *  in components that are included into pages data.
 */
export function setPagesData(allPagesData: { [key: string]: PageData }): void {
  pagesData = allPagesData
}

/**
 * Get data of given page by its key
 */
export function getPageData(pageKey: string): PageData | undefined {
  return pagesData[pageKey] && { ...pagesData[pageKey], key: pageKey }
}

/**
 * Get url for given page
 */
export function getPageUrl(key: string, params?: { [key: string]: string }): string | undefined {
  if (typeof pagesData[key] === 'undefined') {
    return undefined
  }

  let url = pagesData[key].url
  if (params) {
    Object.keys(params).forEach((name) => {
      url = url.replace('/:' + name, '/' + params[name])
    })
  }

  return url
}
