import { AllExtractedFilesTypes, IOCFileTypes, mainFileKey } from '../types'
import { ReportWrapperConstructor } from './report-wrapper-base'

export function SubpagesMixin<TBase extends ReportWrapperConstructor>(Base: TBase) {
  return class ReportWithSubpages extends Base {
    /**
     * Get yara rules matches for given file report
     */
    getYaraMatches(reportId: string) {
      const report = this.getReport(reportId)
      return report?.yara
    }

    /**
     * Get overview data for given file report
     */
    getOverview(reportId: string) {
      const report = this.getReport(reportId)
      return report?.overview
    }

    /**
     * Get scan options for given file report
     */
    getScanOptions(reportId: string) {
      const report = this.getReport(reportId)
      return report?.scanOptions
    }

    /**
     * Get name of scan profile user for this scan
     */
    getScanProfile(reportId: string) {
      const report = this.getReport(reportId)
      return report?.scanProfile
    }

    /**
     * Get indicator that show if default scan options have been used
     */
    isDefaultOptionsUsed(reportId: string) {
      const report = this.getReport(reportId)
      return report?.defaultOptionsUsed
    }

    /**
     * Get all extracted stings for given file report
     */
    getExtractedStrings(reportId: string) {
      const report = this.getReport(reportId)

      return report?.strings
    }

    /**
     * Get details for PE file
     */
    getPEDetails(reportId: string) {
      const report = this.getReport(reportId)

      return report?.pe_details
    }

    /**
     * Get indicators of compromise
     */
    getIOCs(reportId: string) {
      const report = this.getReport(reportId)

      return report?.ioc
    }

    /**
     * Get hasInterestingIOCs flag
     */
    hasInterestingIOCs(reportId: string) {
      const report = this.getReport(reportId)

      return report?.hasInterestingIOCs
    }

    /**
     * Get indicators of compromise names, that were stripped from result
     */
    getStrippedIOCs(reportId: string) {
      const report = this.getReport(reportId)

      return report?.strippedIocs
    }

    /**
     * Get details for general file
     */
    getOtherDetails(reportId: string) {
      const report = this.getReport(reportId)

      return report?.details
    }

    /**
     * Get details for MS Office file
     */
    getMsOfficeDetails(reportId: string) {
      const report = this.getReport(reportId)

      return report?.ms_office_details
    }

    /**
     * Get details for EML file
     */
    getEmlDetails(reportId: string) {
      const report = this.getReport(reportId)

      return report?.eml_details
    }

    /**
     * Get details for MBox file
     */
    getMboxDetails(reportId: string) {
      const report = this.getReport(reportId)

      return report?.mbox_details
    }

    /**
     * Get details for PDF file
     */
    getPdfDetails(reportId: string) {
      const report = this.getReport(reportId)

      return report?.pdf_details
    }

    /**
     * Generic method to get file details
     */
    getDetails(reportId: string) {
      const report = this.getReport(reportId)

      return (
        report?.details ??
        report?.pdf_details ??
        report?.mbox_details ??
        report?.eml_details ??
        report?.ms_office_details ??
        report?.pe_details ??
        report?.lnk_details ??
        report?.elf_details
      )
    }

    /**
     * Get short summary of main report file, to use it in all files lists
     */
    getFileShortSummary(reportId: string) {
      const report = this.getReport(reportId)
      const details = this.getDetails(reportId)

      return {
        id: mainFileKey,
        name: report?.file?.name,
        description: details?.overview?.fileMagicDescription,
        size: details?.overview?.size,
        mediaType: details?.meta?.['Content-Type'],
        source: 'mainFile',
      }
    }

    /**
     * Get emulation data
     */
    getEmulationData(reportId: string) {
      const report = this.getReport(reportId)

      return report?.emulation_data
    }

    /**
     * Get OSINT data
     */
    getOsint(reportId: string) {
      const report = this.getReport(reportId)

      return report?.osint
    }

    /**
     * Get disassembly sections data
     */
    getDisassemblySections(reportId: string) {
      const report = this.getReport(reportId)

      return report?.disassembly
    }

    /**
     * Get geolocation data
     */
    getGeolocation(reportId: string) {
      const report = this.getReport(reportId)

      return report?.geolocation
    }

    /**
     * Get visualization data
     */
    getVisualization(reportId: string) {
      const report = this.getReport(reportId)

      return report?.visualization
    }

    /**
     * Get preview data
     */
    getPreview(reportId: string) {
      const report = this.getReport(reportId)

      return report?.preview
    }

    /**
     * Get URL details data
     */
    getUrlDetails(reportId: string) {
      const report = this.getReport(reportId)

      return report?.urlDetails
    }

    /**
     * Get extracted files data
     */
    getExtractedFiles(reportId: string, filesType?: AllExtractedFilesTypes) {
      const report = this.getReport(reportId)
      const allFiles = report?.extracted_files
      if (!filesType) {
        return allFiles
      }

      const hasFiles = allFiles && allFiles.files && typeof allFiles.files[filesType] !== 'undefined'
      return hasFiles ? allFiles.files[filesType] : undefined
    }

    /**
     * Get WHOIS lookup data
     */
    getWhoisData(reportId: string) {
      const report = this.getReport(reportId)

      return report?.whois
    }

    /**
     * Get helpers info
     */
    getHelpers(reportId: string) {
      const report = this.getReport(reportId)

      return report?.helpers
    }
  }
}
