export enum PasswordInvalidTypes {
  Empty = 'empty-password',
  SpecialCharacters = 'not-special-characters',
  MixedCase = 'not-mixed-case',
  LowerCase = 'lower-case',
  UpperCase = 'upper-case',
  MinimumLength = 'minimum-length-case',
  AlphaNumeric = 'alpha-numberic',
}

export type PasswordInvalidObject = {
  [PasswordInvalidTypes.Empty]?: boolean
  [PasswordInvalidTypes.LowerCase]?: boolean
  [PasswordInvalidTypes.UpperCase]?: boolean
  [PasswordInvalidTypes.AlphaNumeric]?: boolean
  [PasswordInvalidTypes.MinimumLength]?: boolean
  [PasswordInvalidTypes.SpecialCharacters]?: boolean
}
