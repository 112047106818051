import { HttpService } from '../../shared'
import { apiRoutes } from '../../shared/routing'
import { UserGroup } from '../types'
import { PageParam } from '../../shared/hooks'
import { EmailQueueItem } from '../features/user-management/emails/types'

export const usersService = {
  getUsers<T>(page?: number | undefined, filter?: string, ids?: string | string[]): Promise<T> {
    const params = []

    if (page) {
      params.push('page=' + page)
    }
    if (filter) {
      params.push('filter=' + filter)
    }

    if (ids && !Array.isArray(ids)) {
      ids = [ids]
    }
    if (ids) {
      ;(ids as string[]).forEach((item) => {
        params.push('ids=' + item)
      })
    }

    const query = params.length ? '?' + params.join('&') : ''

    return HttpService.get<T>(apiRoutes.users + query)
  },
  importUsers<T>(): Promise<T> {
    return HttpService.get<T>(apiRoutes.importUsers)
  },
  getUser<T>(userId: string): Promise<T> {
    const url = apiRoutes.adminEditUser.replace('{userId}', userId)
    return HttpService.get<T>(url)
  },
  editUser<T>(userId: string, data: { [key: string]: any }): Promise<T> {
    const url = apiRoutes.adminEditUser.replace('{userId}', userId)

    return HttpService.post<T>(url, data)
  },
  deleteUser(userId: string): Promise<void> {
    const url = apiRoutes.adminEditUser.replace('{userId}', userId)
    return HttpService.delete(url)
  },
  generateResetLink(userId: string): Promise<string> {
    const url = apiRoutes.adminResetUser.replace('{userId}', userId)
    return HttpService.get<{ token: string }>(url).then((res) => res.token)
  },
  getGroups<T>(page?: number | undefined): Promise<T> {
    const query = page ? '?page=' + page : ''

    return HttpService.get<T>(apiRoutes.userGroups + query)
  },
  postGroup<T>(data: UserGroup): Promise<T> {
    return HttpService.post<T>(apiRoutes.userGroups, data)
  },
  removeGroup<T>(groupId: string): Promise<T> {
    return HttpService.post<T>(apiRoutes.removeUserGroup.replace('{groupId}', groupId))
  },
  getUploads<T>(userId: string, params: PageParam): Promise<T> {
    const query = `?page=${params.page}&page_size=${params.pageSize}`
    return HttpService.get<T>(apiRoutes.userUploads.replace('{userId}', userId) + query)
  },
  generateInviteToken<T>(groups: string[]): Promise<T> {
    return HttpService.post<T>(apiRoutes.generateInviteToken, { groups })
  },
  getEmailingHistory<T>(): Promise<T> {
    return HttpService.get<T>(apiRoutes.emailingHistory)
  },
  postEmailQueueItem<T>(data: EmailQueueItem): Promise<T> {
    return HttpService.post<T>(apiRoutes.saveEmailQueueItem, data)
  },
  getEmailTemplates<T>(): Promise<T> {
    return HttpService.get<T>(apiRoutes.emailTemplates)
  },
}
