import React from 'react'
import { Card } from 'react-bootstrap'

/**
 * Base building block for report page
 */
export function ReportBlock({ children, className }: { children: any; className?: string }): JSX.Element {
  if (typeof className === 'undefined') {
    className = ''
  }

  return (
    <Card className={`report-block ${className}`}>
      <Card.Body className="d-flex">{children}</Card.Body>
    </Card>
  )
}
