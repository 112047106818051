import { HttpService } from '../../shared/services'
import { apiRoutes } from '../../shared/routing/api-routes'
import { InterestingReportsType } from '../types'

export const statService = {
  getIoc: (): Promise<any> => {
    return HttpService.get(apiRoutes.statIoc)
  },

  getTags: (): Promise<any> => {
    return HttpService.get(apiRoutes.getFrequentTags)
  },

  getInteresting: (): Promise<any> => {
    return HttpService.get(apiRoutes.getInterestingReport)
  },

  getYaraStat: (): Promise<any> => {
    return HttpService.get(apiRoutes.statYara)
  },

  getYaraInfo: (): Promise<any> => {
    return HttpService.get(apiRoutes.infoYara)
  },

  getMitreStat: (): Promise<any> => {
    return HttpService.get(apiRoutes.mitreStat)
  },
}
