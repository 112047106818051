import React from 'react'
import { Event } from './events'

export type EventsHandlerData = {
  addHandler: CallableFunction
  trigger: CallableFunction
}

/**
 * Generic event handler for custom events
 */
function EventsHandler(): EventsHandlerData {
  const handlers: { [event: string]: CallableFunction[] } = {}

  // Add event handler for given event
  function addHandler(event: Event, handler: CallableFunction) {
    if (typeof handlers[event] == 'undefined') {
      handlers[event] = []
    }

    handlers[event].push(handler)
  }

  // Execute all handlers for given event
  function trigger(event: Event, ...args: any[]) {
    if (typeof handlers[event] == 'undefined') {
      throw Error(`No event ${event} registered`)
    }

    let result

    handlers[event].forEach((handler: CallableFunction) => {
      result = handler(...args)
      if (React.isValidElement(result) || result === false) {
        return false
      }
    })

    return result
  }

  return { addHandler, trigger }
}

export const eventsHandler = EventsHandler()
