import { ScheduleTrigger, TriggerUnit, CronTriggerUnit } from '../components/form/schedule'

export type JobProcess = {
  name: string
  category: string
  description: string
  config?: JobProcessConfig
  state?: JobProcessState
}

export type JobProcessConfig = {
  name: string
  trigger: ScheduleTrigger
  trigger_config: {
    unit: TriggerUnit | CronTriggerUnit
    value: number | string
    time?: string
  }
  runtime_config?: { [key: string]: any }
  is_active: boolean
  is_editable: boolean
}

export type JobProcessConfigMap = {
  [jobName: string]: JobProcessConfig
}

export enum JobError {
  memory_overhead = 'memory_overhead',
  low_available_memory = 'low_available_memory',
  system_shutdown = 'system_shutdown',
}

export type JobProcessState = {
  name: string
  state: string
  updated_date: string
  data: {
    scheduled_run_time: string
    exception?: {
      class: string
      description: string
      trace: string
    }
    error?: JobError
  }
  has_public_log: boolean
}

export enum StateValue {
  Succeeded = 'succeeded',
  Failed = 'failed',
  Running = 'running',
}
