import { capitalize } from './functions'
import { ScanState, SignalVerdict, TagType } from '../types'
import { castVerdictToStrength } from './signal-type'

// tags groups
const tagsInformational = ['autoit', 'adware', 'delphi', 'macros', 'obfuscated', 'packed']
const tagsSuspicious = [
  'anti-debug',
  'anti-security',
  'anti-vm',
  'exploit',
  'fingerprint',
  'greyware',
  'hacktool',
  'keylogger',
  'overlay',
  'pos',
  'pup',
]
const tagsLikelyMalicious = [
  'apt',
  'backdoor',
  'banker',
  'botnet',
  'crimeware',
  'embedequation',
  'evasive',
  'macros-on-change',
  'macros-on-close',
  'macros-on-open',
  'phishing',
  'ransomware',
  'rat',
  'spyware',
  'stealer',
  'worm',
  'asyncrat',
  'njrat',
  'quasarrat',
  'nanocore',
  // TODO: we need to add capability for wildcards
]

/**
 * @param opacity opacity in dec 0.1...1
 * @returns opacity in hex 0...255
 */
const getOpacityHex = (opacity: number): string => (opacity === 1 ? '' : Math.round(opacity * 255).toString(16))

export function getTagVerdict(tag: TagType): SignalVerdict {
  const name = tag.tag.name
  const verdict = tag.tag.verdict
  if (verdict) {
    const signal = capitalize(verdict.verdict, true)
    return SignalVerdict[signal as keyof typeof SignalVerdict]
  } else {
    if (tag.source === 'OSINT_LOOKUP') {
      return SignalVerdict.Malicious
    }

    if (tagsLikelyMalicious.includes(name)) {
      return SignalVerdict.Likely_malicious
    }

    if (tag.source === 'YARA_RULE' || tagsSuspicious.includes(name)) {
      return SignalVerdict.Suspicious
    }

    if (tagsInformational.includes(name)) {
      return SignalVerdict.No_threat
    }
  }

  return SignalVerdict.Unknown
}

/**
 * Get color code for signal verdict
 */
export function getVerdictColor(verdict: SignalVerdict, opacity = 1): string {
  const strength = castVerdictToStrength(verdict)

  return getStrengthColor(strength, opacity)
}

/**
 * Get color code for signal verdict text
 */
export const getVerdictTextColor = (verdict: SignalVerdict): string =>
  verdict === SignalVerdict.Likely_malicious || verdict === SignalVerdict.Suspicious ? 'black' : 'white'

/**
 * Build css color to style signal strength
 *
 * @param {float} strength
 */
export function getStrengthColor(strength: number, opacity = 1): string {
  const opacityHex = getOpacityHex(opacity)

  if (strength < 0) {
    return `#1d6bfc${opacityHex}` //benign (blue)
  } else if (strength === 0) {
    return `#333c4a${opacityHex}` //unknown (grey)
  } else if (strength <= 0.25) {
    return `#008a00${opacityHex}` //no threat / informational (deprecated)
  } else if (strength <= 0.5) {
    return `#fdbd0d${opacityHex}` //suspicious
  } else if (strength <= 0.75) {
    return `#ed6706${opacityHex}` //likely malicious
  } else {
    return `#d00300${opacityHex}` //malicious (dark red)
  }
}

/**
 * Build css color to style report state
 */
export function getStateColor(state: ScanState): string | undefined {
  if (state === ScanState.Success) {
    return `rgba(50, 120, 0, 1)`
  } else if (state === ScanState.SuccessPartial) {
    return `rgba(50, 120, 0, .8)`
  } else if (state === ScanState.Failed) {
    return `rgba(200, 35, 0, 1)`
  } else if (state === ScanState.FailedPartial) {
    return `rgba(200, 35, 0, .8)`
  } else if (state === ScanState.InProgress) {
    return `rgba(124, 77, 255, 1)`
  } else if (state === ScanState.InQueue) {
    return `rgba(121, 134, 203, 1)`
  }
}

export function getVerdictClass(verdict: SignalVerdict | undefined) {
  return verdict ? 'verdict-' + verdict : ''
}
