/**
 * Admin module feature
 */
export enum AdminFeature {
  Usage = 'usage',
  UserManagement = 'user-management',
  Settings = 'settings',
  Stat = 'stat',
  Jobs = 'jobs',
  ScanSources = 'scan-sources',
  AuditLogger = 'audit-logger',
}
