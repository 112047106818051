import { matchRoutes, useLocation } from 'react-router'
import { pagesForMatching } from '../routing/pages-data'

export * from './use-url-search-param'
export * from './use-url-search-params'
export * from './use-paged-data'

// Get path template of route, that matches current location
export function useRouteMatch(): string {
  const location = useLocation()
  const matchedPage = matchRoutes(pagesForMatching, location)
  const pathTmpl = matchedPage?.length ? matchedPage[0].route.path : null

  if (!pathTmpl) {
    throw Error('Can not match any route with given location')
  }

  return pathTmpl
}
