import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { TFunction } from 'i18next'
import { Placement } from 'react-bootstrap/esm/types'
import { ScanProfileOptions } from 'app/modules/user/features/profile/types'

export function HelpIcon({ tooltipId, note, placement }: { tooltipId: string; note: string; placement?: Placement }) {
  return (
    <OverlayTrigger placement={placement || 'right'} overlay={<Tooltip id={tooltipId}>{note}</Tooltip>}>
      {({ ref, ...triggerHandler }) => (
        <i
          {...triggerHandler}
          ref={ref}
          className="ds3-icon ds3-information icon-button text-muted-light fa-2 ms-1 font-size-15"
        ></i>
      )}
    </OverlayTrigger>
  )
}

export function getAdvancedOptionsFields(_t: TFunction) {
  return [
    { label: _t('early-termination'), note: _t('early-termination-note'), name: 'early_termination' },
    { label: _t('input-file-yara'), note: _t('input-file-yara-note'), name: 'input_file_yara' },
    { label: _t('extracted-files-yara'), note: _t('extracted-files-yara-note'), name: 'extracted_files_yara' },
    { label: _t('file-visualization'), note: _t('file-visualization-note'), name: 'visualization' },
    { label: _t('files-download'), note: _t('files-download-note'), name: 'files_download' },
    { label: _t('ocr-qr'), note: _t('ocr-qr-note'), name: 'ocr_qr' },
    { label: _t('extract-strings'), note: _t('extract-strings-note'), name: 'extract_strings' },
    { label: _t('certificates'), note: _t('certificates-note'), name: 'certificates' },
    { label: _t('osint-lookups'), note: _t('osint-lookups-note'), name: 'osint' },
    { label: _t('extended-osint-lookups'), note: _t('extended-osint-lookups-note'), name: 'extended_osint' },
    {
      label: _t('extracted-files-osint-lookups'),
      note: _t('extracted-files-osint-lookups-note'),
      name: 'extracted_files_osint',
    },
    { label: _t('url-analysis'), note: _t('url-analysis-note'), name: 'url_analysis' },
    { label: _t('phishing-detection'), note: _t('phishing-detection-note'), name: 'phishing_detection' },
  ]
}

export function getAvailableOptionsDefaults(
  allDefaults: ScanProfileOptions,
  availableOptions: ScanProfileOptions | undefined
) {
  const result: any = {}

  Object.keys(allDefaults).forEach((name) => {
    const key = name as keyof ScanProfileOptions
    const isAllowed = typeof availableOptions === 'undefined' || availableOptions[key]

    if (isAllowed) {
      result[name] = allDefaults[key]
    }
  })

  return result
}
