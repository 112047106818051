import React, { forwardRef, memo } from 'react'

interface Props {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const Gutter = ({ children = <>&nbsp;</>, className, style }: Props, ref: React.Ref<HTMLDivElement>) => (
  <div className={className} ref={ref} style={style}>
    {children}
  </div>
)

export default memo(forwardRef(Gutter))
