import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from './components'
import { initPages } from './modules/shared/routing/pages-data'
import { To } from './modules/shared/routing/render-page'
import { AppWrappers } from './components/app-wrappers'
import './modules/shared/events/register-events'
import './app.scss'
import NotFoundPage from './components/errors/not-found'

// Set routing options for all pages
initPages()

export const App = (): JSX.Element => {
  return (
    <AppWrappers>
      <Router>
        <ErrorBoundary>
          {/* Switch for routes. Routes should be immediate kids of it. */}
          <Routes>
            {/* Admin */}
            <Route {...To('admin-home')} />
            <Route {...To('admin-user-management-home')} />
            <Route {...To('admin-user-management')} />
            <Route {...To('admin-user-management-item')} />
            <Route {...To('admin-settings-home')} />
            <Route {...To('admin-settings')} />
            <Route {...To('admin-stat-home')} />
            <Route {...To('admin-stat')} />
            <Route {...To('admin-jobs-home')} />
            <Route {...To('admin-jobs')} />
            <Route {...To('admin-scan-sources-home')} />
            <Route {...To('admin-scan-sources')} />
            <Route {...To('admin-audit-logger-home')} />
            <Route {...To('admin-audit-logger')} />
            <Route {...To('admin-setup')} />

            {/* Auth */}
            <Route {...To('first-signup-redirect')} />
            <Route {...To('signup-redirect')} />
            <Route {...To('invite-signup-redirect')} />
            <Route {...To('sign-up')} />
            <Route {...To('first-sign-up')} />
            <Route {...To('sign-up-invite')} />
            <Route {...To('sign-up-confirm')} />
            <Route {...To('sign-in')} />
            <Route {...To('external-auth-redirect')} />
            <Route {...To('request-password-reset')} />
            <Route {...To('reset-password')} />

            {/* User related */}
            <Route {...To('user-profile')} />

            {/* Report related */}
            <Route {...To('home')} />
            <Route {...To('scan')} />
            <Route {...To('upload-initial-page')} />
            <Route {...To('report-overview-redirect')} />
            <Route {...To('report-new-overview-redirect')} />
            <Route {...To('report')} />
            <Route {...To('report-new')} />
            <Route {...To('my-reports')} />
            <Route {...To('my-downloads')} />
            <Route {...To('all-reports')} />
            <Route {...To('search-result')} />
            <Route {...To('advanced-search')} />
            <Route {...To('threats-overview')} />
            <Route {...To('trends')} />
            <Route {...To('leaders')} />

            {/* Help pages */}
            <Route {...To('help')} />
            <Route {...To('status')} />
            <Route {...To('terms')} />
            <Route {...To('privacy')} />
            <Route {...To('cookie-policy')} />
            <Route {...To('feed')} />
            <Route {...To('yara-feed')} />
            <Route {...To('archives')} />
            <Route {...To('yara-feed')} />
            <Route {...To('yara-archives')} />

            {/* Custom error pages */}
            <Route {...To('quota-exceeded')} />

            {/* Not found, should be the last route */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ErrorBoundary>
      </Router>
    </AppWrappers>
  )
}

export default App
