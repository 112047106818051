import React from 'react'
import { LogoImageType } from 'app/modules/admin/features/settings/appearence/types'
import { applicationConfiguration } from 'app/modules/shared'
import { useTheme } from 'app/modules/shared/context/theme-context'
import { useContext } from 'react'
import { apiRoutes } from 'app/modules/shared/routing'
import { VisualMode } from 'app/modules/shared/types/theme'

type LogoImageParams = {
  type?: LogoImageType
  name?: string
  width?: string
  height?: string
  className?: string
  theme?: VisualMode
  style?: { [key: string]: any }
  title?: string
  onClick?: CallableFunction
}

export function LogoImage({ type, theme, name, width, height, className, style, title, onClick }: LogoImageParams) {
  const config = useContext(applicationConfiguration)
  const globalTheme = useTheme().mode
  theme = theme || globalTheme

  if (!type && !name) {
    throw Error('Logo component should receive either `type` or `name`')
  }

  let url
  if (name) {
    url = apiRoutes.getLogoByFilename.replace('{name}', encodeURIComponent(name))
  } else {
    const selectedDate =
      config.logoSelectedTime[theme][type as LogoImageType] ??
      config.logoSelectedTime[config.defaultTheme][type as LogoImageType]
    url = apiRoutes.getLogoByType.replace('{theme}', theme).replace('{type}', type as string)

    if (selectedDate) {
      url += '&time=' + new Date(selectedDate).getTime()
    }
  }

  return (
    <img
      src={url}
      alt=""
      className={className || ''}
      style={style}
      width={width}
      height={height}
      title={title}
      onClick={onClick as any}
    />
  )
}
