import React, { MouseEvent, useContext } from 'react'
import { getUpdateModalCallback } from '../../../components/modal'
import { ModalWrapperType } from '../../shared/types'
import { modalContext } from '../../shared'
import { Copy2Clipboard } from './copy-to-clipboard'

const LENGTH_UPPER_LIMIT = 100
const LENGTH_SHOW = 60

/**
 * Display long string in a convinient way:
 * - show only start of it
 * - add button to show modal with full string
 *
 * @param value
 * @param modalWrapper
 */
type LongTextProps = {
  value: string
  copyable: boolean
  allowViewAll?: boolean
  limit: number
  className?: string
}
export function LongText(props: LongTextProps): JSX.Element {
  const { value, copyable, limit, allowViewAll, className } = props
  const modalWrapper = useContext(modalContext)
  const showModalButton = typeof allowViewAll === 'undefined' || allowViewAll === true

  if (!value) {
    return <span />
  }

  if (!isLongString(value, limit)) {
    return (
      <div className={'d-inline-block ' + (className || '')}>
        <span className="text-break-all">{value}</span>
        {copyable && buildCopyButton(value)}
      </div>
    )
  }

  const [shortValue, cutDescription] = cutLongString(value, limit)
  return (
    <span className={'long-string ' + (className || '')}>
      <span className="text-break-all">{shortValue}</span>
      {cutDescription}
      {showModalButton && buildModalButton(value, modalWrapper)}
      {copyable && buildCopyButton(value)}
    </span>
  )
}

export function buildLongText(
  value: string,
  modalWrapper: ModalWrapperType,
  copyable = true,
  limit = 0
): string | JSX.Element {
  if (!value) return ''
  if (!isLongString(value, limit)) {
    return value
  }

  const [shortValue, cutDescription] = cutLongString(value, limit)
  const modalButton = buildModalButton(value, modalWrapper)
  const copyButton = copyable ? buildCopyButton(value) : null

  return (
    <span className="long-string ellipsis">
      <span>{shortValue}</span>
      {cutDescription}
      {modalButton}
      {copyButton}
    </span>
  )
}

LongText.defaultProps = {
  copyable: true,
  limit: 0,
}

function buildHighlightText(value: string, segments: string[], search: string): Array<string | JSX.Element> {
  const result = []
  const length = value.length
  let curLen = 0
  if (segments[0].length < length) {
    result.push(segments[0])
    curLen = segments[0].length
  } else {
    result.push(segments[0].substring(0, length))
    return result
  }

  for (let i = 1; i < segments.length; i++) {
    if (search.length + curLen < length) {
      curLen += search.length
      result.push(<span className="highlight">{search}</span>)
    } else {
      result.push(<span className="highlight">{search.substring(0, length - curLen)}</span>)
      break
    }

    const seg = segments[i]
    if (seg.length + curLen < length) {
      curLen += search.length
      result.push(seg)
    } else {
      result.push(seg.substring(0, length - curLen))
      break
    }
  }

  return result
}

type HighlightLongTextProps = {
  value: string
  copyable: boolean
  limit: number
  search: string
  allowViewAll?: boolean
  className?: string
}
export function HighlightLongText(props: HighlightLongTextProps): JSX.Element {
  const { value, copyable, limit, search, allowViewAll, className } = props
  const modalWrapper = useContext(modalContext)

  if (!value) {
    return <span />
  }
  if (!search) {
    return (
      <LongText value={value} copyable={copyable} limit={limit} allowViewAll={allowViewAll} className={className} />
    )
  }

  const segments = value.split(search)
  if (!isLongString(value, limit)) {
    return (
      <div className={'d-inline-block ' + (className || '')}>
        <span className="text-break-all">
          {segments[0]}
          {segments.slice(1).map((segment, idx) => (
            <React.Fragment key={idx}>
              <span className="highlight">{search}</span>
              {segment}
            </React.Fragment>
          ))}
        </span>
        {copyable && buildCopyButton(value)}
      </div>
    )
  }

  const [shortValue, cutDescription] = cutLongString(value, limit)
  const content = buildHighlightText(shortValue as string, segments, search)

  return (
    <span className={'long-string ellipsis ' + (className || '')}>
      <span className="text-break-all">{content}</span>
      {cutDescription}
      {allowViewAll && buildModalButton(value, modalWrapper)}
      {copyable && buildCopyButton(value)}
    </span>
  )
}

/**
 * Determine if string is long enough to apply this component
 * @param value
 */
function isLongString(value: string, limit = 0) {
  if (limit === -1) {
    return false
  }
  if (limit >= 0) {
    return value.length > limit
  }

  return value.length - LENGTH_UPPER_LIMIT > 40
}

/**
 * Cut long string
 *
 * @param value
 */
function cutLongString(value: string, limit: number) {
  const initialLength = value.length
  value = value.substr(0, limit ? limit : LENGTH_SHOW)

  const cutDescription = <span className="cut-description">... ({initialLength})</span>

  return [value, cutDescription]
}

/**
 * Build button component to show modal with full string
 * @param value
 * @param modalWrapper
 */
export function buildModalButton(value: string, modalWrapper: ModalWrapperType): JSX.Element {
  const hideModal = getUpdateModalCallback(modalWrapper, { show: false })
  const showModal = getUpdateModalCallback(modalWrapper, {
    show: true,
    title: 'Full value',
    body: (
      <div className="full-long-string-container extracted-code">
        {value}
        {buildCopyButton(value)}
      </div>
    ),
    props: {
      onHide: hideModal,
    },
  })

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()
    showModal()
  }

  return (
    <i
      className="ds3-icon ds3-eye icon-button icon-show-long-string align-middle"
      onClick={handleClick}
      aria-label="Show modal"
    ></i>
  )
}

function buildCopyButton(value: string) {
  return <Copy2Clipboard copyText={value} />
}

export function cutoffString(value: string, limit = 32): string {
  if (value.length < limit) {
    return value
  } else {
    return value.substr(0, limit) + ' ...'
  }
}
