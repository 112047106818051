import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

/**
 * Show loader as the only element on the page, possibly with message
 */
export default function PageLoader({
  message,
  style,
}: {
  message?: JSX.Element | string | undefined
  style?: { [key: string]: any } | undefined
}): JSX.Element {
  const messageComponent = typeof message === 'string' ? <p>{message}</p> : message ?? null

  return (
    <div className="page-loader" style={style}>
      <div className="spinner-container">
        <Spinner animation="border" variant="primary" />
      </div>
      {messageComponent}
    </div>
  )
}

export function SmallPageLoader({
  message,
  className,
  style,
}: {
  message?: string | undefined
  className?: string | undefined
  style?: { [key: string]: any } | undefined
}): JSX.Element {
  return (
    <div className={'small-page-loader ' + (className || '')}>
      <PageLoader message={message} style={style} />
    </div>
  )
}

export function ReportScanSpinner({ message }: { message?: JSX.Element | string | undefined }) {
  const messageComponent = typeof message === 'string' ? <p className="text-center">{message}</p> : message ?? null

  return (
    <div className="report-scan-spinner">
      <div className="spinner-container">
        <i className="owl-head spinner-central-icon"></i>
        <div className="report-spinner-image">
          <svg>
            <circle className="outer-circle base-form thick" cx="50%" cy="50%" r="60"></circle>
            <circle className="outer-circle base-form medium" cx="50%" cy="50%" r="60"></circle>
            <circle className="outer-circle base-form thin" cx="50%" cy="50%" r="60"></circle>
            <circle className="outer-circle base-form thick-rev" cx="50%" cy="50%" r="60"></circle>
            <circle className="outer-circle base-form medium-rev" cx="50%" cy="50%" r="60"></circle>
            <circle className="outer-circle base-form thin-rev" cx="50%" cy="50%" r="60"></circle>
            <circle className="middle-circle base-form thick" cx="50%" cy="50%" r="50"></circle>
            <circle className="middle-circle base-form medium" cx="50%" cy="50%" r="50"></circle>
            <circle className="middle-circle base-form thin" cx="50%" cy="50%" r="50"></circle>
            <circle className="middle-circle base-form thick-rev" cx="50%" cy="50%" r="50"></circle>
            <circle className="middle-circle base-form medium-rev" cx="50%" cy="50%" r="50"></circle>
            <circle className="middle-circle base-form thin-rev" cx="50%" cy="50%" r="50"></circle>
            <circle className="inner-circle base-form thick" cx="50%" cy="50%" r="40"></circle>
            <circle className="inner-circle base-form medium" cx="50%" cy="50%" r="40"></circle>
            <circle className="inner-circle base-form thin" cx="50%" cy="50%" r="40"></circle>
            <circle className="inner-circle base-form thick-rev" cx="50%" cy="50%" r="40"></circle>
            <circle className="inner-circle base-form medium-rev" cx="50%" cy="50%" r="40"></circle>
            <circle className="inner-circle base-form thin-rev" cx="50%" cy="50%" r="40"></circle>
          </svg>
        </div>
      </div>
      {messageComponent}
    </div>
  )
}
