import React, { createContext, useContext, useEffect, useState } from 'react'
import { mainNotificationContext } from '../context/main-notification-context'
import { useTypedTranslation } from '../hooks/use-translation'
import { AssetsService } from '../services/assets.service'
import { AssetsType, MitreData } from '../types/assets'

export const assetsContext = createContext<AssetsType>({} as AssetsType)

/**
 * You can add here various fetchings from server to cache generic assets
 */
export const WithAssetsContext = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element | null => {
  const { _t } = useTypedTranslation()
  const mainNotification = useContext(mainNotificationContext)
  const [mitre, setMitre] = useState<MitreData | undefined>()
  const [error, setError] = useState('')

  async function loadAssets() {
    try {
      const mitre = await AssetsService.getMitreData()
      setMitre(mitre)
    } catch (error: any) {
      setError(error.message)
    }
  }

  useEffect(() => {
    loadAssets()
  }, [])

  useEffect(() => {
    error && mainNotification.setMessageData(_t('mitre-load-failed'), 'danger')
  }, [error])

  const provided: AssetsType = {
    mitre: mitre,
  }

  return <assetsContext.Provider value={provided}>{children}</assetsContext.Provider>
}
