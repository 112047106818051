import React, { useRef, useEffect, useState } from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import { Popper } from 'react-popper'

const AppbarDropdown: React.FC<{
  title: string | JSX.Element
  children: JSX.Element | (JSX.Element | null)[]
  className?: string
}> = ({ title, children, className }) => {
  const [isOpen, setIsOpen] = useState(false)
  const referenceElement = useRef<HTMLDivElement>(null)

  const getChevronElement = () => {
    if (typeof title !== 'string') {
      return null
    }

    return isOpen ? <i className="ds3-icon ds3-chevron-up ms-2" /> : <i className="ds3-icon ds3-chevron-down ms-2" />
  }

  useEffect(() => {
    if (isOpen) {
      const handleScroll = () => {
        setIsOpen(false)
      }

      document.body.addEventListener('scroll', handleScroll)

      return () => {
        document.body.removeEventListener('scroll', handleScroll)
      }
    }
  }, [isOpen])

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      {/* Desktop Screen */}
      <div className={`hide-mobile appbar-item-wrapper ${className}`} onClick={handleToggle}>
        <Nav.Link
          as="div"
          ref={referenceElement}
          className={`appbar-dropdown-toggle appbar-item ${typeof title !== 'string' ? 'ds3-hover' : ''}`}
        >
          {title}
          {getChevronElement()}
        </Nav.Link>
        {isOpen && (
          <Popper
            placement="bottom-end"
            referenceElement={referenceElement.current!}
            modifiers={[
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['bottom', 'bottom-start'],
                },
              },
            ]}
          >
            {({ ref, style, placement }) => (
              <div ref={ref} style={style} data-placement={placement} className="dropdown-menu show hide-mobile">
                {children}
              </div>
            )}
          </Popper>
        )}
      </div>
      {/* Tablet/Mobile Screen */}
      <NavDropdown
        title={title}
        show={isOpen}
        onToggle={handleToggle}
        className={`w-100 appbar-dropdown-wrapper ${
          isOpen ? 'open-dropdown' : ''
        } ${className}  hide-desktop overflow-auto`}
      >
        {children}
      </NavDropdown>
    </>
  )
}

export default AppbarDropdown
