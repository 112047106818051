import { HttpService } from '../../shared/services'
import { apiRoutes } from '../../shared/routing/api-routes'
import _ from 'lodash'
import { SignalVerdict } from '../types'

export const submissionsService = {
  getSubmissions: (params: { [key: string]: any }): Promise<any> => {
    return HttpService.get(apiRoutes.uploads, { params: { page: params.page, page_size: params.pageSize } })
  },
  getFakeSubmissions: ({ page, pageSize }: any): Promise<any> => {
    const verdicts = [
      SignalVerdict.Benign,
      SignalVerdict.No_threat,
      SignalVerdict.Suspicious,
      SignalVerdict.Malicious,
      SignalVerdict.Unknown,
    ]
    return new Promise((resolve) => {
      setTimeout(() => {
        const items = _.range((page - 1) * pageSize, page * pageSize).map((idx) => ({
          id: `scanned_file-${idx}`,
          file: {
            name: `filename-${idx}`,
            mime_type: `mime_type-${idx}`,
            short_type: `short_type-${idx}`,
            sha256: `sha256-${idx}`,
          },
          scan_init: {
            id: `scan_init-${idx}`,
          },
          verdict: `${verdicts[Math.floor(_.random(4))]}`,
          date: new Date().toTimeString(),
          state: 'success',
        }))

        resolve({
          count: 1000,
          items,
          method: 'and',
        })
      }, 1000)
    })
  },
}
