import React, { useContext } from 'react'
import { Tab } from 'react-bootstrap'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { Tabs } from '../tabs'
import { BaseUploadOptions } from './types'
import { BaseScanOptions } from './base-options'
import { ExtendedScanOptions } from './extended-options'
import { allowedFeaturesStorage } from 'app/modules/auth/helper/storage'
import { Feature } from 'app/modules/auth/types'
import { applicationConfiguration } from '../../../shared'
import { ScanProfile, ScanProfileOptions } from 'app/modules/user/features/profile/types'

type UploadOptionsParams = {
  baseOptions: BaseUploadOptions
  extendedOptions: ScanProfileOptions
  setBaseOptions: CallableFunction
  setExtendedOptions: CallableFunction
  scanProfiles: ScanProfile[]
  activeScanProfile: string | undefined
  setActiveScanProfile: CallableFunction
  availableOptions: ScanProfileOptions | undefined
  error: string | undefined
  setError: CallableFunction
  activeTab: string
  setActiveTab: CallableFunction
}

export function UploadOptions({
  baseOptions,
  extendedOptions,
  setBaseOptions,
  setExtendedOptions,
  error,
  setError,
  activeTab,
  setActiveTab,
  scanProfiles,
  activeScanProfile,
  setActiveScanProfile,
  availableOptions,
}: UploadOptionsParams) {
  const { _t } = useTypedTranslation()
  const config = useContext(applicationConfiguration)
  const features = allowedFeaturesStorage.getFeatures()
  const isAdvancedScanningAllowed = features && features.indexOf(Feature.Advanced_scanning) !== -1

  return (
    <Tabs onSelect={(key: string) => setActiveTab(key)} activeKey={activeTab}>
      <Tab title={_t('base-options')} eventKey="base-options" tabClassName={error ? 'with-badge red-badge' : ''}>
        <p className="text-muted mb-3">
          <i>{_t('base-scan-options-note')}</i>
        </p>

        <BaseScanOptions data={baseOptions} setData={setBaseOptions} error={error} setError={setError} />
      </Tab>

      {config.buildType === 'community' || isAdvancedScanningAllowed ? (
        <Tab title={_t('extended-options')} eventKey="extended-options">
          <ExtendedScanOptions
            data={extendedOptions}
            setData={setExtendedOptions}
            disabled={!isAdvancedScanningAllowed}
            scanProfiles={scanProfiles}
            activeScanProfile={activeScanProfile}
            setActiveScanProfile={setActiveScanProfile}
            availableOptions={availableOptions}
          />
        </Tab>
      ) : null}
    </Tabs>
  )
}
