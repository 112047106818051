// Use enum for fields' keys to easily allow dinamic fields assignement
// in TS (just cast dinamic key to enum type)
export enum WhoisDataField {
  Domain = 'domain',
  Provider = 'provider',
  WhoisRecord = 'whoisRecord',
  DomainRegistration = 'domainRegistration',
  DomainInfo = 'domainInfo',
  Admins = 'admins',
  Techs = 'techs',
}

export type WhoisData = {
  [WhoisDataField.Domain]: string
  [WhoisDataField.Provider]: string
  [WhoisDataField.WhoisRecord]?: {
    CreatedDate?: string
    UpdatedDate?: string
    ExpiresDate?: string
    DatabaseUpdatedDate?: string
  }
  [WhoisDataField.DomainRegistration]?: {
    Registrar?: { [key: string]: any }
    Registrant?: { [key: string]: any }
  }
  [WhoisDataField.DomainInfo]?: {
    Domain?: { [key: string]: any }
  }
  [WhoisDataField.Admins]?: { [key: string]: any }[]
  [WhoisDataField.Techs]?: { [key: string]: any }[]
}
