import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { useLocation } from 'react-router'
import AppbarDropdown from './appbar-dropdown'
import { getActiveRouteClass } from './helpers/helpers'
import { getPageUrl } from 'app/modules/shared/routing'
import { Link } from 'react-router-dom'
import { Feature } from 'app/modules/auth/types'
import { allowedFeaturesStorage } from 'app/modules/auth/helper/storage'

const Feed = (): JSX.Element => {
  const { _t } = useTypedTranslation()
  const currentRoute = useLocation().pathname.substring(1)
  // Get class for the menu items
  const getActiveMenuClass = (activeRoutes: string[]) =>
    getActiveRouteClass(activeRoutes, currentRoute, 'appbar-dropdown-item-selected')
  const allowedFeatures = allowedFeaturesStorage.getFeatures() || []
  const isYaraFeedAllowed = allowedFeatures.indexOf(Feature.Yara_feed) !== -1
  const isYaraArchivesAllowed = allowedFeatures.indexOf(Feature.Yara_daily_feed) !== -1

  return (
    <AppbarDropdown
      // Get class for the dropdown label
      className={getActiveRouteClass(['feed', 'archives', 'yara-feed'], currentRoute, 'appbar-dropdown-selected')}
      title={_t('feed')}
    >
      <Link
        className={`appbar-dropdown-menu-item dropdown-item ${getActiveMenuClass(['feed/reports'])}`}
        to={getPageUrl('feed') as string}
      >
        {_t('latest-reports-xml')}
      </Link>
      <NavDropdown.Divider className="hide-desktop m-0" />
      {isYaraFeedAllowed ? (
        <Link
          className={`appbar-dropdown-menu-item dropdown-item ${getActiveMenuClass(['feed/yara'])}`}
          to={getPageUrl('yara-feed') as string}
        >
          {_t('latest-yara-xml')}
        </Link>
      ) : null}
      <Link
        className={`appbar-dropdown-menu-item dropdown-item ${getActiveMenuClass(['archives/samples'])}`}
        to={getPageUrl('archives') as string}
      >
        {_t('daily-samples')}
      </Link>
      {isYaraArchivesAllowed ? (
        <Link
          className={`appbar-dropdown-menu-item dropdown-item ${getActiveMenuClass(['archives/yara'])}`}
          to={getPageUrl('yara-archives') as string}
        >
          {_t('daily-yara')}
        </Link>
      ) : null}
    </AppbarDropdown>
  )
}

export default Feed
