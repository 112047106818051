import BaseFormatter from './base'
import { TreeNode } from '../../types/tree'

/**
 * Format general report verdict
 */
export class UpdateEmulationFormatter extends BaseFormatter {
  /**
   * Update emulation data using geolocation
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    if (!report.geolocation || !report.emulation_data) {
      return
    }

    const emulation = report.emulation_data
    const locations = report.geolocation.map((item: any) => {
      const location: any = { domain: item.resource?.data }
      item.geoData?.ip && (location.ip = item.geoData.ip)
      item.geoData?.country_name && (location.region = item.geoData.country_name)
      item.geoData?.city && (location.region += `, ${item.geoData?.city}`)

      return location
    })

    this.update(locations, emulation.input?.tree)

    if (Array.isArray(emulation.extracted)) {
      for (const item of emulation.extracted) {
        this.update(locations, item.tree)
      }
    }

    if (Array.isArray(emulation.downloaded)) {
      for (const item of emulation.downloaded) {
        this.update(locations, item.tree)
      }
    }
  }

  update(locations: any[], tree?: TreeNode): void {
    if (!tree) return

    if (tree.type === 'network') {
      if (tree.extra.URI) {
        const hostname = this.getHostname(tree.extra.URI)
        const location = locations.find((value: any) => hostname === value.ip || hostname === value.domain)

        if (location) {
          if (!tree.original.additionalInformation) {
            tree.original.additionalInformation = {}
          }

          tree.original.additionalInformation.ip = location.ip
          tree.original.additionalInformation.region = location.region
        }
      }
    }

    for (const item of tree.childs) {
      this.update(locations, item)
    }
  }

  getHostname(uri: string): string {
    try {
      const { hostname } = new URL(uri)
      return hostname
    } catch (e) {
      let hostname = uri.replace(/^https?:\/\//, '')
      const slashPos = hostname.indexOf('/')
      if (slashPos > 0) {
        hostname = hostname.substring(0, slashPos)
      }

      const colonPos = hostname.indexOf(':')
      if (colonPos > 0) {
        return hostname.substring(0, colonPos)
      } else {
        return hostname
      }
    }
  }
}
