import BaseFormatter from './base'
import { has, formatEnumItem } from '../../helpers'
import { SignalVerdict, VerdictData } from '../../types'

/**
 * Format general report verdict
 */
export default class VerdictFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    if (!has(data, 'finalVerdict')) {
      return
    }

    let originalVerdict
    if (data.verdictHistory && data.verdictHistory.length) {
      originalVerdict = data.verdictHistory[0].verdict
    }

    const fileResource = this.getResource(data, 'file')
    const verdict: VerdictData = {
      name: data.finalVerdict.verdict.toLowerCase() as SignalVerdict,
      original: originalVerdict ? (formatEnumItem(originalVerdict) as SignalVerdict) : undefined,
      confidence: data.finalVerdict.confidence,
      threatLevel: data.finalVerdict.threatLevel,
      interestingScore: data.interestingScore,
    }
    if (fileResource?.verdict?.comment) {
      verdict.comment = fileResource.verdict.comment
    }

    report.verdict = verdict
  }
}
