export type EmulationProcessType = {
  Program: string
  Command: string
  ParentPid: string
}

export type ProcessListType = {
  [pid: string]: EmulationProcessType
}

export type EmulationFilterStat = {
  types: {
    category: { [key: string]: number }
    type: { [key: string]: number }
    source: { [key: string]: number }
  }
  total: number
}

export enum EmulationCategory {
  Execution = 'Execution',
  FileManipulation = 'FileManipulation',
  Network = 'Network',
  DecisionPoint = 'DecisionPoint',
  OtherEventsAndActions = 'OtherEventsAndActions',
}

export type EmulationItem = {
  action: string
  source: string
  category: string
  commandLowerForFilter?: string
  [key: string]: any
}

export type EmulationFileData = {
  data: EmulationItem[]
  exitCode: number
  meta: { [key: string]: any }
  title?: string
  tree: { [key: string]: any }
}
