import React from 'react'
import { routeState } from 'app/modules/shared'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { redirectAfterSignin } from 'app/modules/shared/events/handlers/signin-redirect'
import { getPageUrl } from 'app/modules/shared/routing/pages'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'

// Signin link, that supports getting back to current page after signin is done
export function SigninLink({
  className,
  children,
}: {
  className?: string
  children?: string | JSX.Element | JSX.Element[]
}) {
  const navigate = useNavigate()
  const { pathname: url } = useLocation()

  const pageState = useContext(routeState)
  const page = pageState.data.page
  const { _t } = useTypedTranslation()

  const redirectToSignin = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    if (url === getPageUrl('sign-in')) {
      return
    }

    redirectAfterSignin.setRedirect(page?.key as string, url)
    navigate(getPageUrl('sign-in') as string)
  }

  if (typeof className === 'undefined') {
    className = ''
  }

  if (typeof children === 'undefined') {
    children = _t('sign-in')
  }

  return (
    <a onClick={redirectToSignin} href="#" className={className}>
      {children}
    </a>
  )
}
