import React, { useContext, useEffect } from 'react'
import { mainNotificationContext } from '../modules/shared/context/main-notification-context'
import { Alert } from 'react-bootstrap'
import { isSimpleObject } from 'app/modules/reports/helpers'

export function MainNotification(): JSX.Element | null {
  const notificationContext = useContext(mainNotificationContext)
  const message = notificationContext.message
  const type = notificationContext.messageType
  const timeout = notificationContext.timeout

  function doClose() {
    notificationContext.setMessage('')
  }

  useEffect(() => {
    if (!message || !timeout) {
      return
    }

    const pause = setTimeout(() => {
      if (message) {
        doClose()
      }
    }, timeout)

    return () => clearTimeout(pause)
  }, [message, type, timeout])

  return message ? (
    <Alert variant={type} className="alert-fixed-top" onClose={() => doClose()} dismissible={true}>
      {Array.isArray(message)
        ? message.map((item) => {
            if (isSimpleObject(item) && !Array.isArray(item)) {
              return Object.keys(item).map((key) => (
                <div>
                  <strong>{key}</strong>: {item[key as keyof typeof item]}
                </div>
              ))
            }

            return <div>{item}</div>
          })
        : message}
    </Alert>
  ) : null
}
