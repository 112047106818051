export interface ModalStateData {
  [key: string]: any
}

export class ModalState {
  title: JSX.Element | string | null = null
  show = false
  body: JSX.Element | null = null
  footer: JSX.Element | null = null
  props: { [key: string]: string | number | boolean | JSX.Element | null } = {
    size: 'lg',
    centered: true,
    dialogClassName: null,
    titleClassName: null,
  }
}

export interface ModalWrapperType {
  state: ModalStateData
  setState: CallableFunction
}
