import React, { useRef, useState } from 'react'
import { Popper } from 'react-popper'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import TextInput from './text-input'
import { Dropdown } from 'react-bootstrap'
import OutsideClickHandler from 'react-outside-click-handler'

// TODO: add uncontrolled behaviour
const DropdownSelect = ({
  value,
  children,
  items,
  onClick,
  className,
  style,
  id,
  asDiv,
  disabled,
}: {
  value?: string
  children?: JSX.Element | JSX.Element[]
  items?: { value: string | number; name?: string | number }[]
  onClick?: (value: string) => void
  className?: string
  style?: React.CSSProperties
  id?: string
  asDiv?: boolean
  disabled?: boolean
}) => {
  const [isOpen, setOpen] = useState(false)
  const { _t } = useTypedTranslation()
  const referenceElement = useRef<HTMLDivElement>(null)
  const chevronId = id ? id + '-chevron' : undefined
  const divValueId = id ? id + '-value' : undefined

  const handleToggle = (e: React.MouseEvent<HTMLElement>) => {
    !disabled && setOpen(!isOpen)
  }

  const chevronIcon = (
    <i
      id={chevronId}
      className={'ds3-icon ' + (isOpen ? 'ds3-chevron-up' : 'ds3-chevron-down')}
      onClick={handleToggle}
    />
  )

  return (
    <div
      className={'dropdown-container ' + (className || '')}
      onClick={handleToggle}
      ref={referenceElement}
      style={style}
    >
      {asDiv ? (
        <div className="d-flex value-container" id={id}>
          <div className="ps-3 pe-2 value" id={divValueId}>
            {value}
          </div>
          {chevronIcon}
        </div>
      ) : (
        <TextInput
          id={id}
          value={value}
          onChange={() => {}}
          placeholder={_t('select-option')}
          className="dropdown-input"
          postfix={chevronIcon}
          disabled={disabled}
        />
      )}
      {isOpen && (
        <Popper
          placement={'bottom'}
          referenceElement={referenceElement.current!}
          modifiers={[
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom-end'],
              },
            },
          ]}
        >
          {({ ref, style, placement }) => (
            <OutsideClickHandler
              onOutsideClick={(e) => {
                const targetId = (e.target as HTMLElement).id
                if (isOpen && ![id, chevronId, divValueId].includes(targetId)) {
                  setOpen(false)
                }
              }}
            >
              <ul
                ref={ref}
                style={style}
                data-placement={placement}
                onClick={() => setOpen(false)}
                className={`dropdown-menu custom-dropdown-menu${isOpen ? ' show' : ''}`}
              >
                {items ? <DropdownItems items={items} onClick={onClick} active={value} /> : children}
              </ul>
            </OutsideClickHandler>
          )}
        </Popper>
      )}
    </div>
  )
}

function DropdownItems({
  items,
  onClick,
  active,
}: {
  items: { value: string | number; name?: string | number }[]
  onClick: undefined | ((value: string) => void)
  active: string | undefined
}) {
  return items.map((item, idx) => {
    const name = item.name || item.value
    const isActive = name + '' === active + ''

    return (
      <Dropdown.Item
        key={item.value}
        as="li"
        value={item.value}
        onClick={() => onClick && onClick(item.value + '')}
        className={isActive ? 'active' : undefined}
      >
        {name}
      </Dropdown.Item>
    )
  })
}

export default DropdownSelect
