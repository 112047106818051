import TextInput, { TextInputType } from 'app/components/text-input'
import React from 'react'

export type FormControlProps = {
  id?: string
  labelClass?: string
  label?: string | JSX.Element
  extendedLabel?: string | JSX.Element
  name?: string
  className?: string
  parentClass?: string
  type?: TextInputType
  value?: string | number
  onChange?: React.ChangeEventHandler
  required?: boolean
  autoComplete?: string
  error?: string
  placeholder?: string
  readOnly?: boolean
  note?: string | JSX.Element
  control?: JSX.Element
  disabled?: boolean
}

export const FormControl: React.FC<React.PropsWithChildren<FormControlProps>> = (props: FormControlProps) => {
  const {
    id,
    label,
    labelClass,
    error,
    type,
    parentClass,
    note,
    className,
    control,
    required,
    extendedLabel,
    ...others
  } = props

  const groupClass = 'form-control-container ' + (parentClass || 'mb-4')

  return (
    <div className={groupClass}>
      {label ? (
        <div className="d-flex justify-content-between align-items-center">
          <div className={'input-label ' + (labelClass || '')}>
            {label}
            {required ? <>&nbsp;*</> : null}
          </div>
          {extendedLabel ? <div className={`input-label w-100 d-flex justify-content-end`}>{extendedLabel}</div> : null}
          <div></div>
        </div>
      ) : null}
      {control ?? <TextInput type={type ?? 'text'} id={id} {...others} className={className} />}
      {note ? (
        <span className="text-muted">
          <small>{note}</small>
        </span>
      ) : null}
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  )
}
