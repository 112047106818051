import React from 'react'
import { hasFields, capitalize } from '../helpers'
import { CommonLabeledDataRow } from './labeled-data-row'

type ObjectToRowProps = {
  object: { [key: string]: any }
  renderItem: (key: string) => JSX.Element
}
export function ObjectToRow(props: ObjectToRowProps): JSX.Element {
  const { object, renderItem } = props

  return <div className="data-block">{Object.keys(object).map((key) => renderItem(key))}</div>
}

type ObjectDataRowsProps = {
  data?: { [key: string]: any }
  className?: string
  enableCopy: boolean
  checkHash: boolean
  lengthLimit: number
  showEmpty?: boolean
}
export function ObjectDataRows(props: ObjectDataRowsProps): JSX.Element | null {
  const { data, enableCopy, checkHash, lengthLimit, className, showEmpty } = props
  if (typeof data !== 'object' || !hasFields(data)) {
    return null
  }

  return (
    <div className={'data-block ' + (className ?? '')}>
      {Object.keys(data).map((key) => {
        const label = capitalize(key)
        const value = data[key]

        return (
          <CommonLabeledDataRow
            key={key}
            label={label}
            value={value}
            enableCopy={enableCopy}
            checkHash={checkHash}
            lengthLimit={lengthLimit}
            showEmpty={showEmpty}
          />
        )
      })}
    </div>
  )
}

ObjectDataRows.defaultProps = {
  enableCopy: false,
  checkHash: false,
  lengthLimit: 0,
}
