import BaseFormatter from './base'
import { has, humanFileSize } from '../../helpers'
import { logger } from 'app/components'

/**
 * Pass some fields to formatted report in unchanged form
 */
export default class DefaultFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const passFields = [
      'file',
      'job',
      'estimatedTime',
      'filesDownloadFinished',
      'additionalStepsRunning',
      'scanOptions',
      'defaultOptionsUsed',
      'chatGptSummary',
      'replaceReports',
      'state',
      'positionInQueue',
      'taskWarnings',
      'scanProfile',
    ]

    for (let i = 0; i < passFields.length; i++) {
      const field = passFields[i]
      if (typeof data[field] !== 'undefined') {
        report[field] = data[field]
      }
    }

    if (report.file?.name) {
      try {
        report.file.name = decodeURIComponent(report.file.name)
      } catch (err) {
        logger.info('Could not decode filename, keeping original name ', report.file.name)
      }
    }

    this.defaultOverview(data, report)
    this.formatHelpers(data, report)
  }

  defaultOverview(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const overview: { [key: string]: any } = {}

    const resource = this.getResource(data, 'file')
    if (!resource) return

    const fields = ['fileMagicDescription']
    this.copyFields(fields, resource.extendedData, overview)

    if (has(resource, 'fileSize')) {
      overview.size = humanFileSize(resource.fileSize)
    }

    const lang = this.getFileLanguage(resource)
    if (lang) {
      overview.language = lang
    }

    this.getDigests(resource, overview)
    this.additionalHashes(resource.extendedData, overview)

    if (resource?.metaData?.fuzzyfsiohash) {
      overview.fsiofuzzyhash = resource.metaData.fuzzyfsiohash
      delete resource.metaData.fuzzyfsiohash
    }

    report.details = { overview }

    if (resource.metaData) {
      report.details.meta = resource.metaData
    }

    this.getASFData(resource.extendedData, report.details)
    this.copyFromResource(resource, report.details)
  }

  getASFData(extendedData: { [key: string]: any } | undefined, details: { [key: string]: any }) {
    if (!extendedData) {
      return
    }

    const result: { [key: string]: any } = {}
    Object.keys(extendedData).forEach((name) => {
      if (name.indexOf('ASF_') === 0) {
        result[name] = extendedData[name]
      }
    })

    if (Object.keys(result).length) {
      details.asf = result
    }
  }

  additionalHashes(data: { [key: string]: any }, overview: { [key: string]: any }): void {
    const names = ['imphash', 'ssdeep', 'authentihash', 'sdhash', 'tlsh']
    this.copyFields(names, data, overview)
    this.deleteFields(names, data)
  }

  // Some data, that is not directly shown anywhere, but is used for calculations/conditions etc.
  formatHelpers(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const resource = this.getResource(data, 'file')
    if (!resource) return

    const helpers: { [key: string]: any } = {}

    if (has(resource, 'fileSize')) {
      helpers.plainFileSize = resource.fileSize
    }

    report.helpers = helpers
  }

  copyFromResource(data: { [key: string]: any }, result: { [key: string]: any }): void {
    this.copyFields(['certInfos'], data, result)
  }
}
