import { HttpService } from '../../shared'
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import { apiRoutes } from '../../shared/routing/api-routes'
import { UploadFilePayload, DownloadFilePayload } from '../types'
import { downloadData } from 'app/modules/shared/helpers/functions'
import _ from 'lodash'
import { BaseUploadOptions } from '../components/file-upload-modal/types'
import { CommunityVotes, TypeVoteCommunity } from '../types/community-votes'
import { SimilarityResultResponse } from '../types/similarity-result'
import { ScanProfileOptions } from 'app/modules/user/features/profile/types'

export const reportsService = {
  uploadFiles(
    {
      updateUploadProgress,
      updateUploadSpeed,
      updateUploadEta,
      uploadStatus,
      handleCancel,
      baseOptions,
      extendedOptions,
      file,
      link,
      scanProfile,
    }: UploadFilePayload,
    isAdvancedScanningAllowed: boolean
  ): Promise<string> {
    const uploadSpeedCalculator = { prevTimeStamp: 0, prevBytes: 0, currentSpeedMbps: 0 }
    const formData = new FormData()
    file && formData.append('file', file)
    link && formData.append('url', link)
    formData.append('description', baseOptions.description)
    formData.append('tags', baseOptions.tags.join('|'))
    formData.append('password', baseOptions.isPassword ? baseOptions.password : '')
    ;['propagateTags', 'isPrivate', 'isPrivateReport', 'skipWhitelisted'].forEach((name) => {
      formData.append(_.snakeCase(name), baseOptions[name as keyof BaseUploadOptions] ? 'true' : 'false')
    })

    if (isAdvancedScanningAllowed) {
      if (scanProfile) {
        formData.append(_.snakeCase('scanProfile'), scanProfile)
      } else {
        extendedOptions = { ...extendedOptions }
        Object.keys(extendedOptions).forEach((name) => {
          formData.append(name, extendedOptions[name as keyof ScanProfileOptions] ? 'true' : 'false')
        })
      }
    }

    const url = link ? apiRoutes.scanUrl : apiRoutes.scanFile
    const cancelToken = axios.CancelToken
    const source = cancelToken.source()
    handleCancel(source)

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        sourceID: crypto.getRandomValues(new Uint32Array(1))[0].toString(36).slice(2),
      },
      onUploadProgress: (ev: AxiosProgressEvent) => {
        const progress = ev.total ? (ev.loaded / ev.total) * 100 : 0
        const now = new Date().getTime()

        if (now - uploadSpeedCalculator.prevTimeStamp > 1000) {
          const uploadSpeedKbps =
            (8.0 * (ev.loaded - uploadSpeedCalculator.prevBytes)) / (now - uploadSpeedCalculator.prevTimeStamp)
          updateUploadSpeed(Math.round(uploadSpeedKbps / 10.0) / 100)
          updateUploadEta(ev.total ? ((ev.total - ev.loaded) * 0.008) / uploadSpeedKbps : 0)
          uploadSpeedCalculator.prevBytes = ev.loaded
          uploadSpeedCalculator.prevTimeStamp = now
        }

        updateUploadProgress(Math.round(progress))
      },
      cancelToken: source.token,
    }

    return HttpService.post(url, formData, config, (response: { flow_id: string }) => {
      uploadStatus(true)
      return response.flow_id
    })
  },

  downloadFile: async (params: DownloadFilePayload) => {
    const { hash, password, updateDownloadProgress, storeToken, asOriginalName, reportId } = params
    const cancelToken = axios.CancelToken.source()
    storeToken(cancelToken)

    const query: { [key: string]: any } = { original_name: asOriginalName, report_id: reportId }
    if (password) {
      query.password = password
    }
    const config: AxiosRequestConfig = {
      responseType: 'blob',
      params: query,
      onDownloadProgress: (ev: AxiosProgressEvent) => {
        const progress = ev?.total ? (ev.loaded / ev.total) * 100 : 0
        updateDownloadProgress(Math.round(progress))
      },
      cancelToken: cancelToken.token,
    }

    const fileName = `${hash}.zip`
    const url = apiRoutes.downloadFile.replace('{hash}', hash)
    const data = await HttpService.get<any>(url, config)
    downloadData(data, fileName)
  },

  startSlowDownloadReport: async (reportId: string, format: string) => {
    const url = apiRoutes.startSlowDownloadReport.replace('{reportId}', reportId)

    return await HttpService.get<any>(url + '?format=' + format)
  },

  downloadReport: async (reportId: string, exportId: string | undefined, format: string) => {
    const config: AxiosRequestConfig = {
      responseType: 'blob',
      params: { format },
    }
    if (exportId) {
      config.params.export_id = exportId
    }

    const url = apiRoutes.downloadReport.replace('{reportId}', reportId)

    return await HttpService.get<any>(url, config, true)
  },

  downloadHtmlReport: (html: string, reportId: string) => {
    const link = window.URL.createObjectURL(new Blob([html], { type: 'text/html' }))
    const elem = document.createElement('a')
    elem.href = link
    elem.setAttribute('download', `${reportId}.html`) //or any other extension
    document.body.appendChild(elem)
    elem.click()
  },

  getReports(flowId: string, params: string): Promise<any> {
    return HttpService.get(apiRoutes.reports.replace('{flowId}', flowId) + '?' + params)
  },

  getReport(hash: string, reportId: string, params: string): Promise<any> {
    return HttpService.get(apiRoutes.report.replace('{reportId}', reportId).replace('{hash}', hash) + '?' + params)
  },

  getAllFiles(reportId: string, params?: string): Promise<any> {
    return HttpService.get(apiRoutes.files.replace('{reportId}', reportId) + (params ? '&' + params : ''))
  },

  getExtractedFiles(reportId: string, params?: string): Promise<any> {
    return HttpService.get(apiRoutes.extractedFiles.replace('{reportId}', reportId) + (params ? '&' + params : ''))
  },

  getDownloadedFiles(reportId: string, params?: string): Promise<any> {
    return HttpService.get(apiRoutes.downloadedFiles.replace('{reportId}', reportId) + (params ? '&' + params : ''))
  },

  getFile(idOrHash: string, params?: string, config: { [key: string]: any } = {}): Promise<any> {
    const url = apiRoutes.file.replace('{hash}', idOrHash)
    return HttpService.get(url + (params ? '&' + params : ''), config)
  },

  delete(reportId: string): Promise<void> {
    const url = apiRoutes.adminReport.replace('{reportId}', reportId)
    return HttpService.delete(url)
  },

  getCommunityVotes(sha256: string): Promise<CommunityVotes> {
    return HttpService.get(apiRoutes.getCommunityVotes.replace('{hash}', sha256))
  },

  communityVote(sha256: string, vote: TypeVoteCommunity | 'neutral'): Promise<CommunityVotes> {
    return HttpService.post(apiRoutes.communityVote, { sha256: sha256, vote: vote })
  },

  getSimilarity(
    sha256: string,
    minSimilarity: string,
    verdict?: string,
    tags?: string[]
  ): Promise<SimilarityResultResponse> {
    return HttpService.get(
      apiRoutes.getSimilarity +
        '?hash=' +
        sha256 +
        (minSimilarity !== undefined ? '&min_similarity=' + minSimilarity : '') +
        (verdict !== undefined ? '&verdict=' + verdict : '') +
        (tags !== undefined && tags.length ? '&tags=' + tags.join('&tags=') : '')
    )
  },

  modifyVerdict(reportId: string, verdict: string): Promise<void> {
    return HttpService.post(apiRoutes.modifyVerdict, { verdict: verdict, report_id: reportId })
  },

  getChatGptSummary(reportId: string): Promise<any> {
    const url = apiRoutes.getReportChatGptSummary.replace('{reportId}', reportId)
    return HttpService.get(url)
  },
}
