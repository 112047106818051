import React, { createContext, useState } from 'react'
import { CollapseToggleMode } from '../../types'

type panelStateApplication = {
  getCollapsesState: CallableFunction
  setCollapsesState: CallableFunction
  setForceUpdateStateToken: CallableFunction
  getCollapseBlockState: CallableFunction
}

export const collapsePanelContext = createContext<panelStateApplication>(undefined as any)

/**
 * Context to share state of collapse panel with components it needs to control
 */
export const WithCollapsePanelContext = ({ children }: { children: any }) => {
  const [collapsesState, setCollapsesState] = useState<CollapseToggleMode | null>(null)
  const [forceUpdateStateToken, setForceUpdateStateToken] = useState<number | null>(null)
  const getCollapseBlockState = getCollapseBlockStateGetter(collapsesState, forceUpdateStateToken)

  const provided = {
    getCollapsesState: (): string | null => {
      return collapsesState
    },
    setCollapsesState,
    setForceUpdateStateToken,
    getCollapseBlockState,
  }

  return <collapsePanelContext.Provider value={provided}>{children}</collapsePanelContext.Provider>
}

/**
 * Get open state for a certain collapse block taking in account collapse panel state.
 */
export function getCollapseBlockStateGetter(
  collapsesState: CollapseToggleMode | null,
  forceUpdateStateToken: number | null
) {
  return (idx: number, interesting?: boolean) => {
    let isOpen = false

    if (collapsesState === CollapseToggleMode.OpenAll) {
      isOpen = true
    } else if (collapsesState === CollapseToggleMode.OpenInteresting) {
      isOpen = !!interesting
    }

    // Create unique index for collapse component
    // to force collapse rerender when applying controls.
    // Otherwise just changing isOpen will not work
    let uniqueIdx = idx as unknown as string
    if (forceUpdateStateToken) {
      uniqueIdx += '-' + forceUpdateStateToken
    }

    return { isOpen, uniqueIdx }
  }
}
