import React, { useContext } from 'react'
import { NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { applicationConfiguration } from '../../modules/shared'
import AppbarDropdown from './appbar-dropdown'
import DropdownSelect from '../dropdown'

export enum LanguageControlType {
  Select = 'select',
  Button = 'button',
}

export const LanguageSelector: React.FC<{ type?: LanguageControlType }> = ({ type = LanguageControlType.Button }) => {
  const { i18n } = useTranslation()
  const config = useContext(applicationConfiguration)

  const isSelectedLanguage = (lang: string) => (i18n?.language === lang ? 'appbar-dropdown-item-selected' : '')

  const getLanguage = (code?: string) => {
    if (!code) return ''

    return config.languages.find((lang) => lang.code === i18n.language)?.language
  }

  if (type === LanguageControlType.Button) {
    return (
      <AppbarDropdown title={(i18n?.language ?? '').toUpperCase()}>
        {config.languages.map((item, idx) => (
          <div key={item.code}>
            {idx !== 0 ? <NavDropdown.Divider className="hide-desktop m-0" /> : null}
            <NavDropdown.Item
              className={`appbar-dropdown-menu-item ${isSelectedLanguage(item.code)}`}
              onClick={() => i18n.changeLanguage(item.code)}
            >
              {item.language}
            </NavDropdown.Item>
          </div>
        ))}
      </AppbarDropdown>
    )
  }

  return (
    <DropdownSelect
      items={config.languages.map((item) => ({
        value: item.code,
        name: item.language,
      }))}
      onClick={i18n.changeLanguage}
      value={getLanguage(i18n?.language)}
    />
  )
}
