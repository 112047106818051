import React, { createContext, useContext, useEffect, useState } from 'react'
import { ConfigurationService } from '../services/configuration.service'
import { ApplicationConfiguration } from '../types/application-configuration'
import { allowedFeaturesStorage } from '../../auth/helper/storage'
import { setConstants } from '../../reports/helpers/functions';
import { useTypedTranslation } from '../hooks/use-translation';
import { mainNotificationContext, NotificationData } from './main-notification-context';
import { HttpService } from 'app/modules/shared';
import { authHttpInterceptor } from 'app/modules/auth/interceptors';

export const applicationConfiguration = createContext<ApplicationConfiguration>({} as ApplicationConfiguration)

export const WithConfigurationContext = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element | null => {
  const mainNotification = useContext(mainNotificationContext)
  const { _t } = useTypedTranslation()
  const [configuration, setConfiguration] = useState<ApplicationConfiguration | undefined>()
  const [error, setError] = useState('')

  // Add http interceptors dealing with authentication
  HttpService.addRequestInterceptor(authHttpInterceptor)

  async function loadConfiguration() {
    try {
      const config = await ConfigurationService.getConfiguration()

      // We should access allowed features in routing, where config context is not available.
      // So we save them to special storage.
      allowedFeaturesStorage.setFeatures(config.allowedFeatures)

      setConfiguration({ ...config, loadConfiguration })
      setConstants(config.constantStrings)
    } catch (error: any) {
      setError(error.message)
    }
  }

  useEffect(() => {
    loadConfiguration()
    // eslint-disable-next-line
  }, [])

  // Do various actions when config is changed
  useEffect(() => {
    if (configuration) {
      handleLicenseNotification(configuration, mainNotification, _t)
      handleFreeSpaceWarning(configuration, mainNotification, _t)
    }
  }, [configuration]);

  if (error) {
    return <div>{_t('Server under maintenance. Be back soon.')}</div>
  }
  if (!configuration) {
    return null
  }

  return <applicationConfiguration.Provider value={configuration}>{children}</applicationConfiguration.Provider>;
};

// Show notification in case we reached or are close to license expiration
function handleLicenseNotification(
  config: ApplicationConfiguration, mainNotification: NotificationData, _t: CallableFunction
) {
  const daysLeft = config.licenseExpiration
  const isLicenseEnding = daysLeft !== null && daysLeft !== undefined && daysLeft <= 30

  if (isLicenseEnding) {
    const type = daysLeft <= 7 ? 'danger' : 'warning'
    const message = (
      daysLeft > 0 ? _t("license-expiration-message", { days: config.licenseExpiration }) : _t("license-expired-message")
    )

    mainNotification.setMessageData(message, type, 15000)
  }
}

// Check free storage space and display warning if it is lower then the threshold
function handleFreeSpaceWarning(
  config: ApplicationConfiguration, mainNotification: NotificationData, _t: CallableFunction
) {
  const thresholdMB = config.retentionFreeSpaceWarningThreshold
  const thresholdBytes = thresholdMB ? (thresholdMB * 1024 * 1024) : null
  const freeSpace = config.freeStorageSpace && !isNaN(config.freeStorageSpace) ? config.freeStorageSpace : null
  const thresholdReached = freeSpace && thresholdBytes && (freeSpace < thresholdBytes)

  if (thresholdReached) {
    const freeSpaceMB = Math.round(freeSpace / (1024 * 1024))

    mainNotification.setMessageData(
      _t("retention_free_space_under_limit_warning", {freeSpace: freeSpaceMB, freeSpaceLimit: thresholdMB}),
      'warning',
      0
    )
  }
}
