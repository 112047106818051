import React, { MouseEventHandler, useContext } from 'react'
import { NavDropdown } from 'react-bootstrap'
import { AuthContext } from 'app/modules/auth'
import style from './user-button.module.scss'
import { applicationConfiguration } from '../../../modules/shared'
import { doSignout } from '../../../modules/auth/helper/signout'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { SigninLink } from 'app/modules/auth/components/auth-link'
import { useLocation } from 'react-router'
import AppbarDropdown from '../appbar-dropdown'
import { getActiveRouteClass } from '../helpers/helpers'
import { AuthContextData } from 'app/modules/auth/context'
import { TFunction } from 'i18next'
import { Link } from 'react-router-dom'
import { getPageUrl } from 'app/modules/shared/routing'

export const UserButton = (): JSX.Element => {
  const { _t } = useTypedTranslation()
  const config = useContext(applicationConfiguration)
  const auth = useContext(AuthContext)
  const user = auth.user
  const currentRoute = useLocation().pathname.substring(1)
  const getActiveMenuClass = (activeRoutes: string[]) =>
    getActiveRouteClass(activeRoutes, currentRoute, 'appbar-dropdown-item-selected')
  const activeRouteClass = getActiveRouteClass(
    ['users/profile', 'me/reports', 'auth/signin'],
    currentRoute,
    'appbar-dropdown-selected'
  )

  const handleSignout = () => {
    doSignout(auth, config)
  }

  if (!user) {
    return (
      <div className={`appbar-item-wrapper ${activeRouteClass}`}>
        <SigninLink className={`appbar-item ${style.signin}`} />
      </div>
    )
  }

  return (
    <AppbarDropdown
      className={`${activeRouteClass} appbar-user-menu`}
      title={<i className={`ds3 ds3-user __avatar-placeholder __${style.userIcon}`} />}
    >
      <UserMenu auth={auth} getActiveMenuClass={getActiveMenuClass} t={_t as TFunction} handleSignout={handleSignout} />
    </AppbarDropdown>
  )
}

function UserMenu({
  auth,
  getActiveMenuClass,
  t,
  handleSignout,
}: {
  auth: AuthContextData
  getActiveMenuClass: CallableFunction
  t: TFunction
  handleSignout: MouseEventHandler<HTMLElement>
}) {
  const { _t } = useTypedTranslation()
  const user = auth?.user
  const isAdmin = user?.isAdmin || user?.isAuditor
  const name = user?.username ? user.username : user?.email ? user.email : undefined

  return (
    <div className="appbar-user-dropdown">
      <NavDropdown.Header className="appbar-user-dropdown-header" aria-label="Profile name">
        {name ? <div className="text-body text-m ellipsis">{name}</div> : null}
        {user?.externalAuth ? (
          <div className="text-body text-m ellipsis" title={t('external-user-id')}>
            {user.externalAuth.service}: {user.externalAuth.userId}
          </div>
        ) : null}
      </NavDropdown.Header>
      <NavDropdown.Divider className="mx-3 my-0" />
      <div className="appbar-user-dropdown-body">
        <Link
          className={`appbar-dropdown-menu-item dropdown-item ${getActiveMenuClass(['users/profile'])}`}
          to={getPageUrl('user-profile') as string}
        >
          {_t('my-settings')}
        </Link>
        <NavDropdown.Divider className="hide-desktop m-0" />
        <Link
          className={`appbar-dropdown-menu-item dropdown-item ${getActiveMenuClass(['me/reports'])}`}
          to={getPageUrl('my-reports') as string}
        >
          {_t('my-reports')}
        </Link>
        <Link
          className={`appbar-dropdown-menu-item dropdown-item ${getActiveMenuClass(['me/downloads'])}`}
          to={getPageUrl('my-downloads') as string}
        >
          {_t('my-downloads')}
        </Link>
        {isAdmin ? (
          <>
            <NavDropdown.Divider className="hide-desktop m-0" />
            <Link
              className={`appbar-dropdown-menu-item dropdown-item ${getActiveMenuClass(['admin'])}`}
              to={getPageUrl('admin-home') as string}
            >
              {_t('admin-panel')}
            </Link>
          </>
        ) : null}
      </div>
      <NavDropdown.Divider className="mx-3 mt-0 mb-2" />
      <NavDropdown.Item className="appbar-dropdown-menu-item pb-2" onClick={handleSignout}>
        {t('sign-out')}
      </NavDropdown.Item>
    </div>
  )
}
