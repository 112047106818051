import BaseFormatter from './base'
import { has, hasFields } from '../../helpers'

/**
 * Format report response data for PE file details page
 */
export default class MboxDetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const result: { [key: string]: any } = {}

    const resource = this.getResource(data, 'file')
    if (!resource) return

    const fileType = !data?.file?.type || data.file.type === 'other' ? this.getFileType(resource) : data.file.type
    if (fileType !== 'mbox') {
      return
    }

    this.groupOverview(resource, result)

    if (hasFields(result)) {
      report.mbox_details = result
      report.mbox_details.overview = { ...report.details.overview, ...report.mbox_details.overview }
      delete report.details
    }
  }

  groupOverview(resource: { [key: string]: any }, result: { [key: string]: any }): void {
    const overview: { [key: string]: any } = {}
    const metaNames = ['Subject', 'From', 'To', 'Date', 'In-Reply-To']
    const meta: any = {}

    if (resource.metaData) {
      metaNames.forEach((name) => {
        if (has(resource.metaData, name)) {
          overview[name] = resource.metaData[name]
        }
      })

      for (const key in resource.metaData) {
        if (metaNames.includes(key)) continue
        meta[key] = resource.metaData[key]
      }
    }

    if (hasFields(overview)) {
      result.overview = overview
    }

    if (hasFields(meta)) {
      result.meta = meta
    }
  }
}
