import React, { useContext } from 'react'
import { CollapseToggleMode } from '../../types'
import { collapsePanelContext } from './context'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import Switch from '../switch'
import { useExport } from '../../contexts'

/**
 * Panel with buttons to ease opening/closing of collapses on the page
 */

export function CollapseTogglePanel({ className }: { className?: string }): React.ReactElement | null {
  const { _t } = useTypedTranslation()
  const { exporting } = useExport()
  const panelContext = useContext(collapsePanelContext)
  const setState = (state: CollapseToggleMode) => {
    panelContext.setCollapsesState(state)
  }

  const toggleState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const state = e.target.checked ? CollapseToggleMode.OpenAll : CollapseToggleMode.CloseAll
    setState(state)
  }

  if (typeof className === 'undefined') {
    className = ''
  }

  if (exporting) {
    return null
  }

  return (
    <div className={`collapse-toggle-panel ${className}`}>
      <Switch onChange={toggleState} label={<span className="text-capital">{_t('open-all')}</span>} />
    </div>
  )
}
