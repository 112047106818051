import React from 'react'
import { hasFields, capitalize } from './functions'
import { CommonLabeledDataRow } from '../components/labeled-data-row'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'

type ObjectRowsProps = {
  object: { [key: string]: any }
  enableCopy: boolean
  checkHash: boolean
  lengthLimit: number
  className?: string
}
export function ObjectRows(props: Readonly<ObjectRowsProps>): JSX.Element | null {
  const { object, enableCopy, checkHash, lengthLimit, className } = props
  const { _t } = useTypedTranslation()

  if (!hasFields(object)) {
    return null
  }

  return (
    <div className={'data-block ' + (className ?? '')}>
      {Object.keys(object).map((key) => {
        const label = capitalize(_t(key))
        const value = object[key]

        return (
          <CommonLabeledDataRow
            key={key}
            label={label}
            value={value}
            enableCopy={enableCopy}
            checkHash={checkHash}
            lengthLimit={lengthLimit}
          />
        )
      })}
    </div>
  )
}

ObjectRows.defaultProps = {
  enableCopy: false,
  checkHash: false,
  lengthLimit: 0,
}

/**
 * Turn object into a list of data-rows elements
 *
 * @param object
 */
export function renderObjectToDataRows(
  object: string | { [key: string]: any } | undefined,
  enableCopy = false,
  checkHash = false,
  lengthLimit = 1024
): JSX.Element | null {
  if (typeof object !== 'object' || !hasFields(object)) {
    return null
  }

  return (
    <div className="data-block">
      {Object.keys(object).map((key) => {
        const label = capitalize(key)
        const value = object[key]

        return (
          <CommonLabeledDataRow
            key={key}
            label={label}
            value={value}
            enableCopy={enableCopy}
            checkHash={checkHash}
            lengthLimit={lengthLimit}
          />
        )
      })}
    </div>
  )
}
