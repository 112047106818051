import BaseFormatter from './base'
import { has, hasFields } from '../../helpers'

/**
 * Format report response data for MS Office file details page
 */
export default class UnknownFileTypeDetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const resource = this.getResource(data, 'file')
    if (!resource || typeof data.file === 'undefined') {
      return
    }

    const keys = Object.keys(report)
    const checkKey = '_details'

    // Check if other details exist
    for (let i = 0; i < keys.length; i++) {
      const pos = keys[i].indexOf(checkKey)
      if (pos > 0 && pos === keys[i].length - checkKey.length) {
        return
      }
    }

    const details: { [key: string]: any } = {}
    const copyField = ['metaData', 'exifMetaData', 'chmMetaData']
    copyField.forEach((field) => {
      if (typeof resource[field] !== 'undefined') {
        details[field] = resource[field]
      }
    })

    if (resource.extendedData) {
      this.copyGrouped(resource.extendedData, details)
    }

    if (!Object.keys(details).length) {
      return
    }

    if (typeof report.details === 'undefined') {
      report.details = details
    } else {
      report.details = { ...report.details, ...details }
    }
  }

  /**
   * Just copy data that is pregrouped to result report
   *
   * @param data
   * @param result
   */
  copyGrouped(data: { [key: string]: any }, result: { [key: string]: any }): void {
    const names: { [key: string]: any } = {
      streams: 'oleStreams',
    }

    Object.keys(names).forEach((name) => {
      if (has(data, name)) {
        const useName = names[name]
        result[useName] = data[name]
      }
    })
  }
}
