import { ReactElement } from 'react'
import { TagType } from './signal-group'

export type RawMITRETechnique = {
  ID: string
  name: string
}

export interface Signal {
  strength: number
  description: (ReactElement | string)[]
  origin: string
  originId: string
  tags?: TagType[]
}

export enum SignalType {
  Malicious = 'malicious',
  Suspicious = 'suspicious',
  Informational = 'informational', // BC, replaced with no_threat
  No_threat = 'no_threat',
  Benign = 'benign',
  Unknown = 'unknown',
}

export enum SignalStrength {
  Whitelisted = -1.0,
  NoImpact = 0.0,
  VeryLowImpact = 0.1,
  LowImpact = 0.25,
  ConsiderableImpact = 0.5,
  SevereImpact = 0.75,
  CriticalImpact = 1.0,
}

export const signalStrengthList = Object.values(SignalStrength).sort((a, b) => a.toString().localeCompare(b.toString()))

export enum SignalVerdict {
  Malicious = 'malicious',
  Likely_malicious = 'likely_malicious',
  Suspicious = 'suspicious',
  Informational = 'informational', // BC, replaced with no_threat
  No_threat = 'no_threat',
  Benign = 'benign',
  Unknown = 'unknown',
}

type SignalVerdictType = typeof SignalVerdict
export type UniqueSignalVerdict = Omit<SignalVerdictType, 'Informational'>

export type VerdictData = {
  name: SignalVerdict
  original?: SignalVerdict
  confidence?: number
  threatLevel?: number
  interestingScore?: number
  comment?: string
}
