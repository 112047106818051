import React, { useState, useRef, useContext, useEffect, useMemo, MutableRefObject } from 'react'
import { Modal, Form, ProgressBar } from 'react-bootstrap'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { logger } from '../../../../components/logger'
import { FileUploadModalProps, UploadFilePayload } from '../../types'
import { CancelTokenSource } from 'axios'
import { FileItem } from '../file-item'
import { HttpError } from 'app/modules/shared/exceptions'
import { mainNotificationContext, NotificationData } from 'app/modules/shared/context/main-notification-context'
import { BaseUploadOptions, extendedUploadOptionsDefaults, baseUploadOptionsDefaults } from './types'
import { UploadOptions } from './upload-options'
import { AuthContext } from 'app/modules/auth'
import { AuthContextData } from 'app/modules/auth/context'
import { applicationConfiguration } from 'app/modules/shared'
import { ApplicationConfiguration } from 'app/modules/shared/types'
import Button from 'app/modules/shared/components/button'
import { ScanProfile, ScanProfileOptions } from 'app/modules/user/features/profile/types'
import { getAvailableOptionsDefaults } from './helpers'

export const FileUploadModal = ({ show, file, onSubmit, onHide }: FileUploadModalProps): JSX.Element => {
  const { _t } = useTypedTranslation()
  const auth = useContext(AuthContext)
  const user = auth.user
  const config = useContext(applicationConfiguration)
  const notificationContext = useContext(mainNotificationContext)
  const [baseOptions, setBaseOptions] = useState<BaseUploadOptions>(baseUploadOptionsDefaults)
  const defaultProfile = useMemo(() => {
    const profileId = user?.scanProfiles.filter((item) => item.is_default)?.[0]?.id
    return profileId || user?.scanProfiles.filter((item) => item.name === config.initialScanProfile)?.[0]?.id
  }, [auth.user])
  const [activeScanProfile, setActiveScanProfile] = useState(defaultProfile)
  const availableOptions: ScanProfileOptions | undefined = useMemo(() => {
    const filtered = user?.scanProfiles?.filter((item) => item.name === config.availableOptionsScanProfile)
    return filtered?.length ? filtered[0].options : undefined
  }, [user?.scanProfiles])
  const scanProfiles: ScanProfile[] = useMemo(() => {
    return user?.scanProfiles?.filter((item) => item.name !== config.availableOptionsScanProfile) || []
  }, [user?.scanProfiles])
  const optionsDefaults = useMemo(
    () => getAvailableOptionsDefaults(extendedUploadOptionsDefaults, availableOptions),
    [availableOptions]
  )
  const [extendedOptions, setExtendedOptions] = useState<ScanProfileOptions>(optionsDefaults)

  const [progress, setProgress] = useState(0)
  const [speed, setSpeed] = useState(0)
  const [eta, setEta] = useState(0)
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>()
  const [uploadStarted, setUploadStarted] = useState(false)
  const [error, setError] = useState('')
  const [activeTab, setActiveTab] = useState<string>('base-options')
  const timeRef = useRef<number>(0)

  useEffect(() => {
    if (!baseOptions?.description) {
      return
    }

    const matches = baseOptions.description.match(/#[\w-]+\b/g)
    const tags = matches ? matches.map((match) => match.substring(1)) : []

    setBaseOptions({
      ...baseOptions,
      showPropagate: !!matches,
      tags: tags,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseOptions.description])

  const updateUploadProgress = (progress: number) => {
    setProgress(progress)
    if (progress >= 100) {
      let remained = new Date().getTime() - timeRef.current
      if (remained < 200) remained = 200 - remained
      else remained = 0

      setTimeout(() => {
        setCancelToken(null)
      }, remained)
    }
  }

  const updateUploadSpeed = (speedMbps: number) => {
    setSpeed(speedMbps)
  }

  const updateUploadEta = (second: number) => {
    setEta(second)
  }

  const onCancel = () => {
    !!cancelToken && cancelToken.cancel()
    setUploadStarted(false)
  }

  const handleUpload = getUploadHandler(
    file,
    baseOptions,
    extendedOptions,
    activeScanProfile,
    auth,
    timeRef,
    onSubmit,
    setError,
    setUploadStarted,
    updateUploadProgress,
    updateUploadSpeed,
    updateUploadEta,
    setCancelToken,
    onHide,
    notificationContext,
    _t,
    config
  )

  const sizeClass = 'modal-with-tabs modal-fix-width-2 modal-less-tab-left-margin'

  return (
    <Modal show={show} animation={false} onHide={onHide} backdropClassName="static" className={sizeClass}>
      <Modal.Header closeButton>
        <Modal.Title className="text-break" style={{ width: '93%' }}>
          <FileItem file={file} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-3">
        <Form>
          <UploadOptions
            baseOptions={baseOptions}
            extendedOptions={extendedOptions}
            setBaseOptions={setBaseOptions}
            setExtendedOptions={setExtendedOptions}
            scanProfiles={scanProfiles}
            activeScanProfile={activeScanProfile}
            setActiveScanProfile={setActiveScanProfile}
            availableOptions={availableOptions}
            error={error}
            setError={setError}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Form>

        <div className={'progress-bar-container variant-primary ' + (uploadStarted ? 'visible' : 'invisible')}>
          <ProgressBar now={progress} />
        </div>
        <span
          className={
            'font-size-13 mt-1 d-block ' +
            (timeRef.current !== 0 && timeRef.current + 10000 < Date.now() ? 'visible' : 'invisible')
          }
        >
          <strong>{_t('upload-speed')}:</strong> {`${speed} Mbps`}
          {typeof eta === 'number' ? (
            <>
              <strong className="ms-3">{_t('upload-eta')}:</strong>{' '}
              {`${new Date(eta * 1000)?.toISOString()?.substring(14, 19) || '-'}`} :
            </>
          ) : null}
        </span>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div>
          {uploadStarted && (
            <Button variant="tertiary" onClick={onCancel} disabled={!cancelToken}>
              {_t('cancel-upload')}
            </Button>
          )}
        </div>
        <div className="d-flex">
          <Button variant="tertiary" className="me-6" onClick={() => onHide()}>
            {_t('close')}
          </Button>
          <Button
            variant="primary"
            onClick={(e) => (uploadStarted ? null : handleUpload(e))}
            className={uploadStarted ? 'disabled' : undefined}
            autoFocus
          >
            {_t('upload')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

function getUploadHandler(
  file: string | File,
  baseOptions: BaseUploadOptions,
  extendedOptions: ScanProfileOptions,
  scanProfile: string | undefined,
  auth: AuthContextData,
  timeRef: MutableRefObject<number>,
  onSubmit: CallableFunction,
  setError: CallableFunction,
  setUploadStarted: CallableFunction,
  updateUploadProgress: (progress: number) => void,
  updateUploadSpeed: (speedMbps: number) => void,
  updateUploadEta: (second: number) => void,
  setCancelToken: (cancelToken: CancelTokenSource | null) => void,
  onHide: CallableFunction,
  notificationContext: NotificationData,
  _t: CallableFunction,
  config: ApplicationConfiguration
) {
  const uploadStatus = (status: boolean) => {
    logger.debug('Upload Status: ', status)
  }

  return async (e: React.MouseEvent) => {
    e.preventDefault()
    if (config.askTermsConsent && !baseOptions.isTerms) {
      setError(_t('terms-note'))
      return
    }

    setUploadStarted(true)
    timeRef.current = new Date().getTime()

    const payload: UploadFilePayload = {
      baseOptions,
      extendedOptions,
      scanProfile,
      updateUploadProgress,
      updateUploadSpeed,
      updateUploadEta,
      uploadStatus,
      handleCancel: setCancelToken,
    }

    const source = typeof file === 'string' ? 'link' : 'file'
    payload[source] = file as any

    try {
      await onSubmit(payload)
    } catch (error) {
      // Intermediate protection level, like Cloudflare, can block upload,
      // if it detects a malware possibility in file.
      const malwareBlocked =
        error instanceof HttpError &&
        error.code === 403 &&
        typeof error.response?.data === 'string' &&
        error.response.data.indexOf('Cloudflare') !== -1

      const backendSubmitError =
        error instanceof HttpError &&
        error.code === 400 &&
        error.response?.data &&
        typeof error.response.data === 'object' &&
        'detail' in error.response.data &&
        typeof error.response.data.detail === 'string'
          ? error.response.data.detail
          : undefined

      const queueFullError =
        error instanceof HttpError &&
        error.code === 429 &&
        error.response?.data &&
        typeof error.response.data === 'object' &&
        'detail' in error.response.data &&
        error.response.data.detail &&
        typeof error.response.data.detail === 'object' &&
        'key' in error.response.data.detail &&
        error.response.data.detail.key === 'queue-full'
          ? error.response.data.detail.message
          : undefined

      const message = malwareBlocked
        ? _t('upload-malware-blocked')
        : backendSubmitError
          ? backendSubmitError
          : queueFullError
            ? queueFullError
            : _t('upload-error')

      const quotaExceeded = error instanceof HttpError && error.code === 429 && !queueFullError

      // In case user quota has been exceeded, navigate to the error page instead of showing error message
      onHide(true, quotaExceeded)
      if (!quotaExceeded) {
        notificationContext.setMessageData(message, 'danger')
      }
    }
  }
}
