import { ReportWrapperConstructor } from './report-wrapper-base'

export function ReportsListMixin<TBase extends ReportWrapperConstructor>(Base: TBase): any {
  return class ReportWithReportsList extends Base {
    /**
     * Check if all the reports are completely obtained from scan service
     */
    checkAllReportsReady(): boolean {
      const reportsData = this.getAllReportsData()
      return !!reportsData?.allFinished
    }

    /**
     * Get total reports amount.
     * Theoretically it might be that this number is greater than amount of reports
     *   we fetched from server, if data for some of reports is not obtained yet
     *   from scan service.
     */
    getTotalReportsAmount() {
      const data = this.getAllReportsData()
      return data?.reportsAmount
    }

    /**
     * Get flag indicating that all attempts to load derived files,
     *   for all reports of given uploaded file, are finished
     */
    getFilesLoadingState(): boolean {
      const data = this.getAllReportsData()
      return !!data?.allFilesDownloadFinished
    }

    /**
     * Get flag indicating that all additional scan steps are finished
     */
    getAdditionalStepsState(reportId: string): boolean | undefined {
      const data = this.getAllReportsData()
      return !!data?.allAdditionalStepsDone
    }
  }
}
