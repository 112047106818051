import { useTheme } from 'app/modules/shared/context/theme-context'
import { getThemeColor } from 'app/modules/shared/helpers'
import React, { useCallback, useMemo, useState } from 'react'
import HexViewer from './base-hex-viewer'
// import { BaseHexEditor } from 'react-hex-editor'

interface HexViewerContainerProps {
  //data: string,
  data: Uint8Array
}
export const HexViewerContainer = ({ data }: HexViewerContainerProps): JSX.Element => {
  const { mode } = useTheme()
  const [range, setRange] = useState<[number | null, number | null]>([null, null])
  const input = useMemo(() => {
    //return new TextEncoder().encode(data)
    return data
  }, [data])

  const onChangeSelection = useCallback((s: number, e: number) => {
    setRange([s, e])
  }, [])

  return (
    <div className="hex-viewer-container">
      <HexViewer
        columns={16}
        data={input}
        height={480}
        width={564}
        rowHeight={20}
        rows={16}
        showAscii
        readOnly
        showRowLabels
        onChangeSelection={onChangeSelection}
        inlineStyles={{
          body: { backgroundColor: getThemeColor(mode, 'color-light') },
          gutter: { backgroundColor: getThemeColor(mode, 'color-light') },
          offsetLabel: {
            backgroundColor: getThemeColor(mode, 'color-light'),
            color: getThemeColor(mode, 'color-text-light'),
          },
          byte: { backgroundColor: getThemeColor(mode, 'color-light'), color: getThemeColor(mode, 'color-text') },
          asciiValues: {
            backgroundColor: getThemeColor(mode, 'color-light'),
            color: getThemeColor(mode, 'color-text'),
          },
        }}
      />
      {range[0] !== null && range[1] !== null && range[0] !== range[1] && (
        <div className="mx-2">
          <p className="my-2 text-muted">{`${Math.abs(range[0] - range[1])} characters selected.`}</p>
        </div>
      )}
    </div>
  )
}
