import React, { useEffect, useState } from 'react'
import { LogoImage } from '../logo'
import { LogoImageType } from 'app/modules/admin/features/settings/appearence/types'
import { Link } from 'react-router-dom'
import { getPageUrl } from 'app/modules/shared/routing'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { logger } from '../logger'
import { settingsService } from 'app/modules/admin/services'

const contacts = [
  {
    link: 'mailto:support@filescan.io',
    icon: 'ds3-icon ds3-email',
    title: 'Contact us via email',
  },
  {
    link: 'https://twitter.com/filescan_itsec',
    icon: 'ds3 ds3-twitter',
    title: 'Contact us on X',
  },
  {
    link: 'https://www.linkedin.com/company/opswat/',
    icon: 'ds3 ds3-linkedin',
    title: 'Contact us on linkedin',
  },
  {
    link: 'https://github.com/filescanio',
    icon: 'ds3 ds3-github',
    title: 'Contact us on github',
  },
]

export const Footer = (): JSX.Element => {
  const { _t } = useTypedTranslation()
  const [version, setVersion] = useState('')

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await settingsService.getVersionInfo()
        setVersion(response)
      } catch (err) {
        logger.warn('Failed to fetch version info')
      }
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <div className="footer-logo">
            <LogoImage type={LogoImageType.Footer} height="100%" />
          </div>
          <div className="footer-content">
            <Link to={getPageUrl('help') as string}>{_t('faq')}</Link>
            <Link to={getPageUrl('status') as string}>{_t('status')}</Link>
            <Link to={getPageUrl('privacy') as string}>{_t('privacy-policy')}</Link>
            <Link to={getPageUrl('cookie-policy') as string}>{_t('cookie-policy')}</Link>
            <Link to={getPageUrl('terms') as string}>{_t('terms-condition')}</Link>
            <a href="https://www.filescan.com/THIRD-PARTY" target="_blank" rel="noreferrer">
              {_t('3rd-party-eula')}
            </a>
            <div className="my-auto footer-divider" />
            <div className="version-number">{version}</div>
          </div>
        </div>
        <div className="footer-contact-list">
          {contacts.map((item) => (
            <a
              className="ds3-hover"
              key={item.title}
              href={item.link}
              rel="noreferrer"
              target="_blank"
              title={item.title}
            >
              <i className={item.icon + ' color-text-main'} />
            </a>
          ))}
        </div>
      </div>
    </footer>
  )
}
