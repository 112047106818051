import React from 'react'
import { OverlayTrigger, Popover, Badge } from 'react-bootstrap'
import { SignalVerdict } from '../types'
import { buildPrevalenceSearchLink, getVerdictColor, getVerdictTextColor } from '../helpers'
import { useSelector } from '../contexts/report-check'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'

type PrevalenceProps = {
  count?: {
    [key: string]: number
  }
  type: string
  value: string
  context: string
  currentReportId: string
  tooltip?: string
}
export function Prevalence(props: PrevalenceProps): JSX.Element | null {
  const { _t } = useTypedTranslation()
  const { count, value, type, context, currentReportId, tooltip } = props
  const error = useSelector((state) => state[context]?.error)
  const days = useSelector((state) => state[context]?.days)

  if (error) {
    return (
      <div className="text-center">
        <i className="fa fa-times text-danger" />
      </div>
    )
  }

  let total = 0
  if (count) {
    for (const verdict in count) {
      total += count[verdict]
    }
  }

  // Mention !count here only for further TS type checking
  if (!count || !total) {
    return <div className="text-center">-</div>
  }

  const maliciousCount = (count[SignalVerdict.Malicious] ?? 0) + (count[SignalVerdict.Likely_malicious] ?? 0)
  const suspiciousCount = count[SignalVerdict.Suspicious] ?? 0
  const informationalCount =
    (count[SignalVerdict.Informational] ?? 0) +
    (count[SignalVerdict.No_threat] ?? 0) +
    (count[SignalVerdict.Benign] ?? 0)

  const malColor = {
    backgroundColor: getVerdictColor(SignalVerdict.Likely_malicious),
    color: getVerdictTextColor(SignalVerdict.Likely_malicious),
  }
  const susColor = {
    backgroundColor: getVerdictColor(SignalVerdict.Suspicious),
    color: getVerdictTextColor(SignalVerdict.Suspicious),
  }
  const infColor = {
    backgroundColor: getVerdictColor(SignalVerdict.No_threat),
    color: getVerdictTextColor(SignalVerdict.No_threat),
  }
  const unkColor = {
    backgroundColor: getVerdictColor(SignalVerdict.Unknown),
    color: getVerdictTextColor(SignalVerdict.Unknown),
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="d-inline-flex align-items-center">
        {!total || (
          <PrevalenceItem
            link={buildPrevalenceSearchLink(type, value, days, currentReportId)}
            style={unkColor}
            count={total}
            tooltip={tooltip}
            tooltipParam={{ count: `${total}` }}
            title={tooltip ? undefined : _t('total-reports')}
          />
        )}
        {!maliciousCount || (
          <PrevalenceItem
            link={buildPrevalenceSearchLink(type, value, days, currentReportId, [
              SignalVerdict.Malicious,
              SignalVerdict.Likely_malicious,
            ])}
            style={malColor}
            count={maliciousCount}
            tooltip={tooltip ? `${tooltip}-malicious` : undefined}
            tooltipParam={{ count: `${maliciousCount}` }}
            title={tooltip ? undefined : _t('malicious-reports')}
          />
        )}
        {!suspiciousCount || (
          <PrevalenceItem
            link={buildPrevalenceSearchLink(type, value, days, currentReportId, [SignalVerdict.Suspicious])}
            style={susColor}
            count={suspiciousCount}
            tooltip={tooltip ? `${tooltip}-suspicious` : undefined}
            tooltipParam={{ count: `${suspiciousCount}` }}
            title={tooltip ? undefined : _t('suspicious-reports')}
          />
        )}
        {!informationalCount || (
          <PrevalenceItem
            link={buildPrevalenceSearchLink(type, value, days, currentReportId, [
              SignalVerdict.Informational,
              SignalVerdict.No_threat,
              SignalVerdict.Benign,
            ])}
            style={infColor}
            count={informationalCount}
            tooltip={tooltip ? `${tooltip}-informational` : undefined}
            tooltipParam={{ count: `${informationalCount}` }}
            title={tooltip ? undefined : _t('informational-reports')}
          />
        )}
      </div>
    </div>
  )
}

type PrevalenceItemProps = {
  link: string
  count: number
  style: { [key: string]: string }
  tooltip?: string
  tooltipParam?: { [key: string]: string }
  title?: string
}
function PrevalenceItem(props: PrevalenceItemProps): JSX.Element {
  const { link, count, style, tooltip, tooltipParam, title } = props
  const { _t } = useTypedTranslation()
  if (!tooltip) {
    return (
      <a href={link} target="_blank" className={'prevalence'} rel="noreferrer">
        <span className="badge prevalence-badge" style={style} title={title}>
          {formatCount(count)}
        </span>
      </a>
    )
  }

  return (
    <OverlayTrigger
      placement="auto"
      trigger="hover"
      overlay={
        <Popover id={tooltip} className="responsive-popover">
          <Popover.Body>
            <span
              dangerouslySetInnerHTML={{
                __html: _t(tooltip, tooltipParam),
              }}
            />
          </Popover.Body>
        </Popover>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <a {...triggerHandler} ref={ref} href={link} target="_blank" rel="noreferrer" className={'prevalence'}>
          <span className="badge prevalence-badge" style={style}>
            {formatCount(count)}
          </span>
        </a>
      )}
    </OverlayTrigger>
  )
}

function formatCount(count: number): string {
  if (count <= 0) {
    return '-'
  } else if (count >= 10) {
    return '10+'
  } else {
    return `${count}`
  }
}
