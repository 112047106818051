import BaseFormatter from './base'
import { has, hasFields } from '../../helpers'

/**
 * Format report response data for MS Office file details page
 */
export default class ElfDetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const result: { [key: string]: any } = {}

    const resource = this.getResource(data, 'file')
    if (!resource) return

    const fileType = !data?.file?.type || data.file.type === 'other' ? this.getFileType(resource) : data.file.type
    if (fileType !== 'elf') {
      return
    }

    this.groupOverview(resource, result)
    if (has(resource, 'extendedData')) {
      this.groupExtended(resource.extendedData, result)
    }

    if (hasFields(result)) {
      report.elf_details = result
      report.elf_details.overview = { ...report.details.overview, ...report.elf_details.overview }
      delete report.details
    }
  }

  /**
   * Gather data to display in overview block
   *
   * @param data
   * @param result
   */
  groupOverview(resource: { [key: string]: any }, result: { [key: string]: any }): void {
    const overview: { [key: string]: any } = {}
    const metaNames: any[] = []
    const meta: any = {}

    if (resource.metaData) {
      for (const key in resource.metaData) {
        if (metaNames.includes(key)) {
          overview[key] = resource.metaData[key]
        } else {
          meta[key] = resource.metaData[key]
        }
      }
    }

    if (hasFields(overview)) {
      result.overview = overview
    }

    if (hasFields(meta)) {
      result.meta = meta
    }
  }

  groupExtended(extended: { [key: string]: any }, result: { [key: string]: any }): void {
    result.main = {}
    for (const key in extended) {
      if (typeof extended[key] === 'object') {
        if (key === 'symbols') {
          const symbols = extended[key]
          const cleaned: any = {}

          Object.keys(symbols).forEach((_key: string) => {
            const items = symbols[_key]
            if (Array.isArray(items) && items.length > 0) {
              cleaned[_key] = items
            }
          })

          result[key] = cleaned
        } else {
          result[key] = extended[key]
        }
      } else {
        result.main[key] = extended[key]
      }
    }
  }
}
