import React, { ReactElement, useEffect, useState } from 'react'
import { Tabs as BSTabs } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { has, capitalize, hasFields } from '../helpers/functions'
import { GenericTabsOptions } from '../types'
import { renderObjectToDataRows } from '../helpers/object-to-data-rows'
import { useExport } from '../contexts'
import { useLocation, useNavigate } from 'react-router-dom'
import { useURLSearchParams } from 'app/modules/shared'

/**
 * Allow any customization of standard Bootstrap tabs
 */
export function Tabs(props: { [key: string]: any }): JSX.Element {
  return <BSTabs {...props} />
}

type GenericTabsProps = {
  data: { [key: string]: any }
  options: GenericTabsOptions
  mountOnEnter?: boolean
  urlKey?: string
}
export function GenericTabs(props: GenericTabsProps): JSX.Element {
  const { data, options, mountOnEnter, urlKey } = props
  const { _t } = useTypedTranslation()
  const { exporting } = useExport()
  const location = useLocation()
  const navigate = useNavigate()
  const urlParams = useURLSearchParams()

  const defaultTab = (urlKey && urlParams.get(urlKey)) ?? getDefaultSelectedTab(options, data)
  const [activeTab, setActiveTab] = useState<string | undefined | null>(defaultTab)

  // Update tab in url search query
  useEffect(() => {
    if (!urlKey || !activeTab) {
      return
    }

    const urlTab = urlParams.get(urlKey)
    if (urlTab === activeTab) {
      return
    }

    activeTab ? urlParams.set(urlKey, activeTab) : urlParams.delete(urlKey)

    navigate(location.pathname + '?' + urlParams.toString())

    // eslint-disable-next-line
  }, [activeTab, urlKey])

  const tabs: ReactElement[] = []
  const validOptions = { ...options }
  Object.keys(validOptions).forEach((name: string) => {
    if (!validateTabData(data, name)) {
      delete validOptions[name]
    }
  })

  if (!Object.keys(validOptions).length) {
    return <i>{_t('no-data')}</i>
  }

  if (exporting) {
    return (
      <>
        {Object.keys(validOptions).map((name: string, idx: number) => {
          const tabOptions = validOptions[name]
          const handler = tabOptions.handler ?? defaultTabHandler
          return (
            data[name] && (
              <section className="html-tab mb-4" key={idx}>
                <h3 className="tab-title h4 mb-2">{tabOptions.title || capitalize(name)}</h3>
                {handler(data[name], _t)}
              </section>
            )
          )
        })}
      </>
    )
  }

  Object.keys(validOptions).forEach((name: string, idx: number) => {
    if (has(data, name)) {
      const tabOptions = validOptions[name]
      const title = tabOptions.title || capitalize(name).replace('_', ' ')
      const handler = tabOptions.handler ? tabOptions.handler : defaultTabHandler
      const tabClassName = tabOptions.capitalize === false ? 'text-default' : undefined

      const tab = (
        <Tab key={idx} eventKey={name} title={title} tabClassName={tabClassName}>
          {handler(data[name], _t)}
        </Tab>
      )

      tabs.push(tab)
    }
  })

  return (
    <Tabs
      className="mb-3"
      mountOnEnter={!!mountOnEnter}
      activeKey={activeTab}
      onSelect={(key: string | null) => setActiveTab(key as string)}
    >
      {tabs}
    </Tabs>
  )
}

/**
 * Default simple function for building tab content
 *
 * @param data
 */
function defaultTabHandler(data: { [key: string]: string }) {
  return renderObjectToDataRows(data, false, false, 1024)
}

function getDefaultSelectedTab(options: any, data: any) {
  let result
  for (const name in options) {
    if (validateTabData(data, name)) {
      result = name
      break
    }
  }

  return result
}

// Check if tab data is valid to even show a tab for it
function validateTabData(data: { [key: string]: any }, name: string) {
  return (
    (Array.isArray(data[name]) && data[name].length !== 0) ||
    (typeof data[name] === 'object' && !!hasFields(data[name]))
  )
}
