import { ScanProfileOptions } from 'app/modules/user/features/profile/types'

export type BaseUploadOptions = {
  description: string
  password: string
  isPassword: boolean
  isPrivate: boolean
  isPrivateReport: boolean
  skipWhitelisted: boolean
  showPropagate: boolean
  propagateTags: boolean
  isTerms: boolean
  tags: string[]
}

export const baseUploadOptionsDefaults = {
  description: '',
  password: '',
  isPassword: false,
  isPrivate: false,
  isPrivateReport: false,
  skipWhitelisted: false,
  showPropagate: false,
  propagateTags: true,
  isTerms: false,
  tags: [],
}

export const extendedUploadOptionsDefaults: ScanProfileOptions = {
  osint: true,
  extended_osint: true,
  extracted_files_osint: true,
  visualization: true,
  files_download: true,
  input_file_yara: true,
  extracted_files_yara: true,
  early_termination: true,
  certificates: true,
  url_analysis: true,
  ocr_qr: true,
  extract_strings: true,
  phishing_detection: true,
}
