import { useCallback } from 'react'
import { TOptions } from 'i18next'
import { useTranslation } from 'react-i18next'
import { StringMap } from '../types'

export interface ITranslator {
  _t: (key: string, options?: TOptions<StringMap>) => string
}
export function useTypedTranslation(): ITranslator {
  const { t } = useTranslation()

  const _t = useCallback(
    (key: string, options?: TOptions<StringMap>): string => {
      return t(key, options).toString()
    },
    [t]
  )

  return { _t }
}
