import React, { ErrorInfo } from 'react'
import { ErrorBoundary as BaseErrorBoundary } from 'react-error-boundary'
import { ErrorPageSelector } from './error-page-select'
import { logger } from '../logger'

/**
 * Extend error boundary to make possible its state to be reset when rerendering.
 * It should be a class component to extend base boundary class.
 * Reset state should be performed, when we try to navigate to any page right after visiting error page.
 */
interface CustomErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

interface CustomErrorBoundaryProps {
  FallbackComponent: (props: { error: Error | null; resetError: () => void }) => JSX.Element
  children: JSX.Element | JSX.Element[] | null
}

class CustomErrorBoundary extends React.Component<CustomErrorBoundaryProps, CustomErrorBoundaryState> {
  constructor(props: CustomErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error(error.name, errorInfo)
  }

  componentWillUnmount() {
    this.setState({ hasError: false, error: null })
  }

  handleReset = () => {
    this.setState({ hasError: false, error: null })
    window.location.replace('/')
  }

  render() {
    if (this.state.hasError) {
      return this.props.FallbackComponent({ error: this.state.error, resetError: this.handleReset })
    }

    return this.props.children
  }
}

/**
 * Full boundary definition, to use in multiple places
 */
export function ErrorBoundary({ children }: { children: JSX.Element | JSX.Element[] | null }): JSX.Element {
  return <CustomErrorBoundary FallbackComponent={ErrorPageSelector}>{children}</CustomErrorBoundary>
}

/**
 * Invisible instance of boundary, that should be placed inside special route,
 * that is the only one activated when trying to change route in error state.
 * In this case this instance resets error state.
 */
export function ErrorBoundaryWatcher(): JSX.Element {
  return (
    <div className="d-none">
      <ErrorBoundary>{null}</ErrorBoundary>
    </div>
  )
}
