import BaseFormatter from './base'
import { ExtractedFile, AllExtractedFiles, AllExtractedFilesTypes, SignalVerdict } from '../../types'
import { capitalize, formatEnumItem } from '../../helpers/functions'
import { ReportFilterSection, ReportFilterSectionType } from '../../components/filter/types'
import { ExtractedFilesFilterStat } from '../../features/extracted-files/types'
import { inc } from 'app/modules/shared/helpers'

/**
 * Format extracted files
 */
export default class ExtractedFilesFormatter extends BaseFormatter {
  sectionsCache: { [key: string]: boolean } = {}

  /**
   * Format original report data
   *
   * @param data
   * @param report
   */

  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    this.sectionsCache = {}
    const resource = this.getResource(data, 'file')
    const downloadedFilesResource = this.getResource(data, 'file-download')
    const hasExtracted = resource?.extractedFiles?.length
    const hasDownloaded = downloadedFilesResource?.fileDownloadResults?.length
    const verdicts = resource?.originVerdicts
    const stat: ExtractedFilesFilterStat = {
      types: { verdict: {}, type: {}, tags: {}, origin: {} },
      total: 0,
    }
    const filterSections: { [sectionName: string]: ReportFilterSection } = {
      verdict: { type: ReportFilterSectionType.Verdict, name: 'verdict', items: [] },
      interesting: {
        type: ReportFilterSectionType.Flag,
        name: 'interesting',
        title: 'interesting-filter-header',
        items: [{ value: 'off', name: 'only-interesting' }],
      },
      search: { type: ReportFilterSectionType.Search, name: 'search', items: [], placeholder: 'search-hash-desc' },
      tags: { type: ReportFilterSectionType.List, name: 'tags', items: [] },
      type: { type: ReportFilterSectionType.Flags, name: 'type', items: [] },
      origin: { type: ReportFilterSectionType.Flags, name: 'origin', items: [] },
    }

    if (!hasExtracted && !hasDownloaded) {
      return
    }

    const result: AllExtractedFiles = { extractedFiles: [], downloadedFiles: [], contextFiles: [] }

    if (hasExtracted) {
      this.formatFiles(
        resource?.extractedFiles,
        result,
        AllExtractedFilesTypes.ExtractedFiles,
        verdicts,
        filterSections,
        stat
      )
      this.splitContextFiles(result)
    }
    if (hasDownloaded) {
      this.formatFiles(
        downloadedFilesResource?.fileDownloadResults,
        result,
        AllExtractedFilesTypes.DownloadedFiles,
        verdicts,
        filterSections,
        stat
      )
    }

    if (Object.keys(result).length) {
      report.extracted_files = {
        files: result,
        filter: {
          stat,
          sections: Object.values(filterSections),
        },
      }
    }
  }

  splitContextFiles(files: AllExtractedFiles) {
    if (!files.extractedFiles.length) {
      return
    }

    const extracted: ExtractedFile[] = []
    files.extractedFiles.forEach((item) => {
      const target = item.origin === 'Input context' ? files.contextFiles : extracted
      target.push(item)
    })

    files.extractedFiles = extracted
  }

  formatFiles(
    resource: { [key: string]: any },
    result: { [key: string]: any },
    key: AllExtractedFilesTypes,
    verdicts: { [key: string]: any },
    filterSections: { [sectionName: string]: ReportFilterSection },
    stat: ExtractedFilesFilterStat
  ): void {
    const files: ExtractedFile[] = []
    this.addFiles(resource, files, verdicts, undefined, filterSections, stat, key)

    // sort by interesting and file size
    files.sort((a: ExtractedFile, b: ExtractedFile) => {
      if (a.interesting !== b.interesting) {
        return (b.interesting ? 1 : 0) - (a.interesting ? 1 : 0)
      }

      const threatA = a.verdict?.threatLevel ?? 0
      const threatB = b.verdict?.threatLevel ?? 0
      if (threatA !== threatB) {
        return threatB - threatA
      } else {
        return parseInt(b.size) - parseInt(a.size)
      }
    })

    result[key] = files
  }

  addFiles(
    resource: { [key: string]: any },
    target: ExtractedFile[],
    verdicts: { [key: string]: any },
    originFile: string | undefined,
    filterSections: { [sectionName: string]: ReportFilterSection },
    stat: ExtractedFilesFilterStat,
    key: AllExtractedFilesTypes
  ) {
    resource.forEach((data: { [key: string]: any }) => {
      const item = this.formatFile(data, verdicts, originFile, key)
      target.push(item)

      this.gatherFilterStat(item, stat)
      this.gatherFilterSections(item, filterSections)

      if (data.extractedFiles?.length) {
        this.addFiles(data.extractedFiles, target, verdicts, item.hashes.sha256, filterSections, stat, key)
      }
    })
  }

  gatherFilterStat(file: ExtractedFile, stat: ExtractedFilesFilterStat) {
    const statTypes = stat.types

    if (file.verdict) {
      const verdict = file.verdict.verdict as SignalVerdict
      statTypes.verdict[verdict] = inc(statTypes.verdict[verdict])
    }

    statTypes.origin[file.origin] = inc(statTypes.origin[file.origin])
    file.mediaType && (statTypes.type[file.mediaType] = inc(statTypes.type[file.mediaType]))

    for (const tag of file.tags || []) {
      statTypes.tags[tag.tag.name] = inc(statTypes.tags[tag.tag.name])
    }

    stat.total = inc(stat.total)
  }

  gatherFilterSections(file: ExtractedFile, sections: { [sectionName: string]: ReportFilterSection }) {
    const origin = file.origin
    const type = file.mediaType
    const tags = file.tags || []

    if (origin && typeof this.sectionsCache[origin] === 'undefined') {
      sections.origin.items.push({ value: origin, name: origin })
      this.sectionsCache[origin] = true
    }
    if (type && typeof this.sectionsCache[type] === 'undefined') {
      sections.type.items.push({ value: type, name: type })
      this.sectionsCache[type] = true
    }
    for (const tag of tags) {
      const tagName = tag.tag.name
      if (typeof this.sectionsCache[tagName] === 'undefined') {
        sections.tags.items.push({ value: tagName })
        this.sectionsCache[tagName] = true
      }
    }
  }

  formatFile(
    data: { [key: string]: any },
    verdicts: { [key: string]: any },
    originFile: undefined | string,
    key: AllExtractedFilesTypes
  ) {
    const item: ExtractedFile = {
      id: data.resourceReference?.ID,
      name: data.submitName,
      interesting: data.interesting ?? false,
      description: data.extendedData?.fileMagicDescription,
      mediaType: data.mediaType?.string,
      hashes: this.formatHashes(data.digests),
      origin: originFile ?? formatEnumItem(data.origin.type),
      source: key,
      size: data.fileSize,
      private: data.private,
      tags: data.allTags,
      verdict: data.verdict,
      language: this.getFileLanguage(data),
    }

    if (data.metaData) {
      if (data.metaData.dhash) {
        item.hashes.dhash = data.metaData.dhash
      }
      item.meta = data.metaData
    }

    if (!item.verdict && verdicts?.length) {
      verdicts.forEach((verdictData: { [key: string]: any }) => {
        if (item.hashes.sha256 === verdictData.identifier) {
          item.verdict = { ...verdictData.verdict }
        }
      })
    }

    if (item.verdict) {
      const key = capitalize(item.verdict.verdict, true)
      item.verdict.verdict = SignalVerdict[key as keyof typeof SignalVerdict]
    }

    return item
  }

  formatHashes(digests: { [key: string]: string }): { [key: string]: string } {
    const hashes: { [key: string]: string } = {}

    for (const key in digests) {
      const useKey = key.replace('-', '').toLowerCase()
      hashes[useKey] = digests[key]
    }

    return hashes
  }
}
