import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { applicationConfiguration } from 'app/modules/shared';
import { setMonitoringUser, initMonitoring } from './monitoring';
import { AuthContext } from 'app/modules/user';

export const monitoringContext = createContext({});

// Handle client-side monitoring
export const WithMonitoringContext = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element | null => {
  const config = useContext(applicationConfiguration)
  const auth = useContext(AuthContext)

  // This context does not provide any data
  const provided = useMemo(() => ({}), [])

  // Setup or shutdown monitoring
  useEffect(() => {
    initMonitoring(config)
    setMonitoringUser(auth.user)
  }, [config?.coralogix]);

  // Set user to perform monitoring for
  useEffect(() => {
    setMonitoringUser(auth.user)
  }, [auth.user]);

  return <monitoringContext.Provider value={provided}>{children}</monitoringContext.Provider>;
}
