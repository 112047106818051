import { useLocation } from 'react-router-dom'

/**
 * Returns value of URL search param
 *
 * @param paramName URL search param name
 */
export function useURLSearchParam(paramName: string): string {
  const queryString = useLocation().search
  const params = new URLSearchParams(queryString)

  return params.get(paramName) || ''
}
