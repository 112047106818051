import React, { useMemo, useContext } from 'react'
import { useTypedTranslation } from 'app/modules/shared/hooks/use-translation'
import { getAdvancedOptionsFields } from './helpers'
import { applicationConfiguration } from '../../../shared'
import OpswatMarketingAlert from '../opswat-marketing-alert'
import { TFunction } from 'i18next'
import ExtendedOptionsBody from './extended-options-body'
import { ScanProfile, ScanProfileOptions } from 'app/modules/user/features/profile/types'
import DropdownSelect from 'app/components/dropdown'
import { capitalize } from '../../helpers'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getPageUrl } from 'app/modules/shared/routing'

type ExtendedScanOptionsParams = {
  data: ScanProfileOptions
  setData: CallableFunction
  disabled: boolean
  scanProfiles: ScanProfile[]
  activeScanProfile: string | undefined
  setActiveScanProfile: CallableFunction
  availableOptions: ScanProfileOptions | undefined
}

export type ExtendedOption = {
  label: string
  note: string
  name: string
}

export function ExtendedScanOptions({
  data,
  setData,
  disabled,
  scanProfiles,
  activeScanProfile,
  setActiveScanProfile,
  availableOptions,
}: ExtendedScanOptionsParams) {
  const { _t } = useTypedTranslation()
  const config = useContext(applicationConfiguration)
  const fields: ExtendedOption[] = getAdvancedOptionsFields(_t as TFunction)
  const customProfileName = _t('custom-options')
  const profilesUrl = getPageUrl('user-profile') + '?active=scan-profiles'
  const profilesItems = useMemo(() => {
    const result = []
    for (const profile of scanProfiles) {
      result.push({ name: capitalize(profile.name), value: profile.id as string })
    }
    result.push({ name: customProfileName, value: '' })

    return result
  }, [scanProfiles])

  const activeProfile = useMemo(() => {
    const filtered = scanProfiles.filter((item: ScanProfile) => item.id === activeScanProfile)

    return filtered.length ? filtered[0] : undefined
  }, [scanProfiles, activeScanProfile])
  const description = activeProfile ? activeProfile.description || _t('profile-options') : _t('set-custom-options')

  const activeProfileItem = useMemo(() => {
    const name = profilesItems.filter((item: { value: string; name: string }) => item.value === activeScanProfile)[0]
      ?.name

    return name || customProfileName
  }, [scanProfiles, activeScanProfile])

  function setValue(name: string, value: any) {
    const values: { [key: string]: any } = { ...data }
    values[name] = value

    setData(values as ScanProfileOptions)
  }

  function onOptionChange(name: string) {
    return (value: boolean) => {
      setValue(name, value)
    }
  }

  // This condition is only true on community, where we need to show this notification.
  if (disabled) {
    return <OpswatMarketingAlert />
  }

  return (
    <>
      <p className="text-muted mb-3">
        <i>
          <Trans i18nKey="generic-options-note">
            Pre-configured analysis options, managed at{' '}
            <Link to={profilesUrl} className="text-i">
              Profiles
            </Link>{' '}
            page
          </Trans>
        </i>
      </p>

      <DropdownSelect
        id="scan-profiles"
        className="dropdown-no-capitalize"
        onClick={(profile_id: string) => setActiveScanProfile(profile_id)}
        items={profilesItems}
        value={activeProfileItem}
        disabled={disabled}
      />

      <p className="text-muted mb-3 mt-6">
        <i>{description}</i>
      </p>

      <ExtendedOptionsBody
        data={activeProfile?.options || data}
        disableFields={!!activeScanProfile}
        fields={fields}
        onOptionChange={onOptionChange}
        availableOptions={availableOptions}
        _t={_t as TFunction}
      />
    </>
  )
}
