import { inc } from 'app/modules/shared/helpers'
import { ReportFilterSection, ReportFilterSectionType, ReportPageFilterSettings } from '../../components/filter/types'
import { MlThreatData, PhishingData, RequestDetails, UrlDetailsStat, UrlStat } from '../../types/url-details'
import BaseFormatter from './base'
import { SignalVerdict } from '../../types'

/**
 * Prepare URL render data
 */
export default class UrlDetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const resource = this.getResource(data, 'url-render')
    const fileResource = this.getResource(data, 'file')
    if (!resource) {
      return
    }

    const files = fileResource?.extractedFiles
    const extractedPreview = Array.isArray(files) && files.length ? files[0] : undefined
    const mainFileData = resource.renderResults.map((urlData: { [key: string]: any }) => {
      const requests = urlData.urlRenderData.result?.data?.requests
      const [formattedRequests, filter] = this.handleRequests(requests)
      let previewFile = urlData?.base64

      //STI-6660
      //To keep backwards compatibility with depreceted anesidoraweb structure,
      //use 'snapshot' from fileResource's urlRenderData
      if (!previewFile) {
        const fileRenderData = fileResource?.renderResults?.renderResults
          ? fileResource.renderResults.renderResults.find(
              (result: { [key: string]: any }) =>
                result?.resource?.data && result.resource.data === urlData?.resource?.data
            )
          : undefined
        previewFile = fileRenderData?.urlRenderData?.snapshot
      }

      return {
        url: urlData.url,
        certificates: urlData.urlRenderData.result?.lists?.certificates,
        requests: formattedRequests,
        filter: filter,
        phishing: this.formatPhishing(urlData.urlRenderData),
        mlData: this.formatMlThreatVerdict(urlData.urlRenderData),
        preview: {
          sha256: extractedPreview?.digests['SHA-256'],
          base64: previewFile,
          mediaType: 'image/png',
        },
        state: urlData.urlRenderData.state,
      }
    })

    if (mainFileData.length) {
      report.urlDetails = { main: mainFileData }
    }
  }

  handleRequests(requests: RequestDetails[]): [RequestDetails[], ReportPageFilterSettings] {
    const sectionsCache: { [key: string]: boolean } = {}
    const stat: UrlDetailsStat = { types: { type: {}, method: {}, host: {} }, total: 0 }
    const sections: { [sectionName: string]: ReportFilterSection } = {
      search: { type: ReportFilterSectionType.Search, name: 'search', items: [], placeholder: 'search-url-ip' },
      method: { type: ReportFilterSectionType.Flags, name: 'method', items: [] },
      host: { type: ReportFilterSectionType.Flags, name: 'host', items: [], nameDefault: true },
      type: { type: ReportFilterSectionType.Flags, name: 'type', items: [] },
    }

    if (requests?.length && requests.length > 0) {
      for (let i = 0; i < requests.length; i++) {
        const item = requests[i]
        if (!Object.keys(item).length) {
          continue
        }

        const url = item.request?.request?.url
        const parsedUrl = url && new URL(url)
        const host = parsedUrl && parsedUrl.hostname
        const method = item.request?.request?.method
        const responseType = item.response?.type?.toLowerCase() || 'unknown'

        host && requests[i]?.request?.request && (requests[i].request.request.host = host)

        this.gatherFilterSections(host, method, responseType, sections, sectionsCache)
        this.gatherFilterStat(host, method, responseType, stat)
      }
    }

    return [requests, { sections: Object.values(sections), stat }]
  }

  gatherFilterStat(
    host: string | undefined,
    method: string | undefined,
    responseType: string | undefined,
    stat: UrlDetailsStat
  ) {
    const statTypes = stat.types

    if (host) {
      statTypes.host[host] = inc(statTypes.host[host])
    }
    if (method) {
      statTypes.method[method] = inc(statTypes.method[method])
    }
    if (responseType) {
      statTypes.type[responseType] = inc(statTypes.type[responseType])
    }

    stat.total = inc(stat.total)
  }

  gatherFilterSections(
    host: string | undefined,
    method: string | undefined,
    responseType: string | undefined,
    sections: { [sectionName: string]: ReportFilterSection },
    sectionsCache: { [key: string]: boolean }
  ) {
    if (host && typeof sectionsCache[host] === 'undefined') {
      sections.host.items.push({ value: host })
      sectionsCache[host] = true
    }
    if (method && typeof sectionsCache[method] === 'undefined') {
      sections.method.items.push({ value: method })
      sectionsCache[method] = true
    }
    if (responseType && typeof sectionsCache[responseType] === 'undefined') {
      sections.type.items.push({ value: responseType })
      sectionsCache[responseType] = true
    }
  }

  // Prepare some statistics on requests
  formatStat(data: { [key: string]: any } | undefined): UrlStat | undefined {
    if (!data) {
      return undefined
    }

    const stat: UrlStat = {
      resources: {},
      domains: data.stats?.domainStats,
      methods: {},
    }

    data.stats?.resources?.forEach((resource: { [key: string]: any }) => {
      const type = resource.type.toLowerCase()
      stat.resources[type] = resource
    })

    data.data?.requests?.forEach((item: { [key: string]: any }) => {
      const method = item.request?.request.method
      if (!method) {
        return
      }

      if (typeof stat.methods[method] === 'undefined') {
        stat.methods[method] = { count: 0, method: method }
      }
      stat.methods[method].count++
    })

    return stat
  }

  // Prepare phishing data
  formatPhishing(data: { [key: string]: any } | undefined): PhishingData | undefined {
    const phishingData = data?.result?.phishDetection
    if (!phishingData) {
      return
    }

    const result: PhishingData = {
      checkSucceeded: data.phishDetectionState === 'SUCCESS',
      detected: !!phishingData.category,
      spendTime: phishingData.spend_time,
      skipped: phishingData.skipped,
    }

    if (phishingData.brand) {
      result.brand = phishingData.brand
    }

    if (phishingData.base64) {
      result.preview = {
        base64: phishingData.base64,
        mediaType: 'image/png',
      }
    }

    return result
  }

  formatMlThreatVerdict(data: { [key: string]: any } | undefined) {
    const result: MlThreatData = {}
    const predictionValue = data?.result?.webThreat?.phishtraitsNetwork?.predictions?.phishing
    const verdict = this.castPhishingPredictionToVerdict(predictionValue)
    if (verdict) {
      result.verdict = verdict === SignalVerdict.Benign ? SignalVerdict.No_threat : verdict
    }

    return result
  }

  castPhishingPredictionToVerdict(prediction: number | undefined) {
    if (typeof prediction === 'undefined') {
      return undefined
    }

    if (prediction <= 0.2) {
      return SignalVerdict.Benign
    }
    if (prediction <= 0.35) {
      return SignalVerdict.No_threat
    }
    if (prediction <= 0.5) {
      return SignalVerdict.Unknown
    }
    if (prediction <= 0.75) {
      return SignalVerdict.Suspicious
    }
    if (prediction <= 0.9) {
      return SignalVerdict.Likely_malicious
    }

    return SignalVerdict.Malicious
  }
}
