import BaseFormatter from './base'
import { getNoThreatVerdicts, castVerdictToStrength, castStrengthToVerdict } from '../../helpers/signal-type'
import { has, formatEnumItem, capitalize } from '../../helpers/functions'
import { OsintOverview, OsintItem, ResourceTypes, SignalVerdict, SignalStrength } from '../../types'
import { OsintFilterStat } from '../../features/osint/types'
import { ReportFilterSection, ReportFilterSectionType } from '../../components/filter/types'
import { inc } from 'app/modules/shared/helpers'

/**
 * Format report response data for OSINT page
 */
export default class OsintFormatter extends BaseFormatter {
  providers_cache: { [key: string]: 1 } = {}
  sectionsCache: { [key: string]: boolean } = {}

  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    this.sectionsCache = {}

    const osintData = this.getResources(data, 'osint')
    const tags = typeof data.allOsintTags !== 'undefined' ? data.allOsintTags : []
    const stat: OsintFilterStat = { types: { verdict: {}, type: {}, provider: {} }, total: 0 }
    const filterSections: { [sectionName: string]: ReportFilterSection } = {
      search: { type: ReportFilterSectionType.Search, name: 'search', items: [], placeholder: 'search-resource' },
      verdict: { type: ReportFilterSectionType.Verdict, name: 'verdict', items: [] },
      provider: { type: ReportFilterSectionType.Flags, name: 'provider', items: [] },
      type: { type: ReportFilterSectionType.Flags, name: 'type', items: [] },
    }

    if (!osintData.length) {
      return
    }

    const groupedItems: { [key: string]: any } = {}
    const overview: OsintOverview = {
      providers: [],
      tags: tags,
    }

    this.formatData(osintData, groupedItems, overview, filterSections, stat)
    const result: { [key: string]: any } = {}

    if (overview.providers.length) {
      result.overview = overview
    }
    if (Object.keys(groupedItems).length) {
      result.groups = groupedItems
    }
    if (result.groups) {
      result.filter = { stat, sections: Object.values(filterSections) }
    }

    if (Object.keys(result).length) {
      report.osint = result
    }
  }

  /**
   * Format all osint data and add it to provided containers
   */
  formatData(
    osintData: any[],
    groupedItems: { [key: string]: any },
    overview: OsintOverview,
    filterSections: { [sectionName: string]: ReportFilterSection },
    stat: OsintFilterStat
  ): void {
    osintData.forEach((osintResource) => {
      if (!has(osintResource, 'results')) {
        return
      }

      osintResource.results.forEach((data: { [key: string]: any }) => {
        this.formatProviderData(data)
        const item = this.createOsintItem(data)

        this.addItemToGroup(item, groupedItems)
        this.updateOverview(item, overview)
        this.gatherFilterStat(item, stat)
        this.gatherFilterSections(item, filterSections)
      })
    })
  }

  gatherFilterStat(item: OsintItem, stat: OsintFilterStat) {
    const statTypes = stat.types

    if (item.verdict) {
      const verdict = item.verdict.code
      statTypes.verdict[verdict] = inc(statTypes.verdict[verdict])
    }

    statTypes.type[item.type] = inc(statTypes.type[item.type])
    statTypes.provider[item.provider] = inc(statTypes.provider[item.provider])
    stat.total = inc(stat.total)
  }

  gatherFilterSections(item: OsintItem, sections: { [sectionName: string]: ReportFilterSection }) {
    const type = item.type
    const provider = item.provider

    if (typeof this.sectionsCache[type] === 'undefined') {
      sections.type.items.push({ value: type, name: type })
      this.sectionsCache[type] = true
    }
    if (typeof this.sectionsCache[provider] === 'undefined') {
      sections.provider.items.push({ value: provider, name: provider })
      this.sectionsCache[provider] = true
    }
  }

  /**
   * Format item data obtained from single provider
   */
  formatProviderData(data: { [key: string]: any }): void {
    const key = capitalize(data.verdict, true) as keyof typeof SignalVerdict
    data.verdict = SignalVerdict[key]

    data.type = capitalize(data.type, true)
    data.osintProvider = formatEnumItem(data.osintProvider, 'camelcase')
  }

  /**
   * Create new item object
   */
  createOsintItem(data: { [key: string]: any }): OsintItem {
    const item: OsintItem = {
      resource: data.resource,
      type: ResourceTypes[data.type as keyof typeof ResourceTypes],
      detected: this.isThreatDetected(data),
      verdict: this.getVerdict(data),
      provider: data.osintProvider,
      result: data,
    }

    return item
  }

  /**
   * Check if provider data was marked as having threat of any strength
   */
  isThreatDetected(data: { [key: string]: any }): boolean {
    const noThreat = getNoThreatVerdicts()

    return noThreat.indexOf(data.verdict) === -1
  }

  /**
   * Cast item verdict to normalized form
   */
  getVerdict(data: { [key: string]: any }): { code: SignalVerdict; strength: SignalStrength } {
    const strength = castVerdictToStrength(data.verdict)
    const mergedVerdict = castStrengthToVerdict(strength, true)
    const verdict: { code: SignalVerdict; strength: SignalStrength } = {
      code: mergedVerdict,
      strength: strength,
    }

    return verdict
  }

  /**
   * Add item to group by signal type
   */
  addItemToGroup(item: OsintItem, groups: { [key: string]: any }): void {
    const key = item.verdict.code
    const type = item.type

    if (!has(groups, key)) {
      groups[key] = {}
    }

    const group = groups[key]
    if (!has(group, type)) {
      group[type] = []
    }

    group[type]?.push(item) // Use `?` only to pass TS type check
  }

  /**
   * Update overview using single OSINT resource
   */
  updateOverview(item: OsintItem, overview: { [key: string]: any }): void {
    const provider = item.provider

    if (!has(this.providers_cache, provider)) {
      this.providers_cache[provider] = 1
      overview.providers.push(provider)
    }
  }
}
