import React, { useContext } from 'react'
import Appbar from '../appbar/appbar'
import { Footer } from '../footer'
import { MainNotification } from '../main-notification'
import { applicationConfiguration } from 'app/modules/shared'
import { useLocation } from 'react-router'

/**
 * Layout for functionality to be used by daily users
 */
export function UsersLayout({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const config = useContext(applicationConfiguration)
  const location = useLocation()
  const isSetup = ['/admin/setup', '/auth/first-signup/redirect'].includes(location.pathname)

  if (config.firstRun || isSetup) {
    return <>{children}</>
  }

  return (
    <>
      <Appbar />
      <MainNotification />

      <div className="container-fluid main-container">{children}</div>

      {/* <FeatureNote />  Dark theme disabled with new ui*/}
      {config.showFooter ? <Footer /> : null}
    </>
  )
}
