import { SignalVerdict } from 'app/modules/reports/types'
import React, { MouseEventHandler, useEffect, useState } from 'react'

const Checkbox = ({
  id,
  label,
  labelFirst,
  disabled,
  checked,
  onChange,
  suffix,
  labelClassName,
  className,
  variant = 'primary',
  required,
  name,
  partialSelection,
}: {
  id?: string
  label?: string | JSX.Element
  labelFirst?: boolean
  disabled?: boolean
  checked?: boolean
  onChange?: (checked: boolean) => any
  variant?: 'primary' | 'dark' | SignalVerdict
  suffix?: JSX.Element
  className?: string
  required?: boolean
  name?: string
  partialSelection?: boolean
  labelClassName?: string
}) => {
  const [isChecked, setChecked] = useState(checked ?? false)
  const containerClass =
    'checkbox-component-container ' +
    (isChecked ? 'checked ' : '') +
    (partialSelection ? 'partial-checked ' : '') +
    (className ?? '')

  const handleChange: MouseEventHandler<HTMLElement> = (event) => {
    // There can be links, say, to terms inside chekcbox label.
    // So we prevent checking checkbox when clicking on such links
    const clickTargetTag = (event.target as any).tagName
    if (clickTargetTag && clickTargetTag.toLowerCase() === 'a') {
      return
    }

    event.stopPropagation()
    if (disabled) {
      return
    }

    const checked = !isChecked
    onChange && onChange(checked)
    setChecked(checked)
  }

  useEffect(() => {
    setChecked(!!checked)
  }, [checked])

  return (
    <div id={`${id}${isChecked ? '-checked' : ''}`} className={containerClass} onClickCapture={handleChange}>
      <div className="checkbox-wrapper">
        {labelFirst && label ? (
          <span className={`checkbox-label pe-2 text-main ${labelClassName ?? ''}`}>{label}</span>
        ) : null}
        <div className="checkbox-container">
          <input
            type="checkbox"
            name={name}
            checked={isChecked}
            disabled={disabled}
            required={!!required}
            onChange={(e) => {
              /* required to avoid warnings, actual change handled on the outer div */
            }}
          />
          <span className={`checkbox-custom-input ${variant}`} />
        </div>
        {!labelFirst && label ? (
          <span className={`checkbox-label ps-2 text-main ${labelClassName ?? ''}`}>{label}</span>
        ) : null}
      </div>
      {suffix || null}
    </div>
  )
}

export default Checkbox
