import { ScanState } from '../types'
import { ReportWrapperConstructor } from './report-wrapper-base'

export function ReportStateMixin<TBase extends ReportWrapperConstructor>(Base: TBase): any {
  return class ReportWithReportState extends Base {
    /**
     * Get scan progress, saved for scan init instance
     */
    getGeneralScanProgress() {
      const state = this._data?.state
      const positionInQueue = this._data?.positionInQueue
      const queueSize = this._data?.queueSize

      return { state, positionInQueue, queueSize }
    }

    /**
     * Get scan progress data for given file
     */
    getScanProgress(reportId: string) {
      const report = this.getReport(reportId)
      return report?.progress
    }

    /**
     * Get scan state of report
     */
    getReportState(reportId: string): ScanState | undefined {
      const progress = this.getScanProgress(reportId)
      return progress?.state
    }

    /**
     * Check if state is successfull
     */
    isStateSuccess(state: ScanState): boolean {
      return state === ScanState.Success || state === ScanState.SuccessPartial
    }

    /**
     * Check if state is failed
     */
    isStateFailed(state: ScanState): boolean {
      return state === ScanState.Failed || state === ScanState.FailedPartial
    }

    /**
     * Check if state is final
     */
    isStateFinished(state: ScanState): boolean {
      return this.isStateSuccess(state) || this.isStateFailed(state)
    }

    /**
     * Check if state is 'in queue'
     */
    isStateInQueue(state: ScanState) {
      return state === ScanState.InQueue
    }

    /**
     * Check if state is 'in progress'
     */
    isStateInProgress(state: ScanState) {
      return state === ScanState.InProgress
    }

    /**
     * Get id of first report that is ready to be viewed by user.
     * This is the case, when main scan subtask (file-transform) is finished.
     */
    getReportIdReadyForView() {
      const reports = this.getReports()

      for (const id in reports) {
        const progress = this.getScanProgress(id)
        if (!progress) {
          continue
        }

        const isMainTaskFinished = this.isStateSuccess(progress.main.state)
        if (isMainTaskFinished) {
          return id
        }
      }

      return
    }
  }
}
