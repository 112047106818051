import { useState, useEffect } from 'react'

export type PageParam = {
  page: number
  pageSize?: number
  dateFrom?: string
}

export function usePagedSource(
  fetchFn: ({ page, pageSize, dateFrom }: PageParam) => Promise<any>,
  page: number,
  pageSize?: number,
  dateFrom?: string,
  interval?: number
) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [data, setData] = useState<any>(undefined)

  // Use local variable to avoid sending request if previous one is still running.
  let busy = false

  useEffect(() => {
    const getData = async () => {
      if (busy) {
        return
      }

      busy = true
      setLoading(true)
      setError(null)

      try {
        const res = await fetchFn({ page, pageSize, dateFrom })
        setData(res)
      } catch (error: any) {
        setError(error.message)
        setData(undefined)
      }

      setLoading(false)
      busy = false
    }

    getData()

    if (interval) {
      const pause = setInterval(() => getData(), interval)
      return () => clearInterval(pause)
    }
  }, [page, pageSize, fetchFn, dateFrom])

  return [data, error, loading]
}
