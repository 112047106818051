import React, { useMemo } from 'react'
import { getPageData } from './pages'
import { RenderedRouteWrapper } from './route-wrapper'
import { PageData } from './types'

/**
 * Get params for Route component to handle routing for given page
 */
export function To(pageKey: string): { [key: string]: any } {
  const page = useMemo(() => getPageData(pageKey) ?? getPageData('not-found'), [pageKey]) as PageData
  const exact = page.exact ?? true
  const currentPage: PageData = { ...page, key: pageKey }

  return {
    exact,
    path: page.url,
    element: <RenderedRouteWrapper routeProps={{}} page={currentPage} />,
  }
}
