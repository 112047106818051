import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import './i18n'
import App from './app/app'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'typeface-roboto'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
